import Api from "views/Api/Api.js";
import Settings from "views/Settings/Settings.js";
import Databases from "views/Databases//Databases.js";
import Functions from "views/Functions/Functions.js";

import Store from "views/Store/Store.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import PricingPage from "views/Pages/PricingPage.js";
import TrackingPage from "views/Pages/TrackPage.js";
import ReactTables from "views/Tables/ReactTables.js";
import BillingTables from "views/Tables/BillingTables.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import RegularTables from "views/Tables/RegularTables.js";
import DeliveryPage from "views/Pages/DeliveryPage.js";
import TeamsPage from "views/Pages/TeamsPage.js";
import TripsPage from "views/Pages/TripsPage.js";
import TimecardPage from "views/Pages/TimecardPage.js";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import StorefrontIcon from '@material-ui/icons/Storefront';
import PeopleAlt from "@material-ui/icons/PeopleAlt"
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import CodeIcon from "@material-ui/icons/Code";
import SettingsIcon from '@material-ui/icons/Settings';
import CloudIcon from '@material-ui/icons/Cloud';
import StorageIcon from '@material-ui/icons/Storage';
import BusinessIcon from '@material-ui/icons/Business';
var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  // {
  //   path: "/track",
  //   name: "Track",
  //   icon: LocationOnIcon,
  //   component: Store,
  //   layout: "/admin",
  // },
  {
    path: "/track",
    name: "Track",
    icon: LocationOnIcon,
    component: TrackingPage,
    layout: "/admin",
  },
  {
    path: "/delivery",
    name: "Deliveries",
    icon: LocationOnIcon,
    component: DeliveryPage,
    layout: "/admin",
  },
  {
    path: "/trips",
    name: "Trips",
    component: TripsPage,
    layout: "/admin",
  },
  {
    path: "/timecard",
    name: "Timecard",
    component: TimecardPage,
    layout: "/admin",
  },
  {
    path: "/teams",
    name: "Teams",
    component: TeamsPage,
    layout: "/admin",
  },
  // {
  //   collapse: true,
  //   name: "Track2",
  //   icon: LocationOnIcon,
  //   state: "pageCollapse",
  //   views: [
  //     {
  //       path: "/deliveries",
  //       name: "Deliveries",
  //       mini: "Del",
  //       component: ReactTables,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/servicetrips",
  //       name: "Service Trips",
  //       mini: "Ser",
  //       component: ReactTables,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/fieldtracking",
  //       name: "Field Tracking",
  //       mini: "FT",
  //       component: ReactTables,
  //       layout: "/admin",
  //     }
  //   ],
  // },

  // {
  //   path: "/alldeliveries",
  //   name: "All Deliveries",
  //   icon: FormatListBulletedIcon,
  //   component: ReactTables,
  //   layout: "/admin",
  // },
  // {
  //   path: "/deliveryteam",
  //   name: "Delivery Team",
  //   icon: PeopleAlt,
  //   component: RegularTables,
  //   layout: "/admin",
  // },
  {
    path: "/database",
    name: "Storage & Compute",
    icon: StorageIcon,
    component: Databases,
    layout: "/admin",
  },
  // {
  //   path: "/functions",
  //   name: "Functions",
  //   icon: BusinessIcon,
  //   component: Functions,
  //   layout: "/admin",
  // },
  {
    path: "/settings",
    name: "Settings",
    icon: SettingsIcon,
    component: Settings,
    layout: "/admin",
  },
  {
    path: "/api",
    name: "Api & SDK",
    icon: CodeIcon,
    component: Api,
    layout: "/admin",
  },
 


  // {
  //   path: "/store",
  //   name: "Storefront (β)",
  //   icon: StorefrontIcon,
  //   component: Store,
  //   layout: "/admin",
  // },
  // {
  //   path: "/billing",
  //   name: "Billing",
  //   icon: AttachMoneyIcon,
  //   component: BillingTables,
  //   layout: "/admin",
  // },
  // {
  //   path: "/pricing",
  //   name: "Payment & Plans",
  //   icon: DataUsageIcon,
  //   component: PricingPage,
  //   layout: "/admin",
  // },
  {
    path: "/login-page",
    name: "Login Page",
    component: RegisterPage,
    layout: "/auth",
  },
];
export default dashRoutes;
