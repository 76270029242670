
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import React from "react";
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components

// @material-ui/icons
// import InfoOutline from "@material-ui/icons/InfoOutline";
import GroupIcon from '@material-ui/icons/Group';
import LocationOnIcon from '@material-ui/icons/LocationOn';
// core components
import Table from "components/Table/Table.js";
import moment from "moment";
import Phone from "@material-ui/icons/Phone";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import LaunchIcon from '@material-ui/icons/Launch';
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
import avatar from "assets/img/faces/marc.jpg";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import Accordion from "components/Accordion/Accordion.js";
import Switch from "@material-ui/core/Switch";
import SweetAlert from "react-bootstrap-sweetalert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import AddAlertIcon from '@material-ui/icons/AddAlert';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
// core components
import Heading from "components/Heading/Heading.js";
import Firebase from "firebase";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Person";
import EditIcon from '@material-ui/icons/Edit';
const SMBase64 = require('smbase64')
let base64 = new SMBase64()
import GooglePlacesAutocomplete, {
	geocodeByAddress,
	getLatLng
} from 'react-google-places-autocomplete';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Timeline from "components/Timeline/Timeline.js";
import ChatIcon from '@material-ui/icons/Chat';
import CardTravel from "@material-ui/icons/CardTravel";
import Extension from "@material-ui/icons/Extension";
import Fingerprint from "@material-ui/icons/Fingerprint";
import FlightLand from "@material-ui/icons/FlightLand";
import Build from "@material-ui/icons/Build";
import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
import Info from "@material-ui/icons/Info";
import LocationOn from "@material-ui/icons/LocationOn";
import WorkIcon from '@material-ui/icons/Work';
import HelpOutline from "@material-ui/icons/HelpOutline";
import NavPills from "components/NavPills/NavPills.js";
import TimelineIcon from '@material-ui/icons/Timeline';
import Badge from "components/Badge/Badge.js";
import {mapStyles} from "variables/mapstyles";
import {
  roundedLineChart,
  straightLinesChart,
  simpleBarChart,
  colouredLineChart,
  multipleBarsChart,
  colouredLinesChart,
  pieChart,
} from "variables/charts.js";
import ChartistGraph from "react-chartist";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import StorageIcon from '@material-ui/icons/Storage';
import { CSVLink, CSVDownload } from "react-csv";
import { uniqueNamesGenerator, starWars } from 'unique-names-generator';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import Slider from "nouislider";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Datetime from "react-datetime";

import Popover from '@material-ui/core/Popover';
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import axios from "axios";
const customConfig = {
  dictionaries: [starWars],
  separator: ' ',
  length: 1,
};

const useStyles = makeStyles(styles);
const iconArray = [Timeline, ChatIcon, CardTravel, Extension, Fingerprint, FlightLand, Build, Dashboard, Schedule, Info, LocationOn, WorkIcon, HelpOutline, TimelineIcon]
const colorArray = [ "success", "warning", "danger", "rose"]

const squareMarkerSVG =
  "M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z";
      

const simulationClientName = uniqueNamesGenerator(customConfig)
const simulationTeammemberName = uniqueNamesGenerator(customConfig)
const fieldsForReport = [
  "Client name",
  "Client location",
  "Team member",
  "Team member start location",
  "Time in",
  "Time out",
  "Hours spent",
  "Miles driven",
  "Hours spent outside"]

const fieldsForSummary = [
  "Track Start Time",
  "Track End Time",
  // "Hours Tracked",
  "Track Percentage",
  "Hours At Client Location",
  "Hours Driving", 
  // "Hours Outside Client Location"
] 

export default function TeamsPage() {
  const classes = useStyles();
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }
  const [cMap, setCMap] = React.useState();
  const [locationDetailMap, setLocationDetailMap] = React.useState();
  
  const [markersRef, setMarkersRef] = React.useState();

  const google = window.google;
  
  const mapRef = React.useRef(null);

  let bounds = new google.maps.LatLngBounds();

  const service = new google.maps.DistanceMatrixService();

  const [currentUserData, setCurrentUserData] = React.useState();
  const [topLocations, setTopLocations] = React.useState();
  const [topCustomers, setTopCustomers] = React.useState();
  const [heatMapData, setHeatMapData] = React.useState([]);
  // const [places, setPlaces] = React.useState(true);
  // const [people, setPeople] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [placesData, setPlacesData] = React.useState([]);
  const [basePlacesData, setBasePlacesData] = React.useState([]);
  
  const [placesMapData, setPlacesMapData] = React.useState([]);
  const [liveTrackData, setLiveTrackData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [deliveryGuyPhone, setDeliveryGuyPhone] = React.useState();
  const [deliveryGuyName, setDeliveryGuyName] = React.useState();
  const [teamLatLng, setTeamLatLng] = React.useState();

  const [deliveryData, setDeliveryData] = React.useState([]);
  const [deliveryOpen, setDeliveryOpen] = React.useState(false);
  const [adderssTrackOpen, setAdderssTrackOpen] = React.useState(false);
  const [teamMemberTrackOpen, setTeamMemberTrackOpen] = React.useState(false);
  
  const [trackingDetails, setTrackingDetails] = React.useState([]);
  const [teamMemberDetails, setTeamMemberDetails] = React.useState([]);
  
  const [address, setAddress] = React.useState();
  const [addressLatLng, setAddressLatLng] = React.useState();
  const [addressLocation, setAddressLocation] = React.useState();
  const [addressMainText, setAddressMainText] = React.useState();
  const [selectedDeliveryPerson, setSelectedDeliveryPerson] = React.useState();
  const [customerPhone, setCustomerPhone] = React.useState();
  const [scheduleDateTime, setScheduleDateTime] = React.useState();
  const [customerName, setCustomerName] = React.useState();
  
  const [registeredTeammembers, setRegisteredTeammembers] = React.useState([]);
  const [distanceMatrixData, setDistanceMatrixData] = React.useState();
  const [avgDailyJobs, setAvgDailyJobs] = React.useState();
  const [pieChartData, setPieChartData] = React.useState({ labels: ["100%"],
  series: [100]});
  const [userTimelineData, setUserTimelineData] = React.useState();
  const [userRealtimeActivtyData, setUserRealtimeActivtyData] = React.useState([]);
  const [scheduledClientsForToday, setScheduledClientsForToday] = React.useState();

  
  const [teamsSimulationModal, setTeamsSimulationModal] = React.useState();
  const [simulationAddress, setSimulationAddress] = React.useState();
  const [simulationAddressLatLng, setSimulationAddressLatLng] = React.useState([]);
  const [autoCompleteAddress, setAutoCompleteAddress] = React.useState();

  const [simulationPersonAddress, setSimulationPersonAddress] = React.useState();
  const [simulationPersonAddressLatLng, setSimulationPersonAddressLatLng] = React.useState();
  const [simulationTrackingData, setSimulationTrackingData] = React.useState();
  const [simulationTimeline, setSimulationTimeline] = React.useState([]);
  const [onePersonCSV, setOnePersonCSV] = React.useState();
  const [trackSummary, setTrackSummary] = React.useState();
  const [gnattChartData, setGnattChartData] = React.useState();
  const [reportDates, setReportDates] = React.useState();
  const [reportCSVData, setReportCSVData] = React.useState();
  const [reportGenerationWIP, setReportGenerationWIP] = React.useState(false);
  const [placeFilter, setPlaceFilter] = React.useState("All");

  const getMinutesFromTimestring = (timeStr) => {
    const t = timeStr.split(":")
    return parseInt(t[0])*60 + parseInt(t[1])

  }
  const [checked, setChecked] = React.useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);
  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const [openDate, setOpenDate] = React.useState(false);
 
  const toggle = () => setOpenDate(!openDate);

 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checkedA, setCheckedA] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
 
  const handleDateOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateClose = () => {
    setAnchorEl(null);
    setReportDates(null)
    setReportCSVData(null);
    setReportGenerationWIP(false);
  };

  const showButtonText = () => {
    if (reportGenerationWIP) {
      if (reportCSVData && reportCSVData.length > 0) {
        return (
          <CSVLink filename='Onroad-tracking-report' data={reportCSVData} style={{ fontSize: "10px", color: "white" }}>Download CSV</CSVLink>
        )
      } else {
        return "Preparing"
      }
    } else {
      if (reportCSVData && reportCSVData.length > 0) {
        return (
          <CSVLink filename='Onroad-tracking-report' data={reportCSVData} style={{ fontSize: "10px", color: "white" }}>Download CSV</CSVLink>
        )
      } else {
        return "Generate Report"
      }
    }
  }

  const handleGenerateReport = async () => {
    if (!reportDates) {
      return;
    }
    setReportCSVData(null)
    setReportGenerationWIP(true)

      // const url = `https://us-central1-onroad3-e593b.cloudfunctions.net/generateReportData`
      //     var config = {
      //       method: 'post',
      //       url: url,
      //       headers: {"Authorization": currentUserData.apikey}
      //     };

      // const a = await axios(config)
          // .then(function (response) {
          //   console.log("======nearbysearch====",JSON.stringify(response.data));
          // })
          // .catch(function (error) {
          //   console.log(error);
          // });
    

    var myHeaders = new Headers();
    myHeaders.append("Authorization", currentUserData.apikey);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({dates: reportDates})
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

  const a = await fetch("https://us-central1-onroad3-e593b.cloudfunctions.net/generateReportData", requestOptions)
  const result = await a.json()
  setReportCSVData(result)
    
    // // call api
    // // set csv data
    setReportGenerationWIP(false);
    //  wait(5000);

      // setReportGenerationWIP(false)
    // setReportDates(null)
  
  };

  const openDatePopover = Boolean(anchorEl);
  const id = openDatePopover ? 'simple-popover' : undefined;


  // map initialization 
  const getCurrentWeekDays = () => {
    const weekStart = moment().startOf('week');
  
    const days = [];
    for (let i = -2; i <= 4; i++) {
      days.push(moment(weekStart).add(-i, 'days').format("D"));
    }
    return days;
  }
  React.useEffect(() => {
 
    let google = window.google;
    let map = mapRef.current;
    const myLatlng = new google.maps.LatLng("37.422006", "-122.0841106");

    const mapOptions = {
      zoom: 13,
      center: myLatlng,
      mapTypeControl: false,
      styles: mapStyles,
    }
    map = new google.maps.Map(map, mapOptions);

    setCMap(map)
    // for (let index = 0; index < 10; index++) {
    //   Firebase.database()
    //   .ref(`/realtimetrackingdata/+18622878740/${moment().format("DD-MM-YYYY")}/`)
    //   .push({
    //     status: `active ${index}`,
    //     timestamp: moment().format("YYYY/MM/DD hh:mm A"),
    //     latlng: "12.3,-23.1",
    //     battery: 80,
    //     activity:"driving"
    //   })
    // }
  }, []);

  //user check
   
  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setCurrentUserData(data);
        }
      });
    return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])

  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/tracking/teams/teammembers/${user.email.replace(/\./g, ",")}/`)
      .limitToLast(100)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          let tableData = []
          let liveData = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];

            if (element && element.verificationstatus === "done") {
              const scheduledassignments = element.scheduledassignments[moment().format("DD-MM-YYYY").toString()] ? element.scheduledassignments[moment().format("DD-MM-YYYY").toString()].totalassignments :  0

              if (element.status) {
                result.push(`${element.name} Ph: ${element.phone}`)
                liveData.push([`${element.phone}`, "Active", scheduledassignments])
              }

              tableData.push([`name-|-${element.name}-|-key-|-${keys[index]}-|-status-|-${element.status}-|-scheduledassignments-|-${scheduledassignments}`, element.phone, scheduledassignments, `teammemberstatus:${element.status}-|-${element.phone}-|-${user.email.replace(/\./g,",")}-|-${element.name}`, `deleteTeamMember:${element.phone}-|-${user.email.replace(/\./g,",")}`])
             
            }
          }
          setData(tableData);
          setLiveTrackData(liveData)
          setRegisteredTeammembers(result);
        }
      });
    return () => Firebase.database().ref(`/tracking/teams/teammembers/${user.email.replace(/\./g,",")}/`).off('value', onChildAdd);
  }, [])


  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/tracking/teams/placelist/${user.email.replace(/\./g, ",")}/`)
      .limitToLast(100)
      .on('value', snapshot => {
        const data = snapshot.val()
        if(data){
          let result = []
          let liveData = []
          const keys = Object.keys(data);
          for (let index = keys.length-1; index > -1; index--) {
            const element = data[keys[index]];
            if (moment(element.dateadded, 'DD-MM-YYYY', true).isSame(moment(), 'day')) {
              liveData.push(element)
            }
            result.push([`address-|-${element.addressmaintext || element.address}-|-key-|-${keys[index]}-|-latlng-|-${element.addresslatlng.lat},${element.addresslatlng.lng}`, element.customername, element.addresslocation, element.teammembername,`${element.dateadded}, ${element.timeadded}`, `deleteTeamTrackingPlace:${keys[index]}-|-store:${user.email.replace(/\./g,",")}-|-teammemberphone:${element.teammemberphone}-|-dateadded:${element.dateadded}`])
          }
          setPlacesMapData(liveData)
          setPlacesData(result);
          setBasePlacesData(result)
        }

    });
    return () => Firebase.database().ref(`/tracking/teams/placelist/${user.email.replace(/\./g,",")}/`).off('value', onChildAdd);
  }, [])

  React.useEffect(() => {
   
      // for (var key in markersRef) {
      //   markersRef[key].setMap(null);
      // }
      for (let index = 0; index < placesMapData.length; index++) {
        const point = new google.maps.LatLng(placesMapData[index]?.addresslatlng?.lat, placesMapData[index]?.addresslatlng?.lng);
        // const marker =  new google.maps.Marker({
        //   position: myLatlng,
        //   // icon: {
        //   //   path: squareMarkerSVG,
        //   //   fillColor: "#e91e63",
        //   //   fillOpacity: 1,
        //   //   strokeColor: "#e91e63",
        //   //   scale: 1,
        //   //   anchor: {
        //   //     x: 5,
        //   //     y: 13,
        //   //   },
        //   // },
        //   map: cMap
        // });
  
        // const infowindow = new google.maps.InfoWindow({
        //   content: placesMapData[index].address,
        // });
        // infowindow.open(cMap, marker);

        // setMarkersRef(prevCart => ({
        //   ...prevCart,
        //   [placesMapData[index].trackid]: marker
        // }));

        var regionCircle;
          var regionCircleWindow = new google.maps.InfoWindow({
                  content: `<div class="info-window-content"><p>Client name: ${placesMapData[index].customername} <br />Address  ${placesMapData[index].address}</p></div>`,
                  maxWidth: 500
              });
          var cirleOptions = {
            strokeColor: "#FF0000",
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            clickable: true,
            map: cMap,
            center: point,
            radius: 150
          };
          regionCircle = new google.maps.Circle(cirleOptions);
    
          google.maps.event.addListener(regionCircle, 'click', function (ev) {
            regionCircleWindow.setPosition(ev.latLng);
            regionCircleWindow.open(cMap);
          });
        bounds.extend(point);
      }
      

  }, [cMap, placesMapData]);

  React.useEffect(() => {
    if(!teamLatLng){
      return;
    }
    
      for (var key in markersRef) {
        markersRef[key].setMap(null);
      }
      
      const phones = Object.keys(teamLatLng)
      for (let index = 0; index < phones.length; index++) {
        const element = teamLatLng[phones[index]].split(",");
        if(element && element.length>0){
          const myLatlng = new google.maps.LatLng(element[0], element[1]);
          // setTeamLatLng(prevCart => ({
          //   ...prevCart,
          //   [element]: `${data.latitude},${data.longitude}`
          // }));

        // if(markersRef && markersRef[phones[index]]){
        //   setTimeout(() => {
        //     markersRef[phones[index]].setPosition(myLatlng)
        //     // mapRef.panTo({lat: deliveryGuyLocationLiveTracking.lat,lng: deliveryGuyLocationLiveTracking.lng});
        //     // mapRef.setZoom(11);
        //     // animatingCamera(position.coords.latitude, position.coords.longitude, position.coords.heading);
        //     // getPointOnPath([position.coords.latitude, position.coords.longitude])
    
        //     // TODO 
        //     // mapRef.setHeading(45);
        //   }, 1000)
        // } else{
          const marker =  new google.maps.Marker({
            position: myLatlng,
            icon: {
              path: squareMarkerSVG,
              fillColor: "#e91e63",
              fillOpacity: 1,
              strokeColor: "#e91e63",
              scale: 1,
              anchor: {
                x: 5,
                y: 13,
              },
            },
            map: cMap
          });
    
          const infowindow = new google.maps.InfoWindow({
            content: phones[index],
          });
          infowindow.open(cMap, marker);

          setMarkersRef(prevCart => ({
            ...prevCart,
            [phones[index]]: marker
          }));
          bounds.extend(myLatlng);
          
        }
        
      }
      // if (cMap) {
      // cMap.fitBounds(bounds);
      // }
  }, [cMap, teamLatLng]);

  React.useEffect(() => {
      for (let index = 0; index < liveTrackData.length; index++) {
        const element = liveTrackData[index][0];
        Firebase.database()
        .ref(`/userlivelocationtracking/${element}/`)
        .on('value', snapshot => {
          const data = snapshot.val()
          if(data && data.latitude && data.longitude){
            const myLatlng = new google.maps.LatLng(data.latitude, data.longitude);
  
            setTeamLatLng(prevCart => ({
              ...prevCart,
              [element]: `${data.latitude},${data.longitude}`
            }));
            // bounds.extend(myLatlng);
          }
          // cMap.fitBounds(bounds);
        });
      }
      // if (cMap) {
      //   cMap.fitBounds(bounds);
      //   }
    // const onChildAdd = Firebase.database()
    //   .ref(`/webdeliveryteam/storeadded/${user.email.replace(/\./g,",")}/`)
    //   .on('value', snapshot => {
    //     const data = snapshot.val()
    //     if(data){
    //       let result = []
    //       let liveData = []
    //       const keys = Object.keys(data);
    //       for (let index = keys.length-1; index > -1; index--) {
    //         const element = data[keys[index]];
    //         result.push([element.name, element.phone, element.scheduleddeliveryjobs, element.finisheddeliveryjobs, `deleteDeliveryPerson:${element.phone}-|-${user.email.replace(/\./g,",")}`])
    //         liveData.push([element.name, "Idle"])
    //       }
    //       setData(result);
    //       setLiveTrackData(liveData)
    //     }

    // });
    // return () => Firebase.database().ref(`/webdeliveryteam/storeadded/${user.email.replace(/\./g,",")}/`).off('value', onChildAdd);

  }, [liveTrackData, cMap])
  
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const deliveryHandleOpen = () => {
    setDeliveryOpen(true);
  };

  const deliveryHandleClose = () => {
    setDeliveryOpen(false);
  };

  const adderessTrackClose = () => {
    setTimeLineData(null);
    setAdderssTrackOpen(false);
  }
  const teamMemberTrackClose = () => {
    setTeamMemberTrackOpen(false)
  }

  const teamsSimulationModalClose = () => {
    setSimulationAddress(null);
    setSimulationAddressLatLng(null);
    setTeamsSimulationModal(false)
    setSimulationPersonAddress(null)
    setSimulationPersonAddressLatLng(null);
  }
  
  const handleClick = (val) => {
    setSelectedDeliveryPerson(val);
  }
  const getPhoneFromDisplayName = (displayName) => {
    return displayName.split("Ph: ")[1]
  }
  const getNameFromDisplayName = (displayName) => {
    return displayName.split("Ph: ")[0]
  }
  const savePlace = async () => {
    let scheduleDate = moment().format("DD-MM-YYYY").toString()
    let scheduleTime = moment().format("hh:mm A").toString()
    if (scheduleDateTime) {
      scheduleDate = scheduleDateTime.format("DD-MM-YYYY").toString()
      scheduleTime = scheduleDateTime.format("hh:mm A").toString()
    }

    const ref = await Firebase.database()
        .ref(`/tracking/teams/placelist/${user.email.replace(/\./g,",")}/`)
        .push({
            "address": address,
            "dateadded":scheduleDate,
            "timeadded": scheduleTime,
            "customername": customerName,
            "addressmaintext": addressMainText,
            "addresslocation": addressLocation,
            "addressadjustedbycustomer": false,
            "addresslatlng": addressLatLng,
            "customer": user.email.replace(/\./g, ","),
            "teammemberlatlng": currentUserData.storeaddresslatlng,
            "teammemberphone": getPhoneFromDisplayName(selectedDeliveryPerson),
            "teammembername": getNameFromDisplayName(selectedDeliveryPerson),
            "visitreview": "",
            "vististatus": "not started",
            "destinationphone": customerPhone,
            "discussion": "",
            "images": "",
            "tipamount": "", 
            "tracktype": "visit",
            "cost": "Pricing Not Setup Yet",
            "status": "initialized",
            "distance": (distanceMatrixData && distanceMatrixData.distance) ? distanceMatrixData.distance.text : "Not Ready Yet",
            })
        
        Firebase.database()
            .ref(`/tracking/placeslist/${user.email.replace(/\./g, ",")}/${ref.key}/timeline/${scheduleDate}`).push({
                badgeColor: "danger",
                title: "Initial Place",
                titleColor: "danger",
                timeStamp: moment().format("YYYY/MM/DD hh:mm A"),
                body: `
                   Initial Place was created and assinged to ${getNameFromDisplayName(selectedDeliveryPerson)}.
                `
            })
        
        Firebase.database()
            .ref(`/tracking/placeslist/${user.email.replace(/\./g, ",")}/${ref.key}/`).update({
               "trackid": ref.key,
               "weburl":`https://track.ac/${ref.key}`,
            })
        
        Firebase.database()
            .ref(`/tracking/teams/teammembers/${user.email.replace(/\./g,",")}/${getPhoneFromDisplayName(selectedDeliveryPerson)}/scheduledassignments/${scheduleDate}`)
            .once('value', snapshot => {
              snapshot.ref.child("clients").push({
                "dateadded": scheduleDate,
                "timeadded": scheduleTime,
                "customername": customerName,
                "addressmaintext": addressMainText,
                "addresslocation": addressLocation,
                "addressadjustedbycustomer": false,
                "addresslatlng": addressLatLng,
              })
              
              let val = snapshot.val()
              if (val) {
                snapshot.ref.child("totalassignments").set(parseInt(val.totalassignments) + 1) 
              } else {
                snapshot.ref.child("totalassignments").set(1)
              }
            })
        
        Firebase.database()
            .ref(`/tracking/teams/teammembers/${user.email.replace(/\./g,",")}/${getPhoneFromDisplayName(selectedDeliveryPerson)}/timeline/${scheduleDate}`)
            .push({
                  badgeColor: "danger",
                  title: "Place Assigned",
                  titleColor: "success",
                  timeStamp: scheduleDateTime ? scheduleDateTime.format("YYYY/MM/DD hh:mm A") : moment().format("YYYY/MM/DD hh:mm A"),
                  address: address,
                  addresslatlng: addressLatLng,
                  mapimage: `https://maps.googleapis.com/maps/api/staticmap?maptype=satellite&center=${addressLatLng.lat},${addressLatLng.lng}&zoom=18&size=550x300&maptype=roadmap&markers=color:red%7Clabel:G%7C${addressLatLng.lat},${addressLatLng.lng}&key=AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk`,
                  addressmaintext: addressMainText,
                  addresslocation: addressLocation,
                  addressadjustedbycustomer: false,
                  body: `
                      The address ${address} is assigned to this member. 
                  `
                })
        Firebase.database()
        .ref(`/scheduledjobs/tracking/${getPhoneFromDisplayName(selectedDeliveryPerson)}/${scheduleDate}/${ref.key}`)
        .set({
          "address": address,
          "placeid": ref.key, 
          "dateadded": scheduleDate,
          "timeadded": scheduleTime,
          "customername": customerName,
          "addressmaintext": addressMainText,
          "addresslocation": addressLocation,
          "addressadjustedbycustomer": false,
          "addresslatlng": addressLatLng,
          "customer": user.email.replace(/\./g, ","),
          "teammemberlatlng": currentUserData.storeaddresslatlng,
          "teammemberphone": getPhoneFromDisplayName(selectedDeliveryPerson),
          "teammembername": getNameFromDisplayName(selectedDeliveryPerson),
          "destinationphone": customerPhone,
        })
      Firebase.database()
        .ref(`/webadmin/${user.email.replace(/\./g,",")}/`)
        .once('value', snapshot => {
          let val = snapshot.val()
          let a = 0, b = 0;
          if(val.totalplaces) {
            a = val.totalplaces
          }
          if(val.totalteammembers) {
            b = val.totalteammembers
          }
          snapshot.ref.update({
            totalplaces: parseInt(a) + 1,
            usedcredits: parseInt(val.usedcredits) + 1
          })
        })
    scheduleGeofenceMonitor(customerName, getPhoneFromDisplayName(selectedDeliveryPerson), addressLatLng, scheduleDate, scheduleDateTime ? scheduleDateTime.add(30, "minutes").format("hh:mm A").toString() : moment().add(30, "minutes").format("hh:mm A").toString())
    setAddress(null);
    setAddressLatLng(null)
    setCustomerPhone(null)
    setAddressLocation(null)
    setSelectedDeliveryPerson(null)
    setScheduleDateTime(null)
    setDeliveryOpen(false);
  }
  const saveTeammember = async () => {
      Firebase.database()
      .ref(`/activeteammembers/${user.email.replace(/\./g,",")}/${deliveryGuyPhone}`)
      .set(deliveryGuyName)
      let t = {}
      t[moment().format("DD-MM-YYYY").toString()] = 0
      Firebase.database()
      .ref(`/tracking/teams/teammembers/${user.email.replace(/\./g,",")}/${deliveryGuyPhone}`)
      .set({
            id: "storeid",
            name: deliveryGuyName,
            phone: deliveryGuyPhone,
            verificationstatus: "done",
            lastupdated: moment().format("YYYY/MM/DD"),
            totalassignments: 0,
            todaysassignments: 0,
            scheduledassignments: t,
            finishedassignments: 0,
            status: true
          })
        
      Firebase.database()
      .ref(`/tracking/teams/teammembers/${user.email.replace(/\./g,",")}/${deliveryGuyPhone}/timeline`)
      .push({
            badgeColor: "danger",
            title: "Team member Registered",
            titleColor: "danger",
            timeStamp: moment().format("YYYY/MM/DD hh:mm A"),
            body: `
                Team member is registered with the platform.
            `
      })
        
    Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g,",")}/`)
      .once('value', snapshot => {
        let val = snapshot.val()
        let a = 0
        if(val.totalteammembers) {
          a = val.totalteammembers
        }
        snapshot.ref.update({
          totalteammembers: parseInt(a) + 1
        })
      })
      
    setDeliveryGuyPhone(null);
    setDeliveryGuyName(null)
    setOpen(false);
  }
  const deliveryGuyNameChange = (e) => {
    setDeliveryGuyName(e.target.value);
  }
  const deliveryGuyPhoneChange = (e) => {
    setDeliveryGuyPhone(`${currentUserData.countrycode}${e.target.value}`);
  }
  const noTeamMember = () =>{
    return (
       <GridContainer justify = "space-between"
        style = {{
            color: "grey",
            justifyContent: "center",
            height: "200px",
            display: "inline-block",
            textAlign: "center"
          }}>
         <h3>There are no team members yet</h3>
       </GridContainer>
    )
  }

  const noPlaces = () =>{
    return (
       <GridContainer justify = "space-between"
        style = {{
            color: "grey",
            justifyContent: "center",
            height: "200px",
            display: "inline-block",
            textAlign: "center"
          }}>
         <h3>There are no places yet</h3>
       </GridContainer>
    )
  }
  const customerPhoneChange = (e) => {
    setCustomerPhone(`${currentUserData.countrycode}${e.target.value}`);
  }

  const dateTimeChange = (e) => {
    setScheduleDateTime(e)
  }

  const customerNameChange = (e) => {
    setCustomerName(`${e.target.value}`);
  }

  React.useEffect(async () => {
    if (autoCompleteAddress) {
      setSimulationAddress(autoCompleteAddress.value.description)
      geocodeByAddress(autoCompleteAddress.value.description)
      .then(results => getLatLng(results[0]))
      .then((rootData) => {
        if (rootData) {
          setSimulationAddressLatLng(rootData)
          setSimulationPersonAddress(autoCompleteAddress.value.structured_formatting.secondary_text)
          geocodeByAddress(autoCompleteAddress.value.structured_formatting.secondary_text)
            .then(results => getLatLng(results[0]))
            .then((data) => {
              if (data) {
                setSimulationPersonAddressLatLng(data)
              }
            })
        }
       
         
      })
      }
  }, [autoCompleteAddress]);
  const getSimulationAddress = (address) => {
    setAutoCompleteAddress(address)
    // if (simulationAddressLatLng || simulationPersonAddressLatLng) {
    //   return;
    // }
  };
  const getCoordinates = (address) => {
    geocodeByAddress(address.value.description)
      .then(results => getLatLng(results[0]))
      .then((data) => {
        // console.log('LAT: ', data.lat);
        // console.log('LNG: ', data.lng);
        setAddress(address.value.description)
        if (address.value && address.value.structured_formatting && address.value.structured_formatting.secondary_text) {
          setAddressLocation(address.value.structured_formatting.secondary_text)
          setAddressMainText(address.value.structured_formatting.main_text)
        }
        setAddressLatLng(data)

        // const request = {
        //   origins: [currentUserData.storeaddresslatlng],
        //   destinations: [data],
        //   travelMode: google.maps.TravelMode.DRIVING,
        //   unitSystem: google.maps.UnitSystem.METRIC,
        //   avoidHighways: false,
        //   avoidTolls: false,
        // };
      
        // // get distance matrix response
        // service.getDistanceMatrix(request).then((response) => {
        //   if(response && response.rows && response.rows.length > 0 && response.rows[0].elements.length > 0){
        //     setDistanceMatrixData(response.rows[0].elements[0])
        //   }
        // })
      })
  };
  const openAddressCallback = (address) => {
    setTrackingDetails(address)
    setAdderssTrackOpen(true)
  }
  const hideAlert = () => {
    setAlert(null);
  };
  

  const buildGnattChartSummary = (memberPhone) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", currentUserData.apikey);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({phone: memberPhone, date: moment().format("DD-MM-YYYY")})
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://us-central1-onroad3-e593b.cloudfunctions.net/buildGnattChart", requestOptions)

  }
  const buildTrackSummary = (memberPhone) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", currentUserData.apikey);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({phone: memberPhone, date: moment().format("DD-MM-YYYY")})
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://us-central1-onroad3-e593b.cloudfunctions.net/buildTrackSummary", requestOptions)

  }

  const scheduleGeofenceMonitor = (customername, teammemberphone, customerlatlng, date, time) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", currentUserData.apikey);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({customername: customername, teammemberphone: teammemberphone, customerlatlng: customerlatlng, date: date, time: time})
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://us-central1-onroad3-e593b.cloudfunctions.net/monitorGeofence", requestOptions)
  }
  const handleDecimalTime = (n) => {
    const t = (n + "").split(".")
    if (t[1].length == 1) {
      return `${t[0]}.0${t[1]}`
    }
    return n
  }
  const openTeamMemberCallback = (member) => {
    if (!teamLatLng[member[1]]) {
      setAlert(
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={`No location data`}
          onConfirm={() => hideAlert()}
          showConfirm={false}
        >
          Please make sure {member[1]} installed the app and enabled the tracking
        </SweetAlert>
      );
      setTimeout(hideAlert, 2000);
      return;
    }

    if (member[2] == 0) {
      setAlert(
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={`No Scheduled Jobs Yet`}
          onConfirm={() => hideAlert()}
          showConfirm={false}
        >
          Please make sure <b>{member[0]}</b> assinged a place for today.
        </SweetAlert>
      );
      setTimeout(hideAlert, 2000);
      return;
    }

    Firebase.database()
    .ref(`/tracking/teams/teammembers/${user.email.replace(/\./g,",")}/${member[1]}/scheduledassignments/`)
    .once('value', snapshot => {
      const data = snapshot.val()
      if (data) {
        setAvgDailyJobs(
          {
            labels: getCurrentWeekDays().reverse(),
            series: [[
              data[moment().subtract(6, "days").format("DD-MM-YYYY").toString()]?.totalassignments || 0,
              data[moment().subtract(5, "days").format("DD-MM-YYYY").toString()]?.totalassignments || 0,
              data[moment().subtract(4, "days").format("DD-MM-YYYY").toString()]?.totalassignments || 0,
              data[moment().subtract(3, "days").format("DD-MM-YYYY").toString()]?.totalassignments || 0,
              data[moment().subtract(2, "days").format("DD-MM-YYYY").toString()]?.totalassignments || 0,
              data[moment().subtract(1, "days").format("DD-MM-YYYY").toString()]?.totalassignments || 0,
              data[moment().format("DD-MM-YYYY").toString()]?.totalassignments || 0
            ]],
          }
        )
        
        if (data[moment().format("DD-MM-YYYY").toString()] && data[moment().format("DD-MM-YYYY").toString()].clients) {
          let result = []
          const clients = data[moment().format("DD-MM-YYYY").toString()].clients
          const keys = Object.keys(clients);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = clients[keys[index]];
            result.push(
              {
                clientname: element.customername,
                timeadded: element.timeadded,
                address: element.addressmaintext,
                city: element.addresslocation,
                latlng: element.addresslatlng,
                clientid: keys[index]
              }
            )
          }
          setScheduledClientsForToday(result);
          }
      }
    })
    
    // Firebase.database()
    // .ref(`/tracking/teams/teammembers/${user.email.replace(/\./g,",")}/${member[1]}/piechart/`)
    // .once('value', snapshot => {
    //   const data = snapshot.val()
    //   if (data) {
    //     setPieChartData({
    //       labels: ["62%", "32%", "6%"],
    //       series: [62, 32, 6],
    //     })
    //   }
    // })

    Firebase.database()
    .ref(`/tracking/teams/teammembers/${user.email.replace(/\./g,",")}/${member[1]}/timeline/${moment().format("DD-MM-YYYY").toString()}`)
    .once('value', snapshot => {
      const data = snapshot.val()
      if (data) {
        let result = []
        const keys = Object.keys(data);
        for (let index = keys.length - 1; index > -1; index--) {
          const element = data[keys[index]];
          result.push(
            {
              address: element.address,
              addresslatlng: element.addresslatlng,
              badgeColor: colorArray[Math.floor(Math.random() * colorArray.length)],
              badgeIcon: iconArray[Math.floor(Math.random() * iconArray.length)],
              title: element.title,
              titleColor: element.titleColor,
              userTimeStamp: element.timeStamp,
              mapimage: element.mapimage || null,
              img: element.img || null,
              body:element.body,
            }
          )
        }
        setUserTimelineData(result);
      }
    })
    
    Firebase.database()
    .ref(`/realtimetrackingdata/${member[1]}/${moment().format("DD-MM-YYYY")}`)
    .limitToLast(10)
    .on('value', snapshot => {
      const data = snapshot.val()
      if (data) {
        
        let result = []
        const keys = Object.keys(data);
        for (let index = 0; index < keys.length - 1; index++) {
          const element = data[keys[index]];
          result.push(
            [
              element.status,
              element.timestamp,
              trackDataDetails({battery: element.battery, location: element.latlng, activity: element.activity}),
            ]
          )
        }
        setUserRealtimeActivtyData(result.reverse());
      }
    })

    Firebase.database()
      .ref(`/tracking/teams/teammembers/${user.email.replace(/\./g, ",")}/${member[1]}/tracksummary/${moment().format("DD-MM-YYYY")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setTrackSummary(data);
          const hrsdriving = parseFloat(100 *( getMinutesFromTimestring(data.hoursdriving) || 0) / (getMinutesFromTimestring(data.hourstracked) || 10)).toFixed()
          const hrsatclient = parseFloat(100 * (getMinutesFromTimestring(data.hoursatclientlocation) || 0) / (getMinutesFromTimestring(data.hourstracked) || 10)).toFixed()
          const hrsoutsideclient = parseFloat(100 * (getMinutesFromTimestring(data.hoursoutsideclientlocation) || 0) / (getMinutesFromTimestring(data.hourstracked) || 10)).toFixed()
          setPieChartData({
            labels: [`${hrsdriving}%`, `${hrsatclient}%`, `${hrsoutsideclient}%`],
            series: [hrsdriving, hrsatclient, hrsoutsideclient],
          })
          
          if (data.clients) {
            
            let result = [
              ["Date", "Client name", "Client location", "Team member", "Time in", "Time out", "Hours spent","Hours spent outside", "Attendance"]
            ]
            let gnattChartResult = {}
            const keys = Object.keys(data.clients);
            if (keys.length < parseInt(member[2])) {
              buildTrackSummary(member[1]);
            }
            for (let index = 0; index < keys.length; index++) {
              const element = data.clients[keys[index]];
              result.push(
                [
                  moment().format("DD-MM-YYYY"),
                  element.clientname,
                  element.clientlocation,
                  member[0],
                  element.timein,
                  element.timeout,
                  element.hoursatclientlocation,
                  element.hoursoutsideclientlocation,
                  element.timein == 0 ? "Absent" : "Present"
                ]
              )
              if (element && element.gnattchart) {
                gnattChartResult[element.clientname] = element.gnattchart.map((i) => { var t = i.split("-"); return [parseFloat(handleDecimalTime(t[0])), parseFloat(handleDecimalTime(t[1]))] }).flat()
              }
            }
            setGnattChartData(gnattChartResult)
            setOnePersonCSV(result)
          }
        } else {
        buildTrackSummary(member[1])
          // const url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?keyword=coffee&location=37.773849,-121.958944&radius=1500&type=restaurant&key=AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk`
          // var config = {
          //   method: 'get',
          //   url: url,
          //   headers: {"Access-Control-Allow-Origin": "*"}
          // };

          // axios(config)
          // .then(function (response) {
          //   console.log("======nearbysearch====",JSON.stringify(response.data));
          // })
          // .catch(function (error) {
          //   console.log(error);
          // });
          
        }
      })
    setTeamMemberDetails(member)
    setTeamMemberTrackOpen(true)
  }

  const deleteHandleLastcaseCallback = (t) => {
    if(t == 'member'){
      setData([])
      setTeamLatLng()
    }
    if(t == 'place'){
      setPlacesData([])
      setPlacesMapData([])
    }
  }

  const [timeLineData, setTimeLineData] = React.useState();

  const TeamMemberLivetrackMap = (props) => {

    if(!props || !props.data){
      return;
    }
    
    const mapRef = React.useRef(null);
    const google = window.google;
    const [markerRef, setMarkerRef] = React.useState(null);
    const [locationLiveTracking, setLocationLiveTracking] = React.useState(null);
    
    const temp = teamLatLng[props.data[1]].split(",")
    const userCurrentLatLng = new google.maps.LatLng(parseFloat(temp[0]), parseFloat(temp[1]));
    
    function createCenterControl(map) {
      const controlButton = document.createElement("button");
    
      // Set CSS for the control.
      controlButton.style.backgroundColor = "#fff";
      controlButton.style.border = "2px solid #fff";
      controlButton.style.borderRadius = "3px";
      controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlButton.style.color = "rgb(25,25,25)";
      controlButton.style.cursor = "pointer";
      controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
      controlButton.style.fontSize = "14px";
      controlButton.style.lineHeight = "38px";
      controlButton.style.margin = "8px 0 22px 8px";
      controlButton.style.padding = "0 5px";
      controlButton.style.textAlign = "center";
    
      controlButton.textContent = `${moment().format("DD-MM-YYYY")}`;
      controlButton.title = "Change the date";
      controlButton.type = "button";
    
      // Setup the click event listeners: simply set the map to Chicago.
      controlButton.addEventListener("click", () => {
        
      });
    
      return controlButton;
    }    

    const dropDownDetails = () => {
      var z = document.createElement('div'); // is a node
      z.innerHTML = `<Card id="myControl">
      <CardHeader>
        <h4 className={classes.cardTitle}>
          Description about product
        </h4>
        <p className={classes.cardCategory}>
          More information here
        </p>
      </CardHeader>
      <CardBody>
        Collaboratively administrate empowered markets via
        plug-and-play networks. Dynamically procrastinate B2C
        users after installed base benefits.
        <br />
        <br />
        Dramatically visualize customer directed convergence
        without revolutionary ROI.
      </CardBody>
    </Card>`;
     return z;
    }

    React.useEffect(() => {
      if (props.data[1]) {
        const onChildAdd = Firebase.database()
          .ref(`/userlivelocationtracking/${props.data[1]}`)
          .on('value', snapshot => {
            const data = snapshot.val()
            if (data && data.latitude && data.longitude) {
              setLocationLiveTracking({
                lat: parseFloat(data.latitude),
                lng: parseFloat(data.longitude)
              })
            }
          });
        // Stop listening for updates when no longer required
        return () => Firebase.database().ref(`/userlivelocationtracking/${props.data[1]}`).off('value', onChildAdd);
      }
    }, []);

    React.useEffect(() => {
      if (locationLiveTracking && markerRef) {
        setTimeout(() => {
          markerRef.setPosition({
            lat: locationLiveTracking.lat,
            lng: locationLiveTracking.lng
          })
          // mapRef.panTo({lat: deliveryGuyLocationLiveTracking.lat,lng: deliveryGuyLocationLiveTracking.lng});
          // mapRef.setZoom(11);
          // animatingCamera(position.coords.latitude, position.coords.longitude, position.coords.heading);
          // getPointOnPath([position.coords.latitude, position.coords.longitude])
  
          // TODO 
          // mapRef.setHeading(45);
        }, 1000)
      }
    }, [locationLiveTracking])

    React.useEffect(() => {
      let google = window.google;
      let map = mapRef.current;
      let lat = "37.7799273"
      let lng = "-121.9780153";
      const myLatlng = new google.maps.LatLng(lat, lng);
      const mapOptions = {
        zoom: 8,
        center: myLatlng,
        mapTypeControl: false,
        // mapTypeId: "satellite",
        styles: mapStyles,
      }
  
      map = new google.maps.Map(map, mapOptions);
      
      const centerControlDiv = document.createElement("div");
      // Create the control.
      const centerControl = createCenterControl(map);
      // Append the control to the DIV.
      centerControlDiv.appendChild(centerControl);

      //       <div id="myControl" style="display:none"><select><option>hello
      // world^^</option></select></div>


      // var customMapControls = document.createElement('div');
      // customControlDiv = document.getElementById("myControl").innerHTML;
      // customMapControls.innerHTML = customControlDiv;

      // const liveTrackMarker = new google.maps.Marker({
      //   position: userCurrentLatLng,
      //   icon: {
      //     path: squareMarkerSVG,
      //     fillColor: "#e91e63",
      //     fillOpacity: 1,
      //     strokeColor: "#e91e63",
      //   },
      //   map: map,
      // });

      // setMarkerRef(liveTrackMarker);
    
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);

      // const marker = new google.maps.Marker({
      //   position: myLatlng,
      //   map: map,
      //   animation: google.maps.Animation.DROP,
      //   title: props.data[0],
      // });
  
      // const contentString =
      //   `<div class="info-window-content"><h2>${props.data[0]}</h2></div>`
  
      // const infowindow = new google.maps.InfoWindow({
      //   content: contentString,
      // });
  
      // google.maps.event.addListener(marker, "click", function () {
      //   infowindow.open(map, marker);
      // });
      // const directionsService = new google.maps.DirectionsService();

      function autoRefresh(map, pathCoords) {
        var i, route;
  
        route = new google.maps.Polyline({
          path: [],
          geodesic: true,
          strokeColor: "#000000",
          strokeWeight: 5,
          editable: false,
          map: map,
        });
        const startLatLng = new google.maps.LatLng(pathCoords[0].lat(), pathCoords[0].lng())
        const liveTrackMarker = new google.maps.Marker({
          position: startLatLng,
          icon: {
            path: squareMarkerSVG,
            fillColor: "#e91e63",
            fillOpacity: 1,
            strokeColor: "#e91e63",
          },
          map: map,
        });
  
        for (i = 0; i < pathCoords.length; i++) {
          setTimeout(
            function (coords) {
              route.getPath().push(coords);

              if(liveTrackMarker){
                liveTrackMarker.setPosition({
                  lat: coords.lat(),
                  lng: coords.lng()
                })
                if( i == pathCoords.length - 1){
                  setMarkerRef(liveTrackMarker)
                }
              }
            },
            50 * i,
            pathCoords[i]
          );
        }
      }
      // const directionsDisplay = new google.maps.DirectionsRenderer({
      //   map: map,
      //   suppressMarkers: true,
      //   // polylineOptions: {
      //   //   strokeWeight: 5,
      //   //   strokeColor: "#808080",
      //   // },
      //   preserveViewport: true,
      // });
      let bounds = new google.maps.LatLngBounds();
      // const test = "trackhistory/+18622878740/13-11-2022/"
      if (scheduledClientsForToday && scheduledClientsForToday.length > 0) {
        for (let index = 0; index < scheduledClientsForToday.length; index++) {
          const client = scheduledClientsForToday[index];
          const point = new google.maps.LatLng(client.latlng.lat, client.latlng.lng)
          bounds.extend(point)
          var regionCircle;
          var regionCircleWindow = new google.maps.InfoWindow({
                  content: `<div class="info-window-content"><p>Client name: ${client.clientname} <br />Address  ${client.address}</p></div>`,
                  maxWidth: 500
              });
          var cirleOptions = {
            strokeColor: "#FF0000",
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            clickable: true,
            map: map,
            center: point,
            radius: 150
          };
          regionCircle = new google.maps.Circle(cirleOptions);
    
          google.maps.event.addListener(regionCircle, 'click', function (ev) {
            regionCircleWindow.setPosition(ev.latLng);
            regionCircleWindow.open(map);
          });

          new google.maps.Marker({
            map: map,
            position: point,
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: '#00F',
                fillOpacity: 0.6,
                strokeColor: '#00A',
                strokeOpacity: 0.9,
                strokeWeight: 1,
                scale: 7
            }
          });

        }
      }
      Firebase.database()
        .ref(`/realtimetrackingdata/${teamMemberDetails[1]}/${moment().format("DD-MM-YYYY")}/`)
          .once('value', snapshot => {
            
            const data = snapshot.val()
            if (data) {
              let result = []
              const keys = Object.keys(data);
              for (let index = 0; index < keys.length - 1; index++) {
                const element = data[keys[index]]["latlng"].split(",");
                const point = new google.maps.LatLng(parseFloat(element[0]), parseFloat(element[1]))
                result.push(point)
                bounds.extend(point)
              }
              var tourplan = new google.maps.Polyline({
                path:result,
                strokeColor:"#0000FF",
                strokeOpacity:0.6,
                strokeWeight:5
            });
            
            tourplan.setMap(map);
            map.fitBounds(bounds);
            autoRefresh(map, result)
            }
           
          })

      // directionsService.route(
      //   {
      //     destination: {
      //       lat: 37.706576,
      //       lng: -121.89552,
      //     },
      //     origin: {
      //       lat: 37.7799273,
      //       lng: -121.9780153
      //     },
      //     travelMode: "DRIVING",
      //   },
      //   (result, status) => {
      //     console.log("=====direction result====", result)
      //     if (status == "OK") {
      //       // setLiveTrackMarkerRef(new google.maps.Marker({
      //       //   map: map
      //       // }));
      //       // const a = Date.now()
      //       for (let index = 0; index < result.routes[0].overview_path.length; index++) {
      //         const element = result.routes[0].overview_path[index];
      //         Firebase.database()
      //         .ref(`/trackhistory/+18622878740/${moment().format("DD-MM-YYYY")}`)
      //         .push(
      //           {"location": `${element.lat()},${element.lng()}`,
      //            "servertimestamp": Firebase.database.ServerValue.TIMESTAMP,
      //            "localtimestamp": Date.now()
      //           }
      //         )
      //       }
           
      //       bounds.union(result.routes[0].bounds);
      //       map.fitBounds(bounds);
  
      //       var display = new google.maps.DirectionsRenderer({preserveViewport: true});
  
      //       display.setMap(map);
      //       directionsDisplay.setDirections(result);
  
      //       autoRefresh(map, result.routes[0].overview_path);
      //     }
      //   }
      // );
    }, []);

    return (
      <>
        <div
          style={{
            height: `600px`,
            borderRadius: "6px",
            overflow: "hidden",
          }}
          ref={mapRef}
        />
      </>
    );
  };

  const SimulationLivetrackMap = () => {
      if(!simulationAddressLatLng || !simulationPersonAddressLatLng){
        return (<></>)
      }
      const mapRef = React.useRef(null);
      const google = window.google;
      const [markerRef, setMarkerRef] = React.useState(null);
      const [locationLiveTracking, setLocationLiveTracking] = React.useState(null);
      const directionsService = new google.maps.DirectionsService();

      function createCenterControl(map) {
        const controlButton = document.createElement("button");
      
        // Set CSS for the control.
        controlButton.style.backgroundColor = "#fff";
        controlButton.style.border = "2px solid #fff";
        controlButton.style.borderRadius = "3px";
        controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        controlButton.style.color = "rgb(25,25,25)";
        controlButton.style.cursor = "pointer";
        controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
        controlButton.style.fontSize = "14px";
        controlButton.style.lineHeight = "38px";
        controlButton.style.margin = "8px 0 22px 8px";
        controlButton.style.padding = "0 5px";
        controlButton.style.textAlign = "center";
      
        controlButton.textContent = `${moment().format("MM-DD-YYYY")}`;
        controlButton.title = "Change the date";
        controlButton.type = "button";
      
        // Setup the click event listeners: simply set the map to Chicago.
        controlButton.addEventListener("click", () => {
          
        });
      
        return controlButton;
      }
    
      const countDecimals = (value) => { 
        if ((value % 1) != 0) 
            return value.toString().split(".")[1].length;  
        return 0;
      }
     
      React.useEffect(() => {
          let google = window.google;
          let map = mapRef.current;
          const myLatlng = new google.maps.LatLng(simulationAddressLatLng.lat, simulationAddressLatLng.lng);
          const mapOptions = {
            zoom: 8,
            center: myLatlng,
            mapTypeControl: false,
            // mapTypeId: "satellite",
            styles: mapStyles,
          }
      
          map = new google.maps.Map(map, mapOptions);
          

          
          const centerControlDiv = document.createElement("div");
          // Create the control.
          const centerControl = createCenterControl(map);
          // Append the control to the DIV.
          centerControlDiv.appendChild(centerControl);
      
          map.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);
          
          const personLatlng = new google.maps.LatLng(simulationPersonAddressLatLng.lat, simulationPersonAddressLatLng.lng);

          const marker = new google.maps.Marker({
            position: personLatlng,
            map: map,
            animation: google.maps.Animation.DROP,
            title: "Starting To Job",
          });
      
          const contentString =
            `<div class="info-window-content"><p>Person name: ${simulationTeammemberName} <br />Starting time ${moment().subtract(5, "hours").format("MM-DD-YYYY, hh:mm:ss A")}</p></div>`
      
          const infowindow = new google.maps.InfoWindow({
            content: contentString,
          });
      
          google.maps.event.addListener(marker, "click", function () {
            infowindow.open(map, marker);
          });
        
          var regionCircle;
          var regionCircleWindow = new google.maps.InfoWindow({
                  content: `<div class="info-window-content"><p>Client name: ${simulationClientName} <br />Address  ${simulationAddress}</p></div>`,
                  maxWidth: 500
              });
        
          var cirleOptions = {
            strokeColor: "#FF0000",
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            clickable: true,
            map: map,
            center: new google.maps.LatLng(simulationAddressLatLng.lat, simulationAddressLatLng.lng),
            radius: 150
          };
          regionCircle = new google.maps.Circle(cirleOptions);
    
          google.maps.event.addListener(regionCircle, 'click', function (ev) {
            regionCircleWindow.setPosition(ev.latLng);
            regionCircleWindow.open(map);
          });
      
          function autoRefresh(map, pathCoords) {
            var i, route;
      
            route = new google.maps.Polyline({
              path: [],
              geodesic: true,
              strokeColor: "#000000",
              strokeWeight: 5,
              editable: false,
              map: map,
            });
            const startLatLng = new google.maps.LatLng(pathCoords[0].lat(), pathCoords[0].lng())
            const liveTrackMarker = new google.maps.Marker({
              position: startLatLng,
              icon: {
                path: squareMarkerSVG,
                fillColor: "#e91e63",
                fillOpacity: 1,
                strokeColor: "#e91e63",
              },
              map: map,
            });
      
            for (i = 0; i < pathCoords.length; i++) {
              
              setTimeout(
                function (coords) {
                  route.getPath().push(coords);
    
                  if(liveTrackMarker){
                    liveTrackMarker.setPosition({
                      lat: coords.lat(),
                      lng: coords.lng()
                    })
                    if( i == pathCoords.length - 1){
                      setMarkerRef(liveTrackMarker)
                    }
                  }
                },
                50 * i,
                pathCoords[i]
              );
            }
          }

          let bounds = new google.maps.LatLngBounds();
          bounds.extend(myLatlng);
          directionsService.route(
            {
              destination: simulationAddressLatLng,
              origin: simulationPersonAddressLatLng,
              travelMode: "DRIVING",
            },
            (firstResult, status) => {
              if (status == "OK") {
                setSimulationTrackingData(firstResult.routes[0].overview_path)
                bounds.union(firstResult.routes[0].bounds);
                map.fitBounds(bounds);
                var display = new google.maps.DirectionsRenderer({preserveViewport: true});
      
                display.setMap(map);    
                // autoRefresh(map, firstResult.routes[0].overview_path);

                const latDistance = (1 / ((2 * Math.PI / 360) * 6378.137)) / 1000;  //1 meter in degree
                const newLat = parseFloat(simulationAddressLatLng.lat) + 500 * latDistance
                const lngDistance = (1 / ((2 * Math.PI / 360) * 6378.137)) / 1000;  //1 meter in degree

                const newLng = parseFloat(simulationAddressLatLng.lng) + (500 * lngDistance) / Math.cos(parseFloat(simulationAddressLatLng.lat) * (Math.PI / 180));
                const newLatLng = {lat: newLat, lng: newLng}
                directionsService.route(
                  {
                    destination: newLatLng,
                    origin: simulationAddressLatLng,
                    travelMode: "WALKING",
                  },
                  (newResult, status) => {
                    if (status == "OK") {
                      bounds.union(newResult.routes[0].bounds);
                      map.fitBounds(bounds);
                      var display = new google.maps.DirectionsRenderer({preserveViewport: true});
                      display.setMap(map);

                      setSimulationTimeline([
                        {
                          badgeColor: colorArray[Math.floor(Math.random() * colorArray.length)],
                          badgeIcon: iconArray[Math.floor(Math.random() * iconArray.length)],
                          title: `Entered client location`,
                          titleColor: colorArray[Math.floor(Math.random() * colorArray.length)],
                          userTimeStamp: moment().format("YYYY/MM/DD hh:mm A"),
                          timespent: "showspinner",
                          body: `
                              Team member entered client location ${simulationAddress} and stayed there for 5 hrs
                          `
                        },
                        {
                          badgeColor: colorArray[Math.floor(Math.random() * colorArray.length)],
                          badgeIcon: iconArray[Math.floor(Math.random() * iconArray.length)],
                          title: `Driving`,
                          titleColor: colorArray[Math.floor(Math.random() * colorArray.length)],
                          userTimeStamp: moment().subtract(1, "hours").format("YYYY/MM/DD hh:mm A"),
                          mapimage: `https://maps.googleapis.com/maps/api/staticmap?center=${newLatLng.lat},${newLatLng.lng}&zoom=11&size=550x300&maptype=roadmap&markers=color:red%7Clabel:A%7C${newLatLng.lat},${newLatLng.lng}&path=enc:${newResult.routes[0].overview_polyline}&key=AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk`,
                          timespent: "1 Hrs",
                          body: `
                          Team member drove to location new location
                          `
                        },
                        {
                          badgeColor: colorArray[Math.floor(Math.random() * colorArray.length)],
                          badgeIcon: iconArray[Math.floor(Math.random() * iconArray.length)],
                          title: `At Client Location`,
                          titleColor: colorArray[Math.floor(Math.random() * colorArray.length)],
                          userTimeStamp: moment().subtract(4, "hours").format("YYYY/MM/DD hh:mm A"),
                          timespent: "5 Hrs",
                          body: `
                              Team member entered client location ${simulationAddress} at ${moment().subtract(4, "hours").format("YYYY/MM/DD hh:mm A")} and stayed there for 5 hrs
                          `
                        },
                        {
                          badgeColor: colorArray[Math.floor(Math.random() * colorArray.length)],
                          badgeIcon: iconArray[Math.floor(Math.random() * iconArray.length)],
                          title: `Driving`,
                          titleColor: colorArray[Math.floor(Math.random() * colorArray.length)],
                          userTimeStamp: moment().subtract(5, "hours").format("YYYY/MM/DD hh:mm A"),
                          mapimage: `https://maps.googleapis.com/maps/api/staticmap?center=${simulationAddressLatLng.lat},${simulationAddressLatLng.lng}&zoom=11&size=550x300&maptype=roadmap&markers=color:red%7Clabel:A%7C${simulationAddressLatLng.lat},${simulationAddressLatLng.lng}&path=enc:${firstResult.routes[0].overview_polyline}&key=AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk`,
                          timespent: "1 Hr",
                          body: `
                              Team member drove to client location ${autoCompleteAddress.value.description} via route path above.
                          `
                        },
                        {
                          badgeColor: colorArray[Math.floor(Math.random() * colorArray.length)],
                          badgeIcon: iconArray[Math.floor(Math.random() * iconArray.length)],
                          title: "Place Assigned",
                          titleColor: colorArray[Math.floor(Math.random() * colorArray.length)],
                          userTimeStamp: moment().subtract(5, "hours").format("YYYY/MM/DD hh:mm A"),
                          address: simulationAddress,
                          mapimage: `https://maps.googleapis.com/maps/api/staticmap?maptype=satellite&center=${simulationAddressLatLng.lat},${simulationAddressLatLng.lng}&zoom=18&size=550x300&maptype=roadmap&markers=color:red%7Clabel:G%7C${simulationAddressLatLng.lat},${simulationAddressLatLng.lng}&key=AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk`,
                          addressmaintext: simulationAddress,
                          addresslocation: simulationAddress,
                          body: `
                              The address ${simulationAddress} is assigned to team member ${simulationTeammemberName}. 
                          `
                        }
                      ])

                      const finaArray = firstResult.routes[0].overview_path.concat(newResult.routes[0].overview_path).concat(newResult.routes[0].overview_path.reverse())
                      // for (let index = 0; index < finaArray.length; index++) {
                      //   const element = finaArray[index];
                      //   Firebase.database()
                      //   .ref(`/realtimetrackingdata//${moment().format("DD-MM-YYYY")}`)
                      //   .push({
                      //     status: "Active",
                      //     latlng: `${element.lat()},${element.lng()}`,
                      //     timestamp: moment().add(index, "minutes").format("hh:mm A").toString(),
                      //     battery: 80,
                      //     date: moment().format("DD-MM-YYYY"),
                      //     activity: "driving"
                      //   })
                      // }
                      
                      
                      autoRefresh(map, finaArray);
                    }
                  }
                );
              }
            }
          );  
      }, []);
  
      return (
        <>
          <div
            style={{
              height: `500px`,
              borderRadius: "6px",
              overflow: "hidden",
            }}
            ref={mapRef}
          />
        </>
      );
  }

  const SatelliteMap = (props) => {
    if(!props || !props.data){
      return
    }
    const mapRef = React.useRef(null);
    const latlng = props.data[2].split(",")
    React.useEffect(() => {
      if(!timeLineData || timeLineData.length == 0 ){
        Firebase.database()
          .ref(`tracking/placeslist/${user.email.replace(/\./g, ",")}/${props.data[1]}/timeline/${moment().format("DD-MM-YYYY").toString()}`)
          .once('value', snapshot => {
            const data = snapshot.val()
            if (data) {
              let result = []
              const keys = Object.keys(data);
              for (let index = keys.length - 1; index > -1; index--) {
                const element = data[keys[index]];
                result.push(
                  {
                    badgeColor: element.badgeColor,
                    badgeIcon: TimelineIcon,
                    title: element.title,
                    titleColor: element.titleColor,
                    timeStamp: element.timeStamp,
                    img: element.img,
                    body:element.body,
                  }
                )
              }
              setTimeLineData(result);
            }
          });
      }
    }, [])

    React.useEffect(() => {
      let google = window.google;
      let map = mapRef.current;
      let lat = latlng[0]
      let lng = latlng[1];
      const myLatlng = new google.maps.LatLng(lat, lng);
      const mapOptions = {
        zoom: 18,
        center: myLatlng,
        mapTypeControl: false,
        mapTypeId: "satellite",
        styles: mapStyles,
      }
  
      map = new google.maps.Map(map, mapOptions);
  
      const marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        animation: google.maps.Animation.DROP,
        title: props.data[0],
      });
  
      const contentString =
        `<div class="info-window-content"><h2>${props.data[0]}</h2></div>`
  
      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      });
  
      google.maps.event.addListener(marker, "click", function () {
        infowindow.open(map, marker);
      });
    }, []);

    return (
      <>
        <div
          style={{
            height: `500px`,
            borderRadius: "6px",
            overflow: "hidden",
          }}
          ref={mapRef}
        />
      </>
    );
  };
  const placesList = () => {
    return (<>
      <Dialog open={deliveryOpen} onClose={deliveryHandleClose} aria-labelledby="form-dialog-title" fullWidth>
			<DialogTitle id="form-dialog-title">Add A Place</DialogTitle>
			<DialogContent>
			  <h4>{address}</h4>
			  {addressLatLng && (<img src={`https://maps.googleapis.com/maps/api/staticmap?maptype=satellite&center=${addressLatLng.lat},${addressLatLng.lng}&zoom=18&size=550x300&maptype=roadmap&markers=color:red%7Clabel:G%7C${addressLatLng.lat},${addressLatLng.lng}&key=AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk`} title="" />)}
	
			  <DialogContentText>
				Enter Address
			  </DialogContentText>
			  <GooglePlacesAutocomplete
				selectProps={{
				onChange: (address) => getCoordinates(address),
			  }}
				placeholder="Search Address "
				apiKey = "AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk"
        />
        &nbsp;
			  <h4>Customer Name</h4>
			  <CustomInput
				formControlProps={{
				  fullWidth: true,
				  className: classes.customFormControlClasses,
				}}
				inputProps={{
				  startAdornment: (
					<InputAdornment
					  position="start"
					  className={classes.inputAdornment}
					>
					  <PersonIcon className={classes.inputAdornmentIcon} />
					</InputAdornment>
				  ),
				  type: "text",
				  placeholder: "Name of the customer...",
				  onChange: customerNameChange
				}}
			  />
			  &nbsp;
			  <h4>Customer Phone</h4>
			  <CustomInput
				formControlProps={{
				  fullWidth: true,
				  className: classes.customFormControlClasses,
				}}
				inputProps={{
				  startAdornment: (
					<InputAdornment
					  position="start"
					  className={classes.inputAdornment}
					>
					  <Phone className={classes.inputAdornmentIcon} />
					</InputAdornment>
				  ),
				  type: "number",
				  placeholder: "10 Digit Phone...",
				  onChange: customerPhoneChange
				}}
			  />
			  {/* <p style={{color: "grey"}}>Please make sure delivery person installed onroad mobile app</p> */}
        <br />
        &nbsp;
			  <h4>Schedule Date & Time</h4>
          <Datetime inputProps={{ placeholder: "Pick Date and Time" }} onClose={dateTimeChange} initialValue={new moment()} />
        <br />
				<h4> Team Member : <b>{selectedDeliveryPerson}</b></h4>
				&nbsp;
				<CustomDropdown
					dropup
					onClick={(val) => handleClick(val)}
					buttonText="Pick A Team Member"
					hoverColor="info"
					dropPlacement="top"
					buttonProps={{
					  round: true,
					  fullWidth: true,
					  style: { marginBottom: "0" },
					  color: "info",
					  width: "80%"
					}}
					style={{width: "80%"}}
					dropdownList={registeredTeammembers}
				  />
				  <p>{registeredTeammembers.length == 0 ? "Please register atleast one team member" : null}</p>
			</DialogContent>
			<DialogActions>
			  <Button onClick={deliveryHandleClose} color="primary">
				Cancel
			  </Button>
			  <Button onClick={savePlace} color="primary" disabled={!selectedDeliveryPerson || registeredTeammembers.length == 0 || !address || !addressLatLng || !customerPhone || customerPhone.replace(currentUserData.countrycode,'').length != 10}>
				Add
			  </Button>
			</DialogActions>
		  </Dialog>

      <Dialog open={adderssTrackOpen} onClose={adderessTrackClose} aria-labelledby="form-dialog-title" fullScreen>
			<DialogTitle id="form-dialog-title" >
        
      <Grid container direction="row" justify="space-between" alignItems="center">
        {trackingDetails[0]}
        <IconButton aria-label="close" onClick={adderessTrackClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      </DialogTitle>
      
			<DialogContent>
			  {/* <h4>{address}</h4> */}
			  {/* <img src={`https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/teamtrack.jpg?alt=media&token=7e2f91c8-12d4-4178-be1a-2c29a76cbb78`} title="" style={{width: "100%"}}/> */}
        <GridContainer >
        <GridItem xs={12} sm={12} md={12}>
          <SatelliteMap data={trackingDetails}/>
        </GridItem>
        </GridContainer>
        <GridContainer justify = "space-between"
        style = {{
            justifyContent: "center",
            textAlign: "center"
          }}>  
          <GridItem xs={12} sm={12} md={12} lg={9}>
            {timeLineData && (<Timeline simple stories={timeLineData} />)}
          </GridItem>
        </GridContainer>

			</DialogContent>
			{/* <DialogActions>
			  <Button onClick={adderessTrackClose} color="primary">
				close
			  </Button>
			</DialogActions> */}
		  </Dialog>
      <Card>
      <CardHeader color="rose" icon>
        
        <CardIcon color="rose" style={{float: "right", padding: "0px"}}>
        <Button  onClick={deliveryHandleOpen}
          style = {{
            background: "transparent",
            boxShadow: "none",
            padding:"10px"
          }}>
          Add A Place
        </Button>
        </CardIcon>
        <br />
        <div style={{color: "blue", float: "right", fontSize: "10px", marginRight: "-81px", textTransform: "capitalize"}}>
        <Switch
          // checked={false}
            onChange={(event) => {
              if (event.target.checked) {
                setPlaceFilter("Today")
                const t = placesData.filter((i) => {
                  return i[4].split(", ")[0] == moment().format("DD-MM-YYYY").toString()
                })
                setPlacesData(t)
              } else {
                setPlaceFilter("All")
                setPlacesData(basePlacesData)
              }
            }}
          // value={isActive}
          classes={{
            switchBase: classes.switchBase,
            checked: classes.switchChecked,
            thumb: classes.switchIcon,
            track: classes.switchBar,
          }}
        /> {placeFilter}
        </div>
      </CardHeader>
      <CardBody>
        {placesData && placesData.length> 0 ? (
        <Table
          tableHeaderColor="primary"
          tableHead={["Address", "Client Name", "City", "Team Member", "Time Added", ""]}
          tableData={placesData}
          coloredColls={[3]}
          colorsColls={["primary"]}
          openAddress={openAddressCallback}
          deleteHandleLastcaseCallback={deleteHandleLastcaseCallback}
        />): noPlaces()}
        
      </CardBody>
      </Card>
    </>)

  }
  const Notifications = () => {
    const [notificationList, setNotificationList] = React.useState([]);

    React.useEffect(() => {
        Firebase.database()
          .ref(`notifications/${user.email.replace(/\./g, ",")}/${moment().format("DD-MM-YYYY").toString()}/general`)
          .limitToLast(100)
          .once('value', snapshot => {
            const data = snapshot.val()
            if (data) {
              let result = []
              const keys = Object.keys(data);
              for (let index = keys.length - 1; index > -1; index--) {
                
                const element = data[keys[index]];
                result.push(
                  {
                    message: element.message,
                    customer: element.customer,
                    teammember: element.teammember,
                    color: element.color
                  }
                )
              }
              setNotificationList(result);
            }
          });
    }, [])

    const list = () => {
      return (
        <>
          {notificationList.map(item => (
             <SnackbarContent
             message={item.message}
             color={item.color || 'gray'}
           />)
          )}
        </>
      )
    }
    return (
            <div>
              {notificationList.length == 0 ? 
                (<><h3 style={{textAlign: "center", color: "gray"}}>No Notifications Yet</h3></>) :
                (<>{list()}</>)
              }
            </div>
          )
  }
  const getSharableLink = (phone) => {
    if(!phone){
      return ''
    }
    return `https://track.ac/${base64.fromNumber(parseInt(phone))}`
  }

  const trackDataDetails = (trackData) => {
    return (
      <>
      <Badge color="success">Battery: {trackData.battery}</Badge> &nbsp;&nbsp;
      <Badge color="danger">Location: {trackData.location}</Badge> &nbsp;&nbsp;
      <Badge color="primary">{trackData.activity}</Badge>
      </>
    )
  }
  var enumerateDaysBetweenDates = (startDate, endDate) => {
    var now = startDate, dates = [];
    
    while (now.isSameOrBefore(endDate)) {
          dates.push(now.format('DD-MM-YYYY'));
          now.add(1, 'days');
      }
    return dates;
  };

  const popoverfields = () => {
    return (
      <> <GridContainer style={{marginTop: "40px"}}>
      <GridItem xs={3} sm={3} md={3}>
        <FormLabel
          className={
            classes.labelHorizontal +
            " " +
            classes.labelHorizontalRadioCheckbox
          }
        >
          Fields for the report
        </FormLabel>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <div className={classes.inlineChecks}>
          
        {fieldsForReport.map((field, index) => {
          return (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={true}
                    tabIndex={-1}
                    onClick={() => handleToggle(index)}
                    checkedIcon={
                      <Check className={classes.checkedIcon} />
                    }
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label={field}
              />
            </>
          )
          })}
        </div>
      </GridItem>
    </GridContainer>
      </>
    )
  }
  
  const GnattChart = (data) => {
    React.useEffect(() => {
      if (
        !document.getElementById(data.client).classList.contains("noUi-target")
      ) {
        let connectArray = gnattChartData[data.client].map((e, i) => i % 2 == 0 ? false : true)
        connectArray.push(false)
        var pipsSlider = document.getElementById(data.client);
  
        pipsSlider.setAttribute('disabled', true);
  
        const a = Slider.create(document.getElementById(data.client), {
          // start: [20, 60],
          // connect: [false, true, false],
          // step: 1,
          // range: { min: 0, max: 100 },
  
          range: {
            min: 5,
            max: 20
          },
          // tooltips: [true],
          tooltips: gnattChartData[data.client].map(i => true),
        
          start:  gnattChartData[data.client],
          connect: connectArray,
      // start: [1100,1850],
          pips: {
            mode: 'count',
            values: 16
          }
        });
        // var stepSliderValueElement = document.getElementById('slider-step-value');
  
        // a.on('update', function (values, handle) {
        //     stepSliderValueElement.innerHTML = values[handle];
        // });
        
        var slider = document.getElementById(data.client);
        // mergeTooltips(slider, 15, ' - ');

        var connect = slider.querySelectorAll('.noUi-connect');

        for (var i = 0; i < connect.length; i++) {
            connect[i].style.background = "red"
        }
        
      }
      return function cleanup() {};
    });
    return (
      <>
        <small style={{alignItems: "center"}}>{data.client}</small>
        <div className="slider-info" id={data.client} style={{ marginBottom: "100px" }} />
        
      </>
     
    )
  }

  const teamMemberList = () => {
 
    return (<>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
			<DialogTitle id="form-dialog-title">Register A Team Member</DialogTitle>
			<DialogContent>
			<DialogContentText>
				Enter Name
			</DialogContentText>
			<CustomInput
				formControlProps={{
				fullWidth: true,
				className: classes.customFormControlClasses,
				}}
				inputProps={{
				startAdornment: (
					<InputAdornment
					position="start"
					className={classes.inputAdornment}
					>
					<PersonIcon className={classes.inputAdornmentIcon} />
					</InputAdornment>
				),
				placeholder: "Name...",
				onChange: deliveryGuyNameChange
				}}
			/>
			<br/>
			<br/>
			<DialogContentText>
				Enter Phone
			</DialogContentText>
			<CustomInput
				formControlProps={{
				fullWidth: true,
				className: classes.customFormControlClasses,
				}}
				inputProps={{
				startAdornment: (
					<InputAdornment
					position="start"
					className={classes.inputAdornment}
					>
					<PhoneIcon className={classes.inputAdornmentIcon} />
					</InputAdornment>
				),
				placeholder: "10 Digit Phone Number",
				onChange: deliveryGuyPhoneChange
				}}
			/>
			<p style={{color: "grey", padding: "10px"}}>Please make sure person installed the mobile app</p>
			</DialogContent>
			<DialogActions>
			<Button onClick={handleClose} color="primary">
				Cancel
			</Button>
			<Button onClick={saveTeammember} color="primary" disabled={!deliveryGuyName || !deliveryGuyPhone || deliveryGuyPhone.replace(currentUserData.countrycode,'').length != 10}>
				Save
			</Button>
			</DialogActions>
		  </Dialog>
      <Dialog open={teamMemberTrackOpen} onClose={teamMemberTrackClose} aria-labelledby="form-dialog-title" fullScreen>
			<DialogTitle id="form-dialog-title" style={{paddingBottom: "0px", fontSize: "15px", backgroundColor: "#eeeeee"}}>
        
        <Grid container direction="row" justify="space-between">
          <div style={{color: "gray", fontSize: "10px"}}>
          {teamMemberDetails[0]?.toUpperCase()} &nbsp;&nbsp; {teamMemberDetails[1]} &nbsp;&nbsp; 
            {/* <a href={getSharableLink(teamMemberDetails[1])} target="_blank" style={{fontSize: "15px"}}>
              {getSharableLink(teamMemberDetails[1])} <LaunchIcon style={{paddingLeft: "5px", paddingTop: "5px", fontSize: "1rem"}} />
              </a> */}
            {onePersonCSV && onePersonCSV.length > 0 ? (<CSVLink filename='Onroad-tracking-report' data={onePersonCSV || []} style={{fontSize: "10px", paddingLeft: "20px"}}>Download CSV</CSVLink>): <span style={{fontSize: "10px", paddingLeft: "20px"}}>Download CSV Not Available Yet</span>}
            </div>

          
          <IconButton aria-label="close" onClick={teamMemberTrackClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      
			<DialogContent style={{backgroundColor: "#eeeeee"}}>
			  {/* <h4>{address}</h4> */}
			  {/* <img src={`https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/teamtrack.jpg?alt=media&token=7e2f91c8-12d4-4178-be1a-2c29a76cbb78`} title="" style={{width: "100%"}}/> */}
        
        <GridContainer >
            <GridItem xs={12} sm={7} md={7} lg={7}>
              {React.useMemo(
                () => (
                  <TeamMemberLivetrackMap data={teamMemberDetails}/>
                ),
                [teamMemberDetails]
              )}
              </GridItem>
              {teamMemberDetails &&
              (<GridItem xs={12} sm={5} md={5} lg={5}>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card profile>
                      <CardBody profile>
                    <h6 className={classes.cardCategory}>Track Summary</h6>
                    <Button onClick={() => {
                      buildTrackSummary(teamMemberDetails[1])
                      setTrackSummary(null);
                      }}
                        color="transparent"
                        style={{color: "#9c27b0", fontSize: "10px",  textTransform: "capitalize", padding: "0px"}}
                        className={classes.buttonLink}
                          >
                          Run The Job Again
                      </Button>
                        <List className={classes.list}>
                        {trackSummary ? fieldsForSummary?.map(item => (<ListItem>
                          <b>{item}</b> &nbsp; &nbsp; {trackSummary[item.replace(/\W+/g, '').toLowerCase()]}          
                          </ListItem>)): (<><CircularProgress /></>)}
                        </List>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card profile>                 
                      <CardBody profile>
                        <h6 className={classes.cardCategory}>{scheduledClientsForToday?.length > 1 ? "CLIENTS" : "CLIENT"} </h6>
                        <List className={classes.list}>
                      {scheduledClientsForToday?.map(item => (
                        <>
                          <ListItem>
                            <b>{item.clientname}</b> &nbsp; &nbsp; {item.timeadded} &nbsp; {item.address}
                          </ListItem>
                        </>
                          
                      ))}
                            
                        </List>
                        {/* <h4 className={classes.cardTitle} style={{textTransform: "capitalize"}}>Name: {simulationClientName}</h4>
                        <p className={classes.description}>
                          Requested service at the location {teamMemberDetails[1]}
                        </p> */}
                      </CardBody>
                    </Card>
                  </GridItem>
                  
              
                </GridItem>
                )}
          </GridContainer>  
          
        {/* <GridContainer >
        <GridItem xs={12} sm={12} md={12}>
          <TeamMemberLivetrackMap data={teamMemberDetails}/>
        </GridItem>
      </GridContainer> */}
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <NavPills
            color="warning"
            alignCenter
            tabs={[
              {
                tabButton: "Summary",
                tabIcon: Info,
                tabContent: (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Card>
                        <CardHeader>
                          <h4>Hourly Track Summary</h4>
                        </CardHeader>
                        <CardBody>
                          {
                           gnattChartData ?  Object.keys(gnattChartData).map((client) => {
                              return ( <GnattChart client={client} />)
                            }) : null
                          }
                        </CardBody>
                      </Card>
            
                    </GridItem>
                    <br />
                    <GridItem xs={12} sm={12} md={6}>
                      <Card>
                        <CardHeader>
                          <h4>
                            <small>Avg Daily Jobs</small>
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <ChartistGraph
                            data={avgDailyJobs}
                            type="Line"
                            options={colouredLineChart.options}
                            listener={colouredLineChart.animation}
                          />
                        </CardBody>
                      </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                    <Card>
                      <CardHeader>
                        <h4><small> Today's Time Distribution</small></h4>
                      </CardHeader>
                      <CardBody>
                        <ChartistGraph
                          data={pieChartData}
                          type="Pie"
                          options={pieChart.options}
                        />
                      </CardBody>
                      <CardFooter stats className={classes.cardFooter}>
                        {pieChartData?.labels?.length == 1 ? (<>
                          <i className={"fas fa-circle " + classes.info} /> No Tracking Data Yet
                        </>) : (
                          <>
                        <i className={"fas fa-circle " + classes.info} /> Driving{` `}
                        <i className={"fas fa-circle " + classes.warning} /> Hours Outside
                        {` `}
                        <i className={"fas fa-circle " + classes.danger} /> Hours At Client
                        {` `}</>)}
                        
                      </CardFooter>
                    </Card>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabButton: "Timeline",
                tabIcon: TimelineIcon,
                tabContent: (
                  <GridContainer justify = "space-between"
                    style = {{
                        justifyContent: "center",
                        textAlign: "center"
                      }}>  
                      <GridItem xs={12} sm={12} md={12} lg={9}>
                        {userTimelineData?.length >0 ? (<Timeline simple stories={userTimelineData} />) : (<><h3 style={{textAlign: "center", color: "gray"}}>No Timeline data yet</h3></>)}
                      </GridItem>
                    </GridContainer>

                ),
              },
              {
                tabButton: "Activity",
                tabIcon: StorageIcon,
                tabContent: (
                  <Card>
                    <CardHeader>
                      <h4 className={classes.cardTitle}>
                        Real time Activity
                      </h4>
                    </CardHeader>
                    <CardBody>
                    {userRealtimeActivtyData.length > 0 ? (<Table
                        tableHead={[
                          "Status",
                          "Timestamp",
                          "Trackdata",
                        ]}
                        tableData={userRealtimeActivtyData}
                        customCellClasses={[classes.center, classes.right, classes.right]}
                        customClassesForCells={[0, 4, 5]}
                        customHeadCellClasses={[
                          classes.center,
                          classes.right,
                          classes.right,
                        ]}
                        customHeadClassesForCells={[0, 4, 5]}
                      />): (<><h3 style={{textAlign: "center", color: "gray"}}>No Realtime tracking data yet</h3></>)}
                    </CardBody>
                  </Card>
                ),
              },
              // {
              //   tabButton: "Chat",
              //   tabIcon: ChatIcon,
              //   tabContent: (
              //     <Card>
              //       <CardHeader>
              //         <h4 className={classes.cardTitle}>Help center</h4>
              //         <p className={classes.cardCategory}>
              //           More information here
              //         </p>
              //       </CardHeader>
              //       <CardBody>
              //         From the seamless transition of glass and metal to the
              //         streamlined profile, every detail was carefully considered
              //         to enhance your experience. So while its display is
              //         larger, the phone feels just right.
              //         <br />
              //         <br />
              //         Another Text. The first thing you notice when you hold the
              //         phone is how great it feels in your hand. The cover glass
              //         curves down around the sides to meet the anodized aluminum
              //         enclosure in a remarkable, simplified design.
              //       </CardBody>
              //     </Card>
              //   ),
              // },
            ]}
          />
        </GridItem>
      </GridContainer>

			</DialogContent>
			{/* <DialogActions>
			  <Button onClick={adderessTrackClose} color="primary">
				close
			  </Button>
			</DialogActions> */}
		  </Dialog>
      <Card>
          {/* <Datetime
                  timeFormat={false}
                  inputProps={{ placeholder: "Date Picker Here" }}
        /> */}
        
        
        <CardHeader  icon>
          
          <CardIcon color="rose" style={{ float: "right", padding: "0px" }}>
          <Button  onClick={handleOpen}
            style = {{
              background: "transparent",
              boxShadow: "none",
              padding:"10px"
            }}>
            Add A Team Member
            </Button>
          </CardIcon>
          <br />
          
          <Button onClick={handleDateOpen}
             color="transparent"
             style={{color: "blue", float: "right", fontSize: "10px", marginRight: "-150px", textTransform: "capitalize"}}
             className={classes.buttonLink}
                >
                  Generate Report
          </Button>
         
        </CardHeader>
        <CardBody>
        <Popover
          id={id}
          open={openDatePopover}
          anchorEl={anchorEl}
          onClose={handleDateClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          >
             
            <DateRangePicker
              open={true}
              toggle={toggle}
              onChange={(range) => {
                setReportDates(enumerateDaysBetweenDates(moment(range.startDate), moment(range.endDate)))
                // generateAndSetCSVData()
              }}
                
            />
            <br />
            <bold style={{fontSize: "20px", padding: "30px"}}>Fields in the report</bold>
            <div style={{maxWidth: "700px", padding: "30px"}}>
            {fieldsForReport.map((field, index) => {
              return (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={true}
                        tabIndex={-1}
                        onClick={() => handleToggle(index)}
                        checkedIcon={
                          <Check className={classes.checkedIcon} />
                        }
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label={field}
                  />
                </>
              
              )
            })
            }
            </div>
          
           {/* {reportCSVData && reportCSVData.length > 0 ? ( <Button
              size="sm"
              color="rose"
              onClick={handleDateClose}
              style={{ 
                textAlign: "center",
                left: "40%"
              }}>
              {reportCSVData && reportCSVData.length > 0 ? (<CSVLink filename='Onroad-tracking-report' data={reportCSVData} style={{ fontSize: "10px", color: "white" }}>Download CSV</CSVLink>) : (<CustomLinearProgress />)}
            </Button>) : (<CustomLinearProgress />)} */}

            {reportGenerationWIP ? (<CustomLinearProgress />) : ""}
           
            <Button
              size="sm"
              color="rose"
              onClick={() => {reportGenerationWIP ? handleDateClose() :  handleGenerateReport() }}
              style={{ 
                textAlign: "center",
                left: "45%"
              }}>
              {showButtonText()}
            </Button>

            <Button
              size="sm"
              color="rose"
              onClick={handleDateClose}
              style={{ 
                textAlign: "center",
                left: "50%"
                }}>
              Close
            </Button>
            {reportCSVData && reportCSVData.length > 0 && <a style={{ 
                textAlign: "center",
                
              }}>Tracking-report.csv</a> }
            
        </Popover>
          
          {data && data.length> 0 ? (<Table
          tableHeaderColor="primary"
          tableHead={["Name", "Phone", "Today's Assignments", "Status"]}
          tableData={data}
          coloredColls={[3]}
          colorsColls={["primary"]}
          openTeamMember={openTeamMemberCallback}
          deleteHandleLastcaseCallback={deleteHandleLastcaseCallback}
          />): noTeamMember()}
          
        </CardBody>
        </Card>
    </>)
  }

  const teamGlobalMap = () => {
    return (
      <GridContainer >
        <GridItem xs={12} sm={12} md={12}>
        <div
          style={{
            height: `500px`,
            borderRadius: "6px",
            overflow: "hidden",
          }}
          ref={mapRef}
        />
        
        {/* <div style={{textAlign: "right"}}>
                <Button size="sm" onClick={() => {
                  if(places){setPlaces(false)}
                  else{setPlaces(true);setPeople(false)}
                  setPlaces(!places)
                  }} color={places ? 'success' : 'secondary'}>Places </Button>
                <Button size="sm" onClick={() => {
                   if(people){setPeople(false)}
                   else{setPeople(true);setPlaces(false)}
                }} color={people ? 'success' : 'secondary'}>People </Button>
              </div> */}
        </GridItem>
      </GridContainer>
    )
  }

  const placesAndTeamMembers = () => {
    return(
        <>
        <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          {teamMemberList()}
        </GridItem>
        <GridItem xs={12} sm={12} md={6}> 
          {placesList()}
        </GridItem>
        </GridContainer>
        </>
      )
  }

  const generateSimulationCSVdata = () => {
    return [
      ["Date", "Client name", "Client location", "Team member", "Team member start location", "Time in", "Time out", "Hours spent", "Miles driven", "Hours spent outside"],
      [ moment().format("MM-DD-YYYY"),
        simulationClientName,
        simulationAddress,
        simulationTeammemberName,
        simulationPersonAddress,
        moment().subtract(5, "hours").format("MM-DD-YYYY, hh:mm:ss A"),
        moment().add(5, "hours").format("MM-DD-YYYY, hh:mm:ss A"),
        10,
        14,
        3
      ]
    ]
  }

  const generateAndSetCSVData = async (reportDates) => {
      // call function(dates)
      // const members = data.filter(a => a[2] > 0).map(a => a[1])
      // for (let index = 0; index < members.length; index++) {
      //   const memberPhone = members[index];
      //   for (let index = 0; index < reportDates.length; index++) {
      //     const reportDate = reportDates[index];
      //     // const tracksummary = Firebase.database().ref(`/tracking/teams/teammembers/${user.email.replace(/\./g, ",")}/${memberPhone}/tracksummary/${reportDate}`).once('value')
      //     setTimeout(() => {
      //       setReportCSVData([
      //         ["Client name", "Client location", "Team member", "Team member start location", "Time in", "Time out", "Hours spent", "Miles driven", "Hours spent outside"],
      //         [simulationClientName,
      //           simulationAddress,
      //           simulationTeammemberName,
      //           simulationPersonAddress,
      //           moment().subtract(5, "hours").format("MM-DD-YYYY, hh:mm:ss A"),
      //           moment().add(5, "hours").format("MM-DD-YYYY, hh:mm:ss A"),
      //           10,
      //           14,
      //           3
      //         ]
      //       ])
      //     }, 20);
      //     console.log("=====", memberPhone, reportDate)
      //   }
      // }
  }
  const generateReportCSVdata =  () => {
    if (!anchorEl || !reportDates || !data) {
      return [];
    }
    // 
    // const members = data.filter(a => a[2] > 0).map(a => a[1])
    // for (let index = 0; index < members.length; index++) {
    //   const memberPhone = members[index];
    //   for (let index = 0; index < reportDates.length; index++) {
    //     const reportDate = reportDates[index];
    //     // const tracksummary = Firebase.database().ref(`/tracking/teams/teammembers/${user.email.replace(/\./g, ",")}/${memberPhone}/tracksummary/${reportDate}`).once('value')

    //     console.log("=====", memberPhone, reportDate)
    //   }
    // }
    return [
      ["Client name", "Client location", "Team member", "Team member start location", "Time in", "Time out", "Hours spent", "Miles driven", "Hours spent outside"],
      [simulationClientName,
        simulationAddress,
        simulationTeammemberName,
        simulationPersonAddress,
        moment().subtract(5, "hours").format("MM-DD-YYYY, hh:mm:ss A"),
        moment().add(5, "hours").format("MM-DD-YYYY, hh:mm:ss A"),
        10,
        14,
        3
      ]
    ]
  }
  const teamsScreen = () => {
    return (
      <>
        <div style={{alignItems: "center", justifyContent: "center", padding: "0px", marginTop: "-50px"}}>
          <Heading
            textAlign="center"
            title="Teams"
            className={classes.buttonLink}
            style={{padding: "0px", margin: "0px", margin: "0px!important"}}
            // category={currentUserData?.apikey}
            />
          <Button
              color="transparent"
              style={{color: "red", top:"-10px", fontSize: "8px", padding: "0px", margin: "0px", width: "100%"}}
              className={classes.buttonLink}
              onClick={() => {setTeamsSimulationModal(true)}}
          > Run a Simulation </Button>
        </div>
      

      <Dialog open={teamsSimulationModal} onClose={teamsSimulationModalClose} aria-labelledby="form-dialog-title" fullScreen>
        <DialogTitle id="form-dialog-title" style={{paddingBottom: "0px", fontSize: "15px", backgroundColor: "#eeeeee"}}>
          
          <Grid container direction="row" justify="space-between">
            <div style={{color: "gray", fontSize: "10px"}}>
                {simulationAddress ? (<>{`${simulationAddress} | total people tracking in this simulation : 1`}
                  <IconButton aria-label="close" onClick={() => {
                     setSimulationAddress(null);
                     setSimulationAddressLatLng(null);
                     setSimulationPersonAddress(null)
                     setSimulationPersonAddressLatLng(null);
                } }>
                  <EditIcon style={{ fontSize: "10px", color: "blue" }} />
                  </IconButton>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <CSVLink filename='Onroad-tracking-report' data={generateSimulationCSVdata()} style={{fontSize: "10px"}}>Download CSV</CSVLink>
                </>) : "Please enter client location to run a simulation tracking"}
                
            </div>
            

              {/* <CSVDownload data={csvData} target="_blank" />; */}
            <IconButton aria-label="close" onClick={teamsSimulationModalClose }>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        
          <DialogContent style={{ backgroundColor: "#eeeeee" }}>
          {!simulationPersonAddressLatLng && (<GooglePlacesAutocomplete
            selectProps={{
                onChange: (address) => {
                  getSimulationAddress(address)
                },
            }}
            placeholder="Search Address "
            apiKey = "AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk"
            />)}

          <GridContainer >
            <GridItem xs={12} sm={7} md={7} lg={7}>
              {React.useMemo(
                () => (
                  <SimulationLivetrackMap />
                ),
                [simulationPersonAddressLatLng]
              )}
              </GridItem>
              <br />
              {/* <GridItem xs={12} sm={7} md={7}>
                <div className="slider-info" id="sliderDouble" />
              </GridItem> */}
              {simulationPersonAddressLatLng &&
                (<GridItem xs={12} sm={5} md={5} lg={5}>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card profile>                 
                      <CardBody profile>
                        <h6 className={classes.cardCategory}>CLIENT</h6>
                        <h4 className={classes.cardTitle} style={{textTransform: "capitalize"}}>Name: {simulationClientName}</h4>
                        <p className={classes.description}>
                          Requested service at the location {simulationAddress}
                        </p>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card profile>
                      <CardBody profile>
                        <h6 className={classes.cardCategory}>TEAM MEMBER</h6>
                        <h4 className={classes.cardTitle} style={{textTransform: "capitalize"}}>Name: {simulationTeammemberName}</h4>
                        <div style={{
                          display: "flex",  
                          position: "relative", paddingLeft: "40px"}}>
                          <div className={classes.note} style={{width: "50%"}}>
                            Start location:
                          </div>
                          <div style={{position: "relative", width: "100%"}}>
                            <span style={{paddingLeft: "20px", textTransform: "capitalize"}}>
                              <small style={{color: "gray"}}>{simulationPersonAddress}</small>
                            </span>
                          </div>
                        </div>
                        <div style={{
                          display: "flex",  
                          position: "relative", paddingLeft: "40px"}}>
                          <div className={classes.note} style={{width: "50%"}}>
                          Tracking start time:
                          </div>
                          <div style={{position: "relative", width: "100%"}}>
                            <span style={{paddingLeft: "20px", textTransform: "capitalize"}}>
                            <small style={{ color: "gray" }}>{moment().subtract(5, "hours").format("MM-DD-YYYY, hh:mm:ss A")}</small>
                            </span>
                          </div>
                      </div>   
                      <div style={{
                          display: "flex",  
                          position: "relative", paddingLeft: "40px"}}>
                          <div className={classes.note} style={{width: "50%"}}>
                          Tracking End time:
                          </div>
                          <div style={{position: "relative", width: "100%"}}>
                            <span style={{paddingLeft: "20px", textTransform: "capitalize"}}>
                              <small style={{color: "gray"}}>{moment().add(5, "hours").format("MM-DD-YYYY, hh:mm:ss A")}</small>
                            </span>
                          </div>
                      </div>   
                      
                      <div style={{
                          display: "flex",  
                          position: "relative", paddingLeft: "40px"}}>
                          <div className={classes.note} style={{width: "50%"}}>
                          Hours spent at client
                          </div>
                          <div style={{position: "relative", width: "100%"}}>
                            <span style={{paddingLeft: "20px", textTransform: "capitalize"}}>
                              <small style={{color: "gray"}}>10</small>
                            </span>
                          </div>
                      </div>   
                      <div style={{
                          display: "flex",  
                          position: "relative", paddingLeft: "40px"}}>
                          <div className={classes.note} style={{width: "50%"}}>
                          Hours spent outside
                          </div>
                          <div style={{position: "relative", width: "100%"}}>
                            <span style={{paddingLeft: "20px", textTransform: "capitalize"}}>
                              <small style={{color: "gray"}}>3</small>
                            </span>
                          </div>
                      </div>  
                      <div style={{
                          display: "flex",  
                          position: "relative", paddingLeft: "40px"}}>
                          <div className={classes.note} style={{width: "50%"}}>
                          Miles driven
                          </div>
                          <div style={{position: "relative", width: "100%"}}>
                            <span style={{paddingLeft: "20px", textTransform: "capitalize"}}>
                              <small style={{color: "gray"}}>20</small>
                            </span>
                          </div>
                        </div>
                  
                      </CardBody>
                    </Card>
                </GridItem>
              
                </GridItem>
                )}
          </GridContainer>
         {simulationPersonAddressLatLng && ( <GridContainer justify="center" style={{paddingTop: "35px"}}>
          <GridItem xs={12} sm={12} md={8}>
              <NavPills
                color="warning"
                alignCenter
                tabs={[
                  {
                    tabButton: "Summary",
                    tabIcon: Info,
                    tabContent: (
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <Card>
                            <CardHeader>
                              <h4>
                                <small>Avg Daily Visits</small>
                              </h4>
                            </CardHeader>
                            <CardBody>
                            <ChartistGraph
                              data={{
                                labels: getCurrentWeekDays().reverse(),
                                series: [[
                                  1,2,3,4,1,2,3
                                ]]
                              }}
                              type="Line"
                              options={colouredLineChart.options}
                              listener={colouredLineChart.animation}
                            />
                        </CardBody>
                          </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={5}>
                        <Card>
                          <CardHeader>
                            <h4><small> Time Distribution</small></h4>
                          </CardHeader>
                          <CardBody>
                            <ChartistGraph
                                data={
                                  {
                                    labels: ["8%", "32%", "60%"],
                                    series: [8, 32, 60],
                                  }
                              }
                              type="Pie"
                              options={pieChart.options}
                            />
                          </CardBody>  
                         
                          <CardFooter stats className={classes.cardFooter}>
                            {
                              <>
                            <i className={"fas fa-circle " + classes.info} /> Driving{` `}
                            <i className={"fas fa-circle " + classes.warning} /> Walking
                            {` `}
                            <i className={"fas fa-circle " + classes.danger} /> Idle
                                  {` `}
                                </>}
                            
                          </CardFooter>
                        </Card>
                      </GridItem>
                      </GridContainer>
                    ),
                  },
                  {
                    tabButton: "Timeline",
                    tabIcon: TimelineIcon,
                    tabContent: (
                      <GridContainer justify = "space-between"
                        style = {{
                            justifyContent: "center",
                            textAlign: "center"
                          }}>  
                          <GridItem xs={12} sm={12} md={12} lg={9}>
                            {simulationTimeline?.length > 0 ? (<Timeline simple stories={simulationTimeline} />) : ""}
                          </GridItem>
                        </GridContainer>

                    ),
                  },
                  {
                    tabButton: "Activity",
                    tabIcon: StorageIcon,
                    tabContent: (
                      <Card>
                        <CardHeader>
                          <h4 className={classes.cardTitle}>
                            Real time Activity
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <Table
                            tableHead={[
                              "Status",
                              "Timestamp",
                              "Trackdata",
                            ]}
                            tableData={[
                              [
                                "Active",
                                moment().format("YYYY/MM/DD hh:mm A"),
                                trackDataDetails({battery: 82, location: "12.3,-12.33", activity: "Idle"}),
                              ],
                              [
                                "Active",
                                moment().format("YYYY/MM/DD hh:mm A"),
                                trackDataDetails({battery: 84, location: "12.3,-12.33", activity: "Walking"}),
                              ],
                              [
                                "Active",
                                moment().format("YYYY/MM/DD hh:mm A"),
                                trackDataDetails({battery: 85, location: "12.3,-12.33", activity: "Walking"}),
                              ],
                        
                            ]}
                            customCellClasses={[classes.center, classes.right, classes.right]}
                            customClassesForCells={[0, 4, 5]}
                            customHeadCellClasses={[
                              classes.center,
                              classes.right,
                              classes.right,
                            ]}
                            customHeadClassesForCells={[0, 4, 5]}
                          />
                        </CardBody>
                      </Card>
                    ),
                  }
                ]}
              />
            </GridItem>
          </GridContainer>)}
        </DialogContent>
      </Dialog>
      
        <Accordion
          collapses={[
          {
            title: (<h5 style={{padding: "15px"}}>View On Map</h5>),
            content: (
              <>
                {teamGlobalMap()}
              </>
            )
          }]}
        />
        {alert}
        <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            headerColor="success"
            // customFunction={customFunctionDP}
              tabs={[
                
                {
                  tabName: "Team Members",
                  tabIcon: GroupIcon,
                  tabContent: (
                  <>
                      {teamMemberList()}
                  </>
                  ),
                },
                {
                  tabName: "Places",
                  tabIcon: LocationOnIcon,
                  tabContent: (
                    <>
                    {placesList()}
                    </>
                  ),
                },
                {
                  tabName: "Notifications",
                  tabIcon: AddAlertIcon,
                  tabContent: (
                    <>
                     <Notifications />
                    </>
                  ),
                },
            ]}
          >
          </CustomTabs>
        </GridItem>
      </GridContainer> 
        
        {/* <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <LocationOnIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Top Locations
              </h4>
            </CardHeader>
            <CardBody>
              {currentUserData?.totaldeliveries > 0 && topLocations && topLocations.length > 0 ? topLocationData() : noDeliveries()}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <GroupIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Top Customers
              </h4>
            </CardHeader>
            <CardBody>
              {currentUserData?.totaldeliveries > 0  && topCustomers && topCustomers.length > 0 ? topCustomerData() : noDeliveries()}
            </CardBody>
          </Card>
        </GridItem> */}
      {/* {placesAndTeamMembers()} */}
      {/* {places ? placesList() : teamMemberList()} */}
    
      </>
    )
  }
  return (
    <div>
    	{teamsScreen()}
    </div>
  );
}
