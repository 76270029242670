import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import Firebase from "firebase";
import firebase from 'firebase/app';
import Firebase from "firebase";

import 'firebase/storage';  // <----
import Add from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
// import Firebase from "firebase";
// const firebaseConfig = {
//   apiKey: "AIzaSyDSaYuHlpFznfk_nhTcitcoGG-8QUGK1ec",
//   authDomain: "onroad3-e593b.firebaseapp.com",
//   databaseURL: "https://onroad3-e593b-default-rtdb.firebaseio.com",
//   projectId: "onroad3-e593b",
//   storageBucket: "onroad3-e593b.appspot.com",
//   messagingSenderId: "587064425563",
//   appId: "1:587064425563:web:99aac8f9a9a8b7cae9137e",
//   measurementId: "G-5VFLZ6SK9T"
// }
// firebase.initializeApp(firebaseConfig);

import 'firebase/storage';
export default function BrandingImageUpload(props) {
  const [file, setFile] = React.useState(null);
  const [imageUploaded, setImageUploaded] = React.useState(props.existingImage);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.existingImage || defaultImage
  );
  let fileInput = React.createRef();
  const uploadImage = async () => {
    const storageRef = firebase.storage().ref(`onroadtracking/${props.imageID()}`)
    await storageRef.put(file);
  
    Firebase.database().ref(`/trackconfig/deliverytrack/${props.email.replace(/\./g,",")}/brand/logo`).set(
      `https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/${encodeURIComponent("onroadtracking/".concat(props.imageID()))}?alt=media&token=8a471e43-79d3-489e-b166-cd650271f30a`
    )
    setImageUploaded(true);
  }
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = async () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setImageUploaded(false);
  };
  // eslint-disable-next-line
  const handleSubmit = (storeemail, productID) => {
    e.preventDefault();
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
    
    Firebase.database()
    .ref(`/trackconfig/deliverytrack/${props.email.replace(/\./g,",")}/brand/logo`).set(null)
    setImageUploaded(true);
  };

  // const checkImage = async (url) => {
  //   const res = await fetch(url);
  //   if(res.status === 404){
  //     return false
  //   }
  //   return true;
  // }
  let { avatar, addButtonProps, changeButtonProps, removeButtonProps } = props;
  return (
    <div className="fileinput text-center" style={{width: "100%"}}>
     {props.existingImage ? (<>
      <input type="file" onChange={handleImageChange} ref={fileInput} />
          <div className={"thumbnail"}>
            <img src={imagePreviewUrl} alt="..." style={{maxHeight: "100px" }}/>
          </div>
          <br />
          <span>
            <Button simple {...changeButtonProps} onClick={() => handleClick()} style={{padding: "5px", fontSize: "6px"}}>
              Change
            </Button>
            {avatar ? <br /> : null}
            <Button simple {...removeButtonProps} onClick={() => handleRemove()} style={{padding: "5px", fontSize: "6px"}}>
              <i className="fas fa-times" style={{fontSize: "5px"}} /> Remove
            </Button>
            <br />
            {!imageUploaded && (<Button {...removeButtonProps} onClick={() => uploadImage()} style={{padding: "5px", fontSize: "10px"}}>
              <i className="fas fa-upload" style={{fontSize: "5px"}} /> Looks Good Upload
            </Button>)}
          </span>
          </>
        )
        : 
        (<><input type="file" onChange={handleImageChange} ref={fileInput} />
          <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
            <img src={imagePreviewUrl} alt="..." style={{maxHeight: "100px" }}/>
          </div>
          <div>
            {file === null ? (
              <Button {...addButtonProps} onClick={() => handleClick()} style={{padding: "5px", fontSize: "10px"}}>
                {avatar ? "Add Photo" : "Select Logo"}
              </Button>
            ) : (
              !imageUploaded && (<span>
                <Button simple {...changeButtonProps} onClick={() => handleClick()} style={{padding: "5px", fontSize: "6px"}}>
                  Change
                </Button>
                {avatar ? <br /> : null}
                <Button simple {...removeButtonProps} onClick={() => handleRemove()} style={{padding: "5px", fontSize: "6px"}}>
                  <i className="fas fa-times" style={{fontSize: "5px"}} /> Remove
                </Button>
                <br />
                <Button {...removeButtonProps} onClick={() => uploadImage()} style={{padding: "5px", fontSize: "6px"}}>
                  <i className="fas fa-upload" style={{fontSize: "5px"}} /> Looks Good Upload
                </Button>
              </span>)
            )}
          </div>
        </>)
      }
    </div>
  );
}

BrandingImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  existingImage: PropTypes.string,
  imageNumber: PropTypes.number,
  email: PropTypes.string,
  apikey: PropTypes.string,
};
