import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import {
  NavLink
} from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import GroupIcon from '@material-ui/icons/Group';
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import RedeemIcon from '@material-ui/icons/Redeem';
import LocationOnIcon from '@material-ui/icons/LocationOn';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-google-places-autocomplete';
import CryptoJS from 'crypto-js'


import Firebase from "firebase";
const firebaseConfig = {
  apiKey: "AIzaSyDSaYuHlpFznfk_nhTcitcoGG-8QUGK1ec",
  authDomain: "onroad3-e593b.firebaseapp.com",
  databaseURL: "https://onroad3-e593b-default-rtdb.firebaseio.com",
  projectId: "onroad3-e593b",
  storageBucket: "onroad3-e593b.appspot.com",
  messagingSenderId: "587064425563",
  appId: "1:587064425563:web:99aac8f9a9a8b7cae9137e",
  measurementId: "G-5VFLZ6SK9T"
}
Firebase.initializeApp(firebaseConfig);


import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";


const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }
  const [currentUserData, setCurrentUserData] = React.useState();
  const [topLocations, setTopLocations] = React.useState();
  const [topCustomers, setTopCustomers] = React.useState();
  const [heatMapData, setHeatMapData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [address, setAddress] = React.useState();
  const [addressLatLng, setAddressLatLng] = React.useState();
  const [addressLocation, setAddressLocation] = React.useState();
  const [instantLaundryOrders, setInstantLaundryOrders] = React.useState();

  const [cMap, setCMap] = React.useState();
  const [markersRef, setMarkersRef] = React.useState();

  const google = window.google;
  
  const mapRef = React.useRef(null);

  React.useEffect(() => {
    // if(!teamLatLng){
    //   return;
    // }
    let google = window.google;
    let map = mapRef.current;
    const myLatlng = new google.maps.LatLng("37.422006", "-122.0841106");

    const mapOptions = {
      zoom: 13,
      center: myLatlng,
      mapTypeControl: false,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry.fill",
          stylers: [
            { visibility: "off" }
            
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [
            { visibility: "off" }
          ],
        },
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [
            { visibility: "off" }
          ],
        },
        {
          featureType: "administrative.neighborhood",
          elementType: "labels.text.fill",
          stylers: [
            { visibility: "off" }
          ],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.fill",
          stylers: [
            { visibility: "off" }
          ],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.stroke",
          stylers: [
            { visibility: "off" }
          ],
        },
        {
          featureType: "landscape.natural",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#dde2e3",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#7492a8",
            },
          ],
        },
        {
          featureType: "landscape.natural.terrain",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#dde2e3",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.icon",
          stylers: [
            { visibility: "off" }
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            { visibility: "off" }
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.fill",
          stylers: [
            { visibility: "off" }
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#bae6a1",
            },
          ],
        },
        {
          featureType: "poi.sports_complex",
          elementType: "geometry.fill",
          stylers: [
            { visibility: "off" }
          ],
        },
        {
          featureType: "poi.sports_complex",
          elementType: "geometry.stroke",
          stylers: [
            { visibility: "off" }
          ],
        },
        {
          featureType: "road",
          elementType: "labels.icon",
          stylers: [
            {
              saturation: -45,
            },
            {
              lightness: 10,
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#41626b",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#c1d1d6",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#a6b5bb",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road.highway.controlled_access",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#9fb6bd",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "labels.icon",
          stylers: [
            {
              saturation: -70,
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#b4cbd4",
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#588ca4",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#008cb5",
            },
          ],
        },
        {
          featureType: "transit.station.airport",
          elementType: "geometry.fill",
          stylers: [
            {
              saturation: -100,
            },
            {
              lightness: -5,
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#a6cbe3",
            },
          ],
        },
      ],
    }
    map = new google.maps.Map(map, mapOptions);

    setCMap(map)
  }, []);


  React.useEffect(() => {

    Firebase.database()
      .ref(`/tracking/teams/placelist/${user.email.replace(/\./g, ",")}/`)
      .once('value', snapshot => {
        const data = snapshot.val()
        if (data) {
       
          let heatMapLatLng = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];

            const a = new google.maps.LatLng(element.addresslatlng.lat, element.addresslatlng.lng)
            heatMapLatLng.push(a);
          }
          if (heatMapLatLng && heatMapLatLng.length > 0) {
            setHeatMapData(heatMapLatLng)
          }
        }
      })
      
  }, [])

  React.useEffect(() => {
    if(heatMapData && heatMapData.length > 0){
      var heatmap = new google.maps.visualization.HeatmapLayer({
        data: heatMapData
      });
      var bounds = new google.maps.LatLngBounds();
      for (var i = 0; i < heatMapData.length; i++) {
      bounds.extend(heatMapData[i]);
      }

      cMap.fitBounds(bounds);
      heatmap.setMap(cMap);
    }
  }, [heatMapData])

  // React.useEffect(() => {
  //   if(currentUserData && currentUserData.instantlaundryadmin) {
  //     const onChildAdd = Firebase.database()
  //     .ref(`/instantlaundry/orders/`)
  //     .on('value', snapshot => {
  //       const data = snapshot.val()
  //       if (data) {
  //         let result = []
  //         const keys = Object.keys(data);
  //         for (let index = keys.length - 1; index > -1; index--) {
  //           if(data[keys[index]] && data[keys[index]].name && data[keys[index]].phone && data[keys[index]].address){
  //             const element = Object.assign(data[keys[index]], {id: keys[index]})
  //             const deliverypickbutton = element.deliverypersonname ? `${element.deliverypersonname}:instantlaundrydeliverybutton:done`: `phone:${element.phone}:address:${element.address}:addresslatlng:${element.addresslatlng.lat},${element.addresslatlng.lng}:addresslocation:${element.addresslocation}:instantlaundrydeliverybutton:id:${element.id}`
  //             const storepickbutton = element.storename  ? `${element.storename}:instantlaundrysstorepickbutton:done`: `name:${element.name}:phone:${element.phone}:address:${element.address}:addresslatlng:${element.addresslatlng.lat},${element.addresslatlng.lng}:addresslocation:${element.addresslocation}:service:${element.service}:instantlaundrysstorepickbutton:id:${element.id}`
  //             result.push([`instantlaundryordertime:${element.time}`, element.name, element.phone, element.address, element.service, deliverypickbutton, storepickbutton, element.storeworkstatus || "Store Pending"])
  //           }
  //         }
  //         setInstantLaundryOrders(result)
  //       }
        
  //     });
  //   return () => Firebase.database().ref(`/instantlaundry/orders/`).off('value', onChildAdd);
  //   }
  // }, [currentUserData && currentUserData.instantlaundryadmin])
  
  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setCurrentUserData(data);
          if (data && !data.storeaddress) {
            if (!(window.location.host == "dashboard.hourstrack.com")) {
              setOpen(true)  
            }
          }
          if(data && data.storeaddress && data.storeaddresslatlng){
            setAddress(data.storeaddress)
            setAddressLatLng(data.storeaddresslatlng)
          }
          if(data && !data.currentplan){
            Firebase.database()
            .ref(`/webadmin/${user.email.replace(/\./g, ",")}/`).update({currentplan: "pay as you go", perdeliverycost: 0.15})
          }
          if (data && !data.features) {

            if (window.location.host == "dashboard.hourstrack.com") {
              Firebase.database()
              .ref(`/webadmin/${user.email.replace(/\./g, ",")}/features/`).update({delivery:false, jobs:false,teams: true, timecard:true})
            } else {
              Firebase.database()
              .ref(`/webadmin/${user.email.replace(/\./g, ",")}/features/`).update({delivery:true, jobs:true,teams: true, timecard:true})
            }
           
          }
        }
      });
    return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])

  const handleClose = () => {
    setOpen(false)
  }
  const handleSave = () => {
    Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
      .once('value', snapshot => {
        let val = snapshot.val()
        snapshot.ref.update({
          storeaddress: address,
          storeaddresslocation: addressLocation,
          storeaddresslatlng: addressLatLng
        })
    })
    setOpen(false)
  }

  const getCoordinates = (address) => {
    geocodeByAddress(address.value.description)
      .then(results => getLatLng(results[0]))
      .then((data) => {
        // console.log('LAT: ', data.lat);
        // console.log('LNG: ', data.lng);
        setAddress(address.value.description)
        if (address.value && address.value.structured_formatting && address.value.structured_formatting.secondary_text) {
          setAddressLocation(address.value.structured_formatting.secondary_text)
        }
        setAddressLatLng(data)
      })
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">Your Address</DialogTitle>
        <DialogContent style={{minHeight: "400px"}}>
          <DialogContentText>
            Enter Address
          </DialogContentText>
          <GooglePlacesAutocomplete
            selectProps={{
            onChange: (address) => getCoordinates(address),
          }}
            placeholder="Search Address "
            apiKey = "AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk"
          />
          <h4>{address}</h4>
          {addressLatLng && (<img src={`https://maps.googleapis.com/maps/api/staticmap?center=${addressLatLng.lat},${addressLatLng.lng}&zoom=18&size=550x300&maptype=roadmap&markers=color:red%7Clabel:G%7C${addressLatLng.lat},${addressLatLng.lng}&key=AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk`} title="" />)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={!address || !addressLatLng}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <GridContainer>
        {currentUserData?.storeaddress && (<GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="info" stats icon>
              <p className={classes.cardCategory}>Your Address</p>
              <h3 className={classes.cardTitle} style={{fontSize: "15px"}}>{currentUserData?.storeaddress}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <span onClick={() => setOpen(true)} style={{color: "#9c27b0"}}>
                  Change Address
                </span>
              </div>
            </CardFooter>
          </Card>
        </GridItem>)}
        {/* <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <RedeemIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Used Credits</p>
              <h3 className={classes.cardTitle}>
                {currentUserData?.usedcredits}/{currentUserData?.initialcredits}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <NavLink to={"/admin/pricing"} href="#pablo">
                  Pricing Page
                </NavLink>
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Total Places</p>
              <h3 className={classes.cardTitle}>{currentUserData?.totalplaces}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <NavLink to={"/admin/teams"}>
                  Go To Places
                </NavLink>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <GroupIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Team Members</p>
              <h3 className={classes.cardTitle}>{currentUserData?.totalteammembers}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <NavLink to={"/admin/teams"}>
                  Go To Teams
                </NavLink>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
             
              <h4 className={classes.cardIconTitle}>
                Activity
              </h4>
            </CardHeader>
            <CardBody>
             
              <div
                style={{
                  height: `400px`,
                  borderRadius: "6px",
                  overflow: "hidden",
                }}
                ref={mapRef}
              />
            </CardBody>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <LocationOnIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Top Locations
              </h4>
            </CardHeader>
            <CardBody>
              {currentUserData?.totaldeliveries > 0 && topLocations && topLocations.length > 0 ? topLocationData() : noDeliveries()}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <GroupIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Top Customers
              </h4>
            </CardHeader>
            <CardBody>
              {currentUserData?.totaldeliveries > 0  && topCustomers && topCustomers.length > 0 ? topCustomerData() : noDeliveries()}
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>
      {/* {currentUserData?.instantlaundryadmin  && instantLaundryOrders?.length > 0 && (
        <GridContainer>
         <GridItem xs={12} sm={12} md={12}>
         <Card>
           <CardHeader color="success" icon>
             <CardIcon color="success">
               <GroupIcon />
             </CardIcon>
             <h4 className={classes.cardIconTitle}>
               Instant Laundry Admin
             </h4>
           </CardHeader>
           <CardBody>
            {
              instantLaundryOrders && instantLaundryOrders.length > 0 ? (<Table
                  tableHeaderColor="primary"
                  tableData={instantLaundryOrders}
                  coloredColls={[3]}
                  colorsColls={["primary"]}
              />) : (<h4>No Orders</h4>)
            }
           </CardBody>
         </Card>
       </GridItem>
     </GridContainer>
      )} */}
    </div>
  );
}
