import React from "react";
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import { NavLink } from "react-router-dom";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import PinDropIcon from '@material-ui/icons/PinDrop';
import RoomIcon from '@material-ui/icons/Room';
import Code from "@material-ui/icons/Code";
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import Group from "@material-ui/icons/Group";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";
import BusinessIcon from '@material-ui/icons/Business';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import CryptoJS from 'crypto-js'

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

const useStyles = makeStyles(styles);
import firebase from "firebase/app";
import "firebase/auth";
import {
  FirebaseAuthProvider,
  IfFirebaseUnAuthed,
  IfFirebaseAuthed,
  FirebaseAuthConsumer,
  IfFirebaseAuthedAnd
} from "react-firebase-auth-provider";

const config = {
  apiKey: "AIzaSyDSaYuHlpFznfk_nhTcitcoGG-8QUGK1ec",
  authDomain: "onroad3-e593b.firebaseapp.com",
  databaseURL: "https://onroad3-e593b-default-rtdb.firebaseio.com",
  projectId: "onroad3-e593b",
  storageBucket: "onroad3-e593b.appspot.com",
  messagingSenderId: "587064425563",
  appId: "1:587064425563:web:99aac8f9a9a8b7cae9137e",
  measurementId: "G-5VFLZ6SK9T"
}

export default function RegisterPage() {
  const [isRegister, setIsRegister] = React.useState(false);
  const [organizationName, setOrganizationName] = React.useState();
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();
  const [currentUser, setCurrentUser] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [emailVerificaitonSent, setEmailVerificaitonSent] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState();
  const [countryFlag, setCountryFlag] = React.useState();
  const [locationData, setLocationData] = React.useState();
  const [phone, setPhone] = React.useState();

  const generateKey = () =>{
    const key = 'OnroadApiKey';
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    const enc = CryptoJS.AES.encrypt(email, keyutf, {
      iv: iv
    });
    const encString = enc.toString()
                   .replace(/\./g,'p3Ri')
                   .replace(/\$/g,'D0La')
                   .replace(/\[/g,'53Ft')
                   .replace(/\]/g,'R18t')
                   .replace(/\#/g,'H3Sh')
                   .replace(/\//g,'S1Sh');
    return encString
  }
  const organizationNameChange = (e) => {
    setOrganizationName(e.target.value)
  }
  const emailChange = (e) => {
    setEmail(e.target.value)
  }
  const phoneChange = (e) => {
    setPhone(`${countryCode}${e.target.value}`)
  }
  const passwordChange = (e) => {
    setPassword(e.target.value)
  }
  const resendEmailVerificationLink = () => {
    currentUser.sendEmailVerification();
    setEmailVerificaitonSent(true);
  }
  const generateApiKey = () =>{
    return 
  }
  const registerUser = () => {
      
     firebase.auth().createUserWithEmailAndPassword(email, password)
       .then((userCredential) => {
         var user = userCredential.user;
         setCurrentUser(user);
         user.updateProfile({
           displayName: organizationName
         }).then(function () {
             let ref = firebase.database()
               .ref(`/webadmin/${email.replace(/\./g, ",")}`);
               ref.set({
                 storeid: email,
                 email: email,
                 name: organizationName,
                 phone: phone,
                 locationdata: locationData,
                 countrycode: countryCode,
                 countryflag: countryFlag,
                 initialcredits: 1000,
                 availablecredits: 0,
                 usedcredits: 0,
                 totaldeliveries: 0,
                 totaldeliveryteam: 0,
                 subscription: "starter",
                 apikey: generateKey(),
                 phone: "",
                 currentplan: "pay as you go",
                 perdeliverycost: 0.15
               })
              user.sendEmailVerification();
          }, function (error) {
            // An error happened.
          });
       })
       .catch((error) => {
         var errorCode = error.code;
         var errorMessage = error.message;
         setErrorMessage(errorMessage);
         // ..
       });
     
    console.log("register user", organizationName, email, password)
  }
  const loginUser = () => {
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(async (userCredential) => {
        // Signed in
        var user = userCredential.user;
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        setErrorMessage(errorMessage);
      });

  }
  const classes = useStyles();

  const getCountryCode = () => {
    fetch(
      'https://api.ipapi.com/check?access_key=f07eeacbc8785184d4d862ac6e84eb06&format=1',
    )
      .then(response => response.json())
      .then(data => {
        if (data) {
          setLocationData(data)
          const countrycode = `+${data.location.calling_code}`;
          setCountryCode(countrycode)
          setCountryFlag(data.location.country_flag_emoji)
        }
      })
      .catch(error => {
        console.log("=====ip api error====", error);
      });
  };
  const trackOrLocation = () => {
    if (window.location.host == "dashboard.hourstrack.com") {
      return (
        <>
           <GridItem xs={12} sm={12} md={5}>
                  <InfoArea
                    title="Kiosk"
                    description="By using a check-in and checkout kiosk, employees can record their work hours. "
                    icon={RoomIcon}
                    iconColor="rose"
                  />
                  <InfoArea
                    title="Report Generation"
                    description = "Report can be generated in csv format with custom filed names"
                    icon={PinDropIcon}
                    iconColor="primary"
                  />
                  <InfoArea
                    title="Realtime Dashboard"
                    description="You can monitor real time hours while employees checkin and checkout"
                    icon={FormatListNumberedIcon}
                    iconColor="info"
                  />
                </GridItem>
        </>
      )
    } else {
      return (
        <>
           <GridItem xs={12} sm={12} md={5}>
                  <InfoArea
                    title="Live Tracking"
                    description="After all it is 2022, we need live tracking; doesn't matter what type of delivery it is. For every deliver you do, we create a live tracking url"
                    icon={RoomIcon}
                    iconColor="rose"
                  />
                  <InfoArea
                    title="Route Optimization"
                    description = "We've have the most powerful route optimization engine. You can get the results within seconds. You can use our API for your optimization needs. Also, we have it in the mobile app"
                    icon={PinDropIcon}
                    iconColor="primary"
                  />
                  <InfoArea
                    title="Job Scheduling"
                    description="For large number of deliveries, manual assignment is impossible. We do automatic delivery job scheduling given list of orders and list of deliver persons"
                    icon={FormatListNumberedIcon}
                    iconColor="info"
                  />
                </GridItem>
        </>
      )
    }
      
    
  }
  const loginForm = () => {
    return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>{isRegister ? "Register" : "Login"}</h2>
            <CardBody>
              <GridContainer justify="center">
                {trackOrLocation()}
                <GridItem xs={12} sm={8} md={5}>
                  <form className={classes.form}>
                    {isRegister ? (<CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                          <BusinessIcon className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Organization Name...",
                        onChange: organizationNameChange
                      }}
                    />): null}
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Email...",
                        onChange: emailChange
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        placeholder: "Password...",
                        type: "password",
                        onChange: passwordChange
                      }}
                    />
                    {isRegister && (<CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                          {countryFlag} {" "} {countryCode}
                          </InputAdornment>
                        ),
                        placeholder: "10 Digit Phone Number",
                        onChange: phoneChange
                      }}
                    />)}
                    {errorMessage ? (<p style={{color: "red", textAlign: "center"}}>{errorMessage}</p>) : null}
                    <div style={{textAlign: "center", padding: 10}}>
                      {isRegister ? (<span>
                          I already have account {" "}
                          <Button color="transparent" style={{color: "blue"}}onClick={()=>{
                            setIsRegister(false)
                          }}>Login</Button>.
                      </span>) :
                      (<span>
                          I don't have account {" "}
                            <Button color="transparent" style={{color: "blue"}} onClick={()=>{
                            getCountryCode()
                            setIsRegister(true)
                          }}>Register</Button>.
                      </span>)
                      }
                    </div>
                    <div className={classes.center}>
                      <Button round color="primary" onClick={() => {
                        isRegister ? registerUser() : loginUser()
                      }} disabled={isRegister ? (!organizationName || !email || !password || !phone || phone.length != (10 + countryCode.length)) : (!email || !password)}>
                        {isRegister ? "Register" : "Login"}
                      </Button>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
    );
  }
  const loginAgain = () => {
    window.location.reload();
  }
  const emailVerifyScreen = () => {
    return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>Email Verification</h2>
            <CardBody>
               <div className={classes.center}>
                  <h3> Please click on link send to your email address to verify </h3>
                  {emailVerificaitonSent ? null: <Button round color="primary" onClick={resendEmailVerificationLink} disabled={emailVerificaitonSent}>Resend Confirmation</Button>}
                  {emailVerificaitonSent ? null: <div><small>Already confirmed ? </small><a href="#" onClick={loginAgain}>Login</a></div>}
                  <br />
                  {emailVerificaitonSent ? <small>Email Sent</small> : null}
                  <br />
                  {emailVerificaitonSent ? <a href="#" onClick={loginAgain}>Login</a> : null}
                </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
    );
  }
  return (
    <FirebaseAuthProvider {...config} firebase={firebase}>
      <FirebaseAuthConsumer>
        {({ isSignedIn, user, providerId }) => {
          setCurrentUser(user);
          if(isSignedIn && user.emailVerified){
            return (
            // <pre style={{ height: 300, overflow: "auto" }}>
            //   {JSON.stringify({ isSignedIn, user, providerId }, null, 2)}
            // </pre>
              <Redirect from="/auth" to="/admin/dashboard" />
            );
          }
          if(isSignedIn && !user.emailVerified) {
            return emailVerifyScreen();
          }else{
            return loginForm();
          }
        }}
      </FirebaseAuthConsumer>
      {/* <div className={classes.container}>
        <button
          onClick={() => {
            // const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
            // firebase.auth().signInWithPopup(googleAuthProvider);
           
            // firebase.auth().createUserWithEmailAndPassword("dkeixm@gmail.com", "haribabu")
            //   .then((userCredential) => {
            //     // Signed in 
            //     var user = userCredential.user;
            //     console.log("===user===", user);
            //     // ...
            //   })
            //   .catch((error) => {
            //     var errorCode = error.code;
            //     var errorMessage = error.message;
            //     console.log("====error===", errorCode, errorMessage);
            //     // ..
            //   });

            firebase.auth().signInWithEmailAndPassword("dkeixm@gmail.com", "haribabu")
              .then(async (userCredential) => {
                // Signed in
                var user = userCredential.user;
                awaituserCredential.user.sendEmailVerification();
                console.log("===user===", user);
                // ...
              })
              .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log("====error===", errorCode, errorMessage);
              });

          }}
        >
          Sign In with Google
        </button>
        <button
          onClick={() => {
            firebase.auth().signInAnonymously();
          }}
        >
          Sign In Anonymously
        </button>
        <button
          onClick={() => {
            firebase.auth().signOut();
          }}
        >
          Sign Out
        </button>
        <FirebaseAuthConsumer>
          {({ isSignedIn, user, providerId }) => {
            return (
              <pre style={{ height: 300, overflow: "auto" }}>
                {JSON.stringify({ isSignedIn, user, providerId }, null, 2)}
              </pre>
            );
          }}
        </FirebaseAuthConsumer>
        <div>
          <IfFirebaseAuthed>
            {() => {
              return <div>You are authenticated</div>;
            }}
          </IfFirebaseAuthed>
          <IfFirebaseAuthedAnd
            filter={({ providerId }) => providerId !== "anonymous"}
          >
            {({ providerId }) => {
              return <div>You are authenticated with {providerId}</div>;
            }}
          </IfFirebaseAuthedAnd>
        </div>
      </div> */}
    </FirebaseAuthProvider>
  );
}
