import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import GroupIcon from '@material-ui/icons/Group';
import Business from "@material-ui/icons/Business";
import AccountBalance from "@material-ui/icons/AccountBalance";
import AddAlert from "@material-ui/icons/AddAlert";
import ArrowForward from '@material-ui/icons/ArrowForward';
import DeliveryPage from "views/Pages/DeliveryPage";
import TimerIcon from '@material-ui/icons/Timer';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import {
  NavLink
} from "react-router-dom";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";
import Firebase from 'firebase';

const useStyles = makeStyles(styles);
const serverTimestamp = Firebase.database.ServerValue.TIMESTAMP;

export default function TrackPage() {
  const classes = useStyles();
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }

  const [currentUserData, setCurrentUserData] = React.useState();
  const [tr, setTR] = React.useState(false);  
  
  
  React.useEffect(() => {

    const onChildAdd = Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setCurrentUserData(data);
        }        
      });
    return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
        <Snackbar
          place="tr"
          color="danger"
          message="Your plan changed, we will send you email on next steps"
          open={tr}
          closeNotification={() => setTR(false)}
          close
        />
          {/* <h2 className={classes.title}>Free Credits: 1000</h2> */}
          <h5 className={classes.description} color="rose">
            Your Business Case For Tracking
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
      {currentUserData?.features?.timecard && (<GridItem xs={12} sm={12} md={3}>
            <Card pricing raised={true}>
              <CardBody pricing>
                <h6 className={classes.cardCategory}>Timecard</h6>
                <div className={classes.icon}>
                  <TimerIcon className={classes.iconRose} />
                </div>
                <h4 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                  In-Store Track
                </h4>
                <p className={classes.cardDescription}>
                  Check-In, Check-out, Time clock, Attendance system
                </p>
                <NavLink to={"/admin/timecard"} style={{color: "white"}}>
                <Button round color="rose" disabled={!currentUserData?.features?.timecard}>
                    Timecard &nbsp; <ArrowForward />
                  </Button>
                  </NavLink>
              </CardBody>
            </Card>
        </GridItem>)}
        {currentUserData?.features?.teams && (<GridItem xs={12} sm={12} md={3}>
            <Card pricing raised={true}>
              <CardBody pricing>
                <h6 className={classes.cardCategory}>Team</h6>
                <div className={classes.icon}>
                  <GroupIcon className={classes.iconRose} />
                </div>
                <h4 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                  Field
                </h4>
                <p className={classes.cardDescription}>
                  Track Teams, Field Sales ..etc
                </p>
                <NavLink to={"/admin/teams"} style={{color: "white"}}>
                  <Button round color="rose" >
                    Teams &nbsp; <ArrowForward />
                  </Button>
                  </NavLink>
              </CardBody>
            </Card>
        </GridItem>)}
       {currentUserData?.features?.delivery && (<GridItem xs={12} sm={12} md={3}>
          <Card pricing raised={true}>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>Delivery</h6>
              <div className={classes.icon}>
                <LocalShippingIcon className={classes.iconRose} />
              </div>
              <h4 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                Track Deliveries
              </h4>
              <p className={classes.cardDescription}>
                Ecommerce, Delivery, Shipping ..etc
              </p>
              {/* to={"/admin/delivery"} */}
                <NavLink to={currentUserData?.features?.delivery ? "/admin/delivery" : "/"} style={{color: "white"}}>
                  <Button round color="rose" disabled={!currentUserData?.features?.delivery}>
                  Deliveries &nbsp; <ArrowForward />
                  </Button>
              </NavLink>
              {!currentUserData?.features?.delivery && (<p style={{fontSize: "8px"}}>Not enabled for your account</p>)}

              
            </CardBody>
          </Card>
        </GridItem>)}
       {currentUserData?.features?.jobs && ( <GridItem xs={12} sm={12} md={3}>
          <Card pricing raised={true}>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>Jobs</h6>
              <div className={classes.icon}>
                <DirectionsBikeIcon className={classes.iconRose} />
              </div>
              <h4 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                Gig Work
              </h4>
              <p className={classes.cardDescription}>
                Local Moving, Cleaning, Repairs ..etc
              </p>
              {/* to={"/admin/trips"} */}
              <NavLink to={currentUserData?.features?.jobs ? "/admin/trips" : "/"}  style={{color: "white"}}> 
                <Button round color="rose" disabled={!currentUserData?.features?.jobs}>
                  Jobs &nbsp; <ArrowForward />
                </Button>
              </NavLink>
              {!currentUserData?.features?.jobs && (<p style={{ fontSize: "8px" }}>Not enabled for your account</p>)}
              
            </CardBody>
          </Card>
        </GridItem>)}
        
        {/* <GridItem xs={12} sm={12} md={3}>
          <Card pricing raised>
            <CardBody pricing >
              <h6 className={classes.cardCategory}>MEDIUM COMPANY</h6>
              <div className={classes.icon}>
                <Business className={classes.iconWhite} />
              </div>
              <h3
                className={`${classes.cardTitleWhite} ${classes.marginTop30}`}
              >
                $69
              </h3>
              <p className={classes.cardCategory}>
                This is good if your company size is between 11 and 99 Persons.
              </p>
              <Button round color="white">
                Choose plan
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card pricing plain>
            <CardBody pricing plain>
              <h6 className={classes.cardCategory}>ENTERPRISE</h6>
              <div className={classes.icon}>
                <AccountBalance className={classes.iconWhite} />
              </div>
              <h3
                className={`${classes.cardTitleWhite} ${classes.marginTop30}`}
              >
                $159
              </h3>
              <p className={classes.cardCategory}>
                This is good if your company size is 99+ persons.
              </p>
              <Button round color="white">
                Choose plan
              </Button>
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>
      {/* <GridContainer justify="center">
        {currentUserData?.features?.timecard && (<GridItem xs={12} sm={12} md={3}>
            <Card pricing raised={true}>
              <CardBody pricing>
                <h6 className={classes.cardCategory}>Timecard</h6>
                <div className={classes.icon}>
                  <TimerIcon className={classes.iconRose} />
                </div>
                <h4 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                  In-Store Track
                </h4>
                <p className={classes.cardDescription}>
                  Check-In, Check-out, Time clock, Attendance system
                </p>
                <NavLink to={"/admin/timecard"} style={{color: "white"}}>
                <Button round color="rose" disabled={!currentUserData?.features?.timecard}>
                    Timecard &nbsp; <ArrowForward />
                  </Button>
                  </NavLink>
              </CardBody>
            </Card>
        </GridItem>)}
      </GridContainer> */}
    </div>
  );
}
