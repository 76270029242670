import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CustomInput from "components/CustomInput/CustomInput.js";
import Input from "@material-ui/core/Input";
import CloseIcon from '@material-ui/icons/Close';
import Firebase from "firebase";
import Button from "components/CustomButtons/Button.js";
import RoomIcon from '@material-ui/icons/Room';
import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import { addSyntheticLeadingComment } from "typescript";
import moment from "moment";
import Switch from "@material-ui/core/Switch";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import SweetAlert from "react-bootstrap-sweetalert";
import {default as styles2} from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Datetime from "react-datetime";


const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);

export default function CustomTable(props) {
  const classes = useStyles();

  const classes2 = useStyles2();
  

  const [showAlert, setShowAlert] = React.useState(false);
  const [overWriteTime, setOverWriteTime] = React.useState();
  const [overWriteError, setOverWriteError] = React.useState();
  const [alertDataArray, setAlertDataArray] = React.useState([]);
  
  const handleOverWriteTime = (momentObj) => {
    setOverWriteTime(momentObj.format("hh:mm A"));
    setOverWriteError(null)
  };

  const handleShowAlert = (rT) => {
    setAlertDataArray(rT)
    setShowAlert(true);
  };

  const handleHideAlert = () => {
    setOverWriteTime(null)
    setOverWriteError(null)
    setAlertDataArray([])
    setShowAlert(false);
  };
  const handleConfirmAlert = () => {
    if (!overWriteTime) {
      setOverWriteError("Please Enter A Valid Time")
      return;
    }

    // const nt = moment(overWriteTime, 'hh:mm A');
    // const ot = moment(alertDataArray[0], 'hh:mm A');
    // const t = nt.isSameOrAfter(ot);
    
    // if (alertDataArray[5] == "checkout" && !t) {
    //   setOverWriteError("Please Enter A Valid Time, Should be after Check in time")
    //   return;
    // }
    // if (alertDataArray[5] == "checkin" && !t) {
    //   setOverWriteError("Please Enter A Valid Time, Should be after Check in time")
    //   return;
    // }

    let updateObject = {}
    const detailsT = alertDataArray.details.split("-|-")
    updateObject[alertDataArray.type] = overWriteTime
    updateObject[`overwrittenfrom-${alertDataArray.type}`] = alertDataArray.entry

    Firebase.database().ref(`/tracking/timecard/checkincheckouts/${detailsT[0]}/${detailsT[1]}/${detailsT[2]}/${detailsT[3]}/shifthours/${alertDataArray.index}`).update(updateObject)
    overWriteCallback(detailsT[1])
    setOverWriteError(null)
    setOverWriteTime(null)
    setShowAlert(false);
    setAlertDataArray([])
  }

  const {
    tableHead,
    tableData,
    tableHeaderColor,
    hover,
    colorsColls,
    coloredColls,
    customCellClasses,
    customClassesForCells,
    striped,
    tableShopping,
    customHeadCellClasses,
    customHeadClassesForCells,
    openAddress,
    openTeamMember,
    deleteHandleLastcaseCallback,
    overWriteCallback
  } = props;
  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setDurationArray();
  //   },1000);
  // });
   
  const deleteProduct = (prop) => {
    if(prop){
      if(prop.includes("removetheproduct")){
        const pid = prop.split("removetheproduct:")[1].split('-|-')[0]
        const key = prop.split("removetheproduct:")[1].split('-|-')[1]
        if(pid && key){ 
          Firebase.database()
          .ref(`/ecommerce/${key}/products/${pid}`).set(null)
        }
      }
      if(prop.includes("removetheservice")){
        const pid = prop.split("removetheservice:")[1].split('-|-')[0]
        const key = prop.split("removetheservice:")[1].split('-|-')[1]
        if(pid && key){ 
          Firebase.database()
          .ref(`/serviceofferings/${key}/services/${pid}`).set(null)
        }
      }
      
    }
  }
    
  const deleteDeliveryPerson = (prop) => {
    if(prop){
      const a = prop.split("deleteDeliveryPerson:")[1].split('-|-')
      const phone = a[0]
      const store = a[1]
      if(phone && store){ 
        Firebase.database()
        .ref(`/webdeliveryteam/storeadded/${store}/${phone}`).set(null)
      }
    }
  }
  const deleteTeamMember = (prop) => {
    if(prop){
      const a = prop.split("deleteTeamMember:")[1].split('-|-')
      const phone = a[0]
      const store = a[1]
      if(phone && store){
        if(tableData.length == 1) {deleteHandleLastcaseCallback('member')}
        Firebase.database()
          .ref(`/tracking/teams/teammembers/${store}/${phone}`).set(null)
        Firebase.database()
          .ref(`/activeteammembers/${store}/${phone}`)
          .set(null)
      }
    }
  }
  const deleteTimecardTeamMember = (prop) => {
    if (prop) {
      const a = prop.split("timecardDT:")[1].split('-|-')
      const phone = a[0]
      const store = a[1]
      if(phone && store){
        if(tableData.length == 1) {deleteHandleLastcaseCallback('member')}
        Firebase.database()
          .ref(`/tracking/timecard/teammembers/${store}/${phone}`).set(null)
        Firebase.database()
          .ref(`/timecardactiveteammembers/${store}/${phone}`)
          .set(null)
      }
    }
  }
  const deleteTimecardBranch = (prop) => {
    if (prop) {
      const a = prop.split("deleteTimecardBranch:")[1].split('-|-')
      
      if(a[1] && a[0]){
        if(tableData.length == 1) {deleteHandleLastcaseCallback('branch')}
        Firebase.database()
          .ref(`/tracking/timecard/branches/${a[1]}/${a[0]}`).set(null)
      }
    }
  }
  

  const deleteGigjobTeamMember = (prop) => {
    if(prop){
      const a = prop.split("deleteGigjobTeamMember:")[1].split('-|-')
      const phone = a[0]
      const store = a[1]
      if(phone && store){
        if(tableData.length == 1) {deleteHandleLastcaseCallback('member')}
        Firebase.database()
        .ref(`/tracking/gigjobs/teammembers${store}/${phone}`).set(null)
      }
    }
  }

  
  const deleteAddress = (prop) => {
    if(prop){
      const a = prop.split("deleteTeamTrackingPlace:")[1].split('-|-store:')
      const key = a[0]
      const t1 = a[1].split("-|-teammemberphone:")
      const store = t1[0]
      const t2 = t1[1].split("-|-dateadded:")
      const phone = t2[0]
      const date = t2[1]


      if(key && store){
        if(tableData.length == 1) {deleteHandleLastcaseCallback('place')}
        Firebase.database()
          .ref(`/tracking/teams/placelist/${store}/${key}`).set(null)
        Firebase.database()
          .ref(`/scheduledjobs/tracking/${phone}/${date}/${key}`).set(null)
      }
    }
  }

  
  const generateLiveTrackURL = (deliveryPersonNumber, customerPhone) => {
    const ciphertext = encodeURIComponent(`${deliveryPersonNumber},${customerPhone}`);
    return `https://livetracking.onroad.app/?cid=${ciphertext}`;
  }

  const addADelivery = (cart, deliveryPerson) => {
    const ref = Firebase.database()
      .ref(`/webdeliveries/storeadded/${deliveryPerson.email.replace(/\./g, ",")}`)
      .push({
            id: deliveryPerson.email.replace(/\./g, ","),
            address: cart.address,
            latlng: cart.addressLatLng,
            customerphone: cart.phone,
            addresslocation: cart.addressLocation,
            livetrackurl: generateLiveTrackURL(deliveryPerson.phone, cart.phone),
            deliveryguyphone: deliveryPerson.phone,
            deliveryguyname: deliveryPerson.name,
            deliverystatus: "Not Delivered",
            distance: deliveryPerson.distance || "Not Ready Yet",
            cost: "Pricing Not Setup Yet",
            branch: deliveryPerson.branch || "Single Branch",
          })
    Firebase.database()
      .ref(`/scheduledjobs/${deliveryPerson.phone}/${ref.key}`)
      .set({
        storeid: deliveryPerson.email.replace(/\./g, ","),
        orderid: ref.key,
        address: cart.address,
        lat: cart.addressLatLng.lat,
        lng: cart.addressLatLng.lng,
        phone: cart.phone,
        deliverystatus: "Not Delivered"
      })
    Firebase.database()
      .ref(`/webadmin/${deliveryPerson.email.replace(/\./g,",")}/`)
      .once('value', snapshot => {
        let val = snapshot.val()
        snapshot.ref.update({
          totaldeliveries: parseInt(val.totaldeliveries) + 1,
          usedcredits: parseInt(val.usedcredits) + 1
        })
      })

    Firebase.database()
      .ref(
        `/webdeliveryteam/storeadded/${deliveryPerson.email.replace(/\./g, ",")}/${deliveryPerson.phone}/`,
      )
      .once('value', snapshot => {
        let val = snapshot.val(); 
        let obj = {}
        if(val){
          if(val.scheduleddeliveryjobs){
            obj["scheduleddeliveryjobs"] = parseInt(val.scheduleddeliveryjobs) + 1
          }
          if(val.lastupdated){
            const isSameDay = moment(val.lastupdated).isSame(moment().format("YYYY/MM/DD"));
            if(isSameDay){
              obj["todaysdeliveries"] =  parseInt(val.scheduleddeliveryjobs) + 1
            }else{
              obj["lastupdated"] = moment().format("YYYY/MM/DD")
              obj["todaysdeliveries"] = 1
            }
          }
          snapshot.ref.update(obj);
        }
      });
  }

  const instantLaundryScheduleToDeliveryPerson = (order, deliveryPerson) => {

    const ref = Firebase.database()
    .ref(`/webdeliveries/storeadded/${deliveryPerson.email.replace(/\./g, ",")}`)
    .push({
          id: deliveryPerson.email.replace(/\./g, ","),
          address: order.address,
          latlng: order.addressLatLng,
          customerphone: order.phone,
          addresslocation: order.addressLocation,
          livetrackurl: generateLiveTrackURL(deliveryPerson.phone, order.phone),
          deliveryguyphone: deliveryPerson.phone,
          deliveryguyname: deliveryPerson.name,
          deliverystatus: "Not Delivered",
          distance: deliveryPerson.distance || "Not Ready Yet",
          cost: "Pricing Not Setup Yet",
          branch: deliveryPerson.branch || "Single Branch",
        })
      Firebase.database()
        .ref(`/scheduledjobs/${deliveryPerson.phone}/${ref.key}`)
        .set({
          storeid: deliveryPerson.email.replace(/\./g, ","),
          orderid: ref.key,
          address: order.address,
          lat: parseFloat(order.addressLatLng.lat),
          lng: parseFloat(order.addressLatLng.lng),
          phone: `+91${order.phone}`,
          routeorder: 1,
          deliverystatus: "Not Delivered"
        })
      // Firebase.database()
      //   .ref(`/webadmin/${deliveryPerson.email.replace(/\./g,",")}/`)
      //   .once('value', snapshot => {
      //     let val = snapshot.val()
      //     snapshot.ref.update({
      //       totaldeliveries: parseInt(val.totaldeliveries) + 1,
      //       usedcredits: parseInt(val.usedcredits) + 1
      //     })
      //   })

      // Firebase.database()
      //   .ref(
      //     `/webdeliveryteam/storeadded/${deliveryPerson.email.replace(/\./g, ",")}/${deliveryPerson.phone}/`,
      //   )
      //   .once('value', snapshot => {
      //     let val = snapshot.val(); 
      //     let obj = {}
      //     if(val){
      //       if(val.scheduleddeliveryjobs){
      //         obj["scheduleddeliveryjobs"] = parseInt(val.scheduleddeliveryjobs) + 1
      //       }
      //       if(val.lastupdated){
      //         const isSameDay = moment(val.lastupdated).isSame(moment().format("YYYY/MM/DD"));
      //         if(isSameDay){
      //           obj["todaysdeliveries"] =  parseInt(val.scheduleddeliveryjobs) + 1
      //         }else{
      //           obj["lastupdated"] = moment().format("YYYY/MM/DD")
      //           obj["todaysdeliveries"] = 1
      //         }
      //       }
      //       snapshot.ref.update(obj);
      //     }
      //   });
  }

  const instantLaundryScheduleToStore = (order, storeUiniqId) => {
    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss")
    Firebase.database()
    .ref(`/storeinstantlaundry/orders/${storeUiniqId}/${order.orderid}`).set({
      time: currentTime,
      orderid: order.orderid,
      name: order.name,
      phone: order.phone,
      address:  order.address,
      addressLatLng:  order.addressLatLng || "",
      addressLocation:  order.addressLocation || "",
      service:  order.service,
      payment:  "",
      branchname: "",
      selectedbranch: ""
    })
  }
  const scheduleToDeliveryPeson = (prop) => {
    if(prop){
      const orderId = prop.split("schedulebutton:")[1].split(":deliverypersonphone:")[0]
      const apikey = prop.split(":apikey:")[1].split(":storetype:")[0]
      const bizType = prop.split(":storetype:")[1].split(":distance:")[0]
      if(orderId && (bizType == 'Store' || bizType == 'Restaurant')){
        Firebase.database()
        .ref(`/storeorders/${apikey}/${orderId}`)
        .once('value', snapshot => {
          let val = snapshot.val()
          if(val) {
            const phone = prop.split(":deliverypersonphone:")[1].split(":deliverypersonname:")[0]
            const name = prop.split(":deliverypersonname:")[1].split(":useremail:")[0]
            const email = prop.split(":useremail:")[1].split(":apikey:")[0]
            let distance;
            let branch; 
            if(prop.includes(":branchname:")){
              distance = prop.split(":distance:")[1].split(":branchname:")[0]
              branch = prop.split(":distance:")[1].split(":branchname:")[1]
            }else {
              distance = prop.split(":distance:")[1]
            }
            addADelivery(val, {name: name, phone: phone, email: email, distance: distance, branch: branch})
            snapshot.ref.update({
              scheduled: true
            })
          }
          
      })
      }
      if(orderId && bizType == 'Laundry'){
        Firebase.database()
        .ref(`/laundryorders/${apikey}/${orderId}`)
        .once('value', snapshot => {
          let val = snapshot.val()
          if(val) {
            const phone = prop.split(":deliverypersonphone:")[1].split(":deliverypersonname:")[0]
            const name = prop.split(":deliverypersonname:")[1].split(":useremail:")[0]
            const email = prop.split(":useremail:")[1].split(":apikey:")[0]
            let distance;
            let branch; 
            if(prop.includes(":branchname:")){
              distance = prop.split(":distance:")[1].split(":branchname:")[0]
              branch = prop.split(":distance:")[1].split(":branchname:")[1]
            }else {
              distance = prop.split(":distance:")[1]
            }
            addADelivery(val, {name: name, phone: phone, email: email, distance: distance, branch: branch})
            snapshot.ref.update({
              scheduled: true
            })
          }   
        })
      }
    }
  }

  const finishStoreInstantLaundryOrder = (prop) => {
    if(prop){
      const orderId = prop.split("storeinstantlaundrybutton:")[1].split(":apikey:")[0]
      const apikey = prop.split(":apikey:")[1]
      if(orderId && apikey){
        const currentTime = moment().format("YYYY-MM-DD HH:mm:ss")
        Firebase.database()
        .ref(`/storeinstantlaundry/orders/${apikey}/${orderId}`)        
        .once('value', snapshot => {
          let val = snapshot.val()
          if(val) {
            snapshot.ref.update({
              done: true,
              finishedtime: currentTime
            })
          }
        })
        Firebase.database()
        .ref(`/instantlaundry/orders/${orderId}/storeworkstatus`).set("Store Finished")
      }
    }
  }
  const finishTableOrder = (prop) => {
    if(prop){
      const orderId = prop.split("tableservicebutton:")[1].split(":apikey:")[0]
      const apikey = prop.split(":apikey:")[1]
      
      if(orderId && apikey){
        const currentTime = moment().format("YYYY-MM-DD HH:mm:ss")
        Firebase.database()
        .ref(`/tableorders/${apikey}/${orderId}`)
        .once('value', snapshot => {
          let val = snapshot.val()
          if(val) {
            snapshot.ref.update({
              done: true,
              finishedtime: currentTime
            })
          }
        })
      }
    }
  }
  const updateStock = (stock, prop) => {
    if(prop){
      if(prop.includes("stockonoff:")){
        const s =  stock == true ? "yes" : "no"
        const pid = prop.split(":productid:")[1].split(':storekey:')[0]
        const key = prop.split(":storekey:")[1]
        if(pid && key){ 
          Firebase.database()
          .ref(`/ecommerce/${key}/products/${pid}`)
          .once('value', snapshot => {
            snapshot.ref.update({
              stock: s
            })
          })
        }
      }  
    }
  }
  
   
  const updateTimecardTeammemberStatus = (status, temp) => {
    if (temp && temp.length > 0) {
      if (status == false) {
        Firebase.database()
        .ref(`/timecardactiveteammembers/${temp[2]}/${temp[1]}`)
        .set(null)
      }
      if (status == true) {
        Firebase.database()
        .ref(`/timecardactiveteammembers/${temp[2]}/${temp[1]}`)
        .set(temp[3])
      }
      
      
      Firebase.database()
        .ref(`/tracking/timecard/teammembers/${temp[2]}/${temp[1]}`)
        .once('value', snapshot => {
          snapshot.ref.update({
            status: status
          })
        })
      }
  }
  const updateTeammemberStatus = (status, temp) => {
    if (temp && temp.length > 0) {
      if (status == false) {
        Firebase.database()
        .ref(`/activeteammembers/${temp[2]}/${temp[1]}`)
        .set(null)
      }
      if (status == true) {
        Firebase.database()
        .ref(`/activeteammembers/${temp[2]}/${temp[1]}`)
        .set(temp[3])
      }
      
      
      Firebase.database()
        .ref(`/tracking/teams/teammembers/${temp[2]}/${temp[1]}`)
        .once('value', snapshot => {
          snapshot.ref.update({
            status: status
          })
        })
      }
  }

  function DateTimeInput(props) {
    return (
      <Datetime
        value={props.value}
        onChange={props.onChange}
        inputProps={{ placeholder: "Select Date and Time", readOnly: true }}
        dateFormat="MMM DD, YYYY"
        timeFormat="hh:mm A"
      />
    );
  }

     // let productID = ""
    // let productNameOrPrice = ""
    // if(prop.includes("|name-")){
    //   productID = prop.split("input:")[1].split("|name-")[0]
    //   productNameOrPrice = prop.split("input:")[1].split("|name-")[1]
    // }
    // if(prop.includes("|price-")){
    //   productID = prop.split("input:")[1].split("|price-")[0]
    //   productNameOrPrice = prop.split("input:")[1].split("|price-")[1]
    // }
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor]}>
            <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
              {tableHead.map((prop, key) => {
                const tableCellClasses =
                  classes.tableHeadCell +
                  " " +
                  classes.tableCell +
                  " " +
                  cx({
                    [customHeadCellClasses[
                      customHeadClassesForCells.indexOf(key)
                    ]]: customHeadClassesForCells.indexOf(key) !== -1,
                    [classes.tableShoppingHead]: tableShopping,
                    [classes.tableHeadFontSize]: !tableShopping,
                  });
                return (
                  <TableCell className={tableCellClasses} key={key}>
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            var rowColor = "";
            var rowColored = false;
            if (prop.color !== undefined) {
              rowColor = prop.color;
              rowColored = true;
              prop = prop.data;
            }
            const tableRowClasses = cx({
              [classes.tableRowBody]: true,
              [classes.tableRowHover]: hover,
              [classes[rowColor + "Row"]]: rowColored,
              [classes.tableStripedRow]: striped && key % 2 === 0,
            });
            if (prop.total) {
              return (
                <TableRow key={key} hover={hover} className={tableRowClasses}>
                  <TableCell
                    className={classes.tableCell}
                    colSpan={prop.colspan}
                  />
                  <TableCell
                    className={classes.tableCell + " " + classes.tableCellTotal}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    className={
                      classes.tableCell + " " + classes.tableCellAmount
                    }
                  >
                    {prop.amount}
                  </TableCell>
                  {tableHead.length - (prop.colspan - 0 + 2) > 0 ? (
                    <TableCell
                      className={classes.tableCell}
                      colSpan={tableHead.length - (prop.colspan - 0 + 2)}
                    />
                  ) : null}
                </TableRow>
              );
            }
            if (prop.purchase) {
              return (
                <TableRow key={key} hover={hover} className={tableRowClasses}>
                  <TableCell
                    className={classes.tableCell}
                    colSpan={prop.colspan}
                  />
                  <TableCell
                    className={classes.tableCell + " " + classes.right}
                    colSpan={prop.col.colspan}
                  >
                    {prop.col.text}
                  </TableCell>
                </TableRow>
              );
            }
            return (
              <TableRow
                key={key}
                hover={hover}
                className={classes.tableRow + " " + tableRowClasses}
              >
                {prop.map((prop, key) => {
                  const tableCellClasses =
                    classes.tableCell +
                    " " +
                    cx({
                      [classes[colorsColls[coloredColls.indexOf(key)]]]:
                        coloredColls.indexOf(key) !== -1,
                      [customCellClasses[customClassesForCells.indexOf(key)]]:
                        customClassesForCells.indexOf(key) !== -1,
                    });
                    if (typeof (prop) === "string" && prop.includes("storage.googleapis.com")){
                      return (
                          <TableCell className={tableCellClasses} key={key}
                            target="_blank"
                            component={prop.includes("storage.googleapis.com") ? "a" : null}
                            href={prop.includes("storage.googleapis.com") ? prop : null}>
                            {prop}
                          </TableCell>
                        );
                    }
                    if(typeof (prop) === "string" && prop.includes("orderaddress:")){
                      const temp = prop.split("orderaddress:")[1].split(":distance:")
                      let temp2;
                      if(prop.includes(":branchname:")){
                        temp2 = temp[1].split(":branchname:")
                      }
                      return (
                          <TableCell className={tableCellClasses} key={key} style={{width: "400px"}}>
                            {temp[0]}  
                            <br/>
                            {
                              temp2 ? 
                                (<>Distance: {temp2[0]} <br/>
                                   Branch: {temp2[1].split(",deliverymethod")[0]}
                                   <br/>
                                   <b>Payment Method: {temp2[1].split(",deliverymethod")[1]}</b>
                                  </>) 
                                : 
                                (<>
                                  Distance: {temp[1].split(",deliverymethod")[0]}
                                  <br />
                                  {/* <b>Delivery Method: {temp[1].split(",deliverymethod")[1]}</b> */}
                                </>)
                            }
                            
                          </TableCell>
                        );
                    }
                    if (typeof (prop) === "string" && prop.includes("input")){
                      // let productID = ""
                      let productNameOrPrice = ""
                      let sku = ""
                      if(prop.includes("|name-")){
                        // productID = prop.split("input:")[1].split("|name-")[0]
                        const a = prop.split("input:")[1].split("|name-")[1].split("-|-sku:")
                        productNameOrPrice = a[0]
                        sku = a[1]
                      }
                      if(prop.includes("|price-")){
                        // productID = prop.split("input:")[1].split("|price-")[0]
                        productNameOrPrice = prop.split("input:")[1].split("|price-")[1]
                      }
                      // return (
                      //     <TableCell className={tableCellClasses} key={key}>
                      //       {/* {prop} */}
                      //       {editInput ? (
                      //         <Input value={productNameOrPrice} onBlur={(val) => {setEditInput(false); setProductNameOrPrice(val)}}></Input>
                      //         ) : (<p>{productNameOrPrice}</p>)}
                      //     </TableCell>
                      //   );
                      return (
                        <TableCell className={tableCellClasses} key={key}>
                          <p>{productNameOrPrice}</p>
                          {sku && (<small style={{color: "gray"}}>SKU: {sku}</small>)}
                        </TableCell>
                      );
                    }
                    if (typeof (prop) === "string" && (prop.includes("removetheproduct") || prop.includes("removetheservice"))){
                      return (
                          <TableCell className={tableCellClasses} key={key}>
                            <Button simple className={classes.actionButton} style={{color: "red"}} onClick={() => deleteProduct(prop)}>
                              <CloseIcon/><small style={{color: "gray"}}>Delete</small>
                            </Button>
                          </TableCell>
                        );
                    }
                    if (typeof (prop) === "string" && prop.includes("deleteDeliveryPerson")){
                      return (
                          <TableCell className={tableCellClasses} key={key}>
                            <Button simple className={classes.actionButton} style={{color: "red"}} onClick={() => deleteDeliveryPerson(prop)}>
                              <CloseIcon/><small style={{color: "gray"}}>Delete</small>
                            </Button>
                          </TableCell>
                        );
                    }
                    if (typeof (prop) === "string" && prop.includes("deleteTeamMember")){
                      return (
                          <TableCell className={tableCellClasses} key={key}>
                            <Button simple className={classes.actionButton} style={{color: "red"}} onClick={() => deleteTeamMember(prop)}>
                              <CloseIcon/><small style={{color: "gray"}}>Delete</small>
                            </Button>
                          </TableCell>
                        );
                    }
                    if (typeof (prop) === "string" && prop.includes("timecardDT")){
                      return (
                          <TableCell className={tableCellClasses} key={key}>
                            <Button simple className={classes.actionButton} style={{color: "red"}} onClick={() => deleteTimecardTeamMember(prop)}>
                              <CloseIcon/><small style={{color: "gray"}}>Delete</small>
                            </Button>
                          </TableCell>
                        );
                    }
                    if (typeof (prop) === "string" && prop.includes("deleteTimecardBranch")){
                      return (
                          <TableCell className={tableCellClasses} key={key}>
                            <Button simple className={classes.actionButton} style={{color: "red"}} onClick={() => deleteTimecardBranch(prop)}>
                              <CloseIcon/><small style={{color: "gray"}}>Delete</small>
                            </Button>
                          </TableCell>
                        );
                    }
                  
                    
                    if (typeof (prop) === "string" && prop.includes("deleteGigjobTeamMember")){
                      return (
                          <TableCell className={tableCellClasses} key={key}>
                            <Button simple className={classes.actionButton} style={{color: "red"}} onClick={() => deleteGigjobTeamMember(prop)}>
                              <CloseIcon/><small style={{color: "gray"}}>Delete</small>
                            </Button>
                          </TableCell>
                        );
                    }
                    
                    if (typeof (prop) === "string" && prop.includes("deleteTeamTrackingPlace")){
                      return (
                          <TableCell className={tableCellClasses} key={key}>
                            <Button simple className={classes.actionButton} style={{color: "red"}} onClick={() => deleteAddress(prop)}>
                              <CloseIcon/><small style={{color: "gray"}}>Delete</small>
                            </Button>
                          </TableCell>
                        );
                    }
                    if (typeof (prop) === "string" && prop.includes("https://order.onroad.app/")){
                      const a = prop.split("-|-")
                      const products = a[0].split("<>")
                      const link = a[1]
                      return (
                          <TableCell className={tableCellClasses} key={key} style={{width: "400px"}}>
                            <ol style={{color: "gray"}}>
                              {products && products.length > 0 ? products.map(p => {
                                return (<li>{p}</li>)
                              }): null}
                              <a href={link} target="_blank" style={{textAlign: "center"}}>View Cart({products.length})</a>
                            </ol>
                            
                            {/* <br/>
                            <div style={{marginLeft: "20%"}}>
                              <a href={link} target="_blank" style={{textAlign: "center"}}>View Cart({products.length})</a>
                            </div> */}

                          </TableCell>
                        );
                    }

                    if (typeof (prop) === "string" && prop.includes("table:")){
                      let a = prop.split("table:")
                      let table = a[1]
                      let name;
                      if(a[1].includes(":name:")){
                        table = a[1].split(":name:")[0]
                        name = a[1].split(":name:")[1]
                      }
                      return (
                          <TableCell className={tableCellClasses} key={key}>
                            <p>Table: {table}</p>
                            {name && (<p>Name: {name}</p>)}
                          </TableCell>
                        );
                    }

                    if (typeof (prop) === "string" && prop.includes("https://table.onroad.app/")){
                      const a = prop.split("-|-")
                      const products = a[0].split("<>")
                      const link = a[1]
                      return (
                          <TableCell className={tableCellClasses} key={key}>
                            <ol style={{color: "gray"}}>
                              {products && products.length > 0 ? products.map(p => {
                                return (<li>{p}</li>)
                              }): null}
                                <a href={link} target="_blank" style={{textAlign: "center"}}>View Cart({products.length})</a>
                            </ol>
                            
                            {/* <br/>
                            <div style={{marginLeft: "18%"}}>
                              <a href={link} target="_blank" style={{textAlign: "center"}}>View Cart({products.length})</a>
                            </div> */}

                          </TableCell>
                        );
                    }

                    if (typeof (prop) === "string" && prop.includes("starttime:")){
                      let startTime;
                      let endTime;
                      if (prop.includes(":endtime:")){
                        const a = prop.split("starttime:")
                        const b = a[1].split(":endtime:")
                        startTime = moment(b[0])
                        endTime = moment(b[1])
                      } else {
                        startTime = moment(prop.split("starttime:")[1])
                      }

                      return (
                          <TableCell className={tableCellClasses} key={key}>
                            <p style={{textTransform: "capitalize"}}>{endTime ? `${(moment.duration(endTime.diff(startTime)).asMinutes()).toFixed()} Minutes` : `${moment(startTime).fromNow(true)}`}</p>
                          </TableCell>
                        );
                    }

                    if (typeof (prop) === "string" && prop.includes("tableservicebutton")){
                      return (
                          <TableCell className={tableCellClasses} key={key}>

                            {prop.includes("tableservicebutton:done") ? 
                              (<p>Finished</p>) :
                              (<Button small={true} color="primary" onClick={() => finishTableOrder(prop)}>
                                <RoomIcon/>Done
                              </Button>)
                            }
                          </TableCell>
                        );
                    }
                    if (typeof (prop) === "string" && prop.includes("storeinstantlaundrybutton")){
                      return (
                          <TableCell className={tableCellClasses} key={key}>

                            {prop.includes("storeinstantlaundrybutton:done") ? 
                              (<p>Finished</p>) :
                              (<Button small={true} color="primary" onClick={() => finishStoreInstantLaundryOrder(prop)}>
                                <RoomIcon/>Done
                              </Button>)
                            }
                          </TableCell>
                        );
                    }

                    if (typeof (prop) === "string" && prop.includes("schedulebutton")){
                      return (
                          <TableCell className={tableCellClasses} key={key}>

                            {prop.includes("schedulebutton:done") ? 
                              (<p>Scheduled</p>) :
                              (prop.includes("schedulebutton:notrequired") ? (<b>Store Pickup</b>) : 
                              (<Button small={true} color="primary" onClick={() => scheduleToDeliveryPeson(prop)}>
                                <RoomIcon/>Schedule For Delivery
                              </Button>)
                              )
                            }
                          </TableCell>
                        );
                    }
                    if (typeof (prop) === "string" && prop.includes("address-|-")){
                      const temp = prop.split("address-|-")[1].split("-|-key-|-")
                      const latlng = temp[1].split("-|-latlng-|-")
                      return (
                          <TableCell className={tableCellClasses} key={key} style={{padding: "0px!important"}}>
                            <Button small={true} color="transparent" style={{color: "blue", padding: "0px", margin: "0px"}}
                                  onClick={() => openAddress([temp[0],latlng[0],latlng[1]])}>
                                {temp[0]}
                              </Button>
                          </TableCell>
                        );
                    }
                    if (typeof (prop) === "string" && prop.includes("name-|-")){
                     
                      const name = prop.split("name-|-")[1].split("-|-key-|-")[0]
                      const phone = prop.split("-|-key-|-")[1].split("-|-status-|-")[0]
                      const status = prop.split("-|-status-|-")[1].split("-|-scheduledassignments-|-")[0]
                      const schedulejobs = prop.split("-|-scheduledassignments-|-")[1]
                      return (
                          <TableCell className={tableCellClasses} key={key} style={{padding: "0px!important"}}>
                            <Button small={true} color="transparent" style={{color: status == 'true' ? 'blue':  "gray", padding: "0px"}}
                            onClick={() => openTeamMember([name, phone, parseInt(schedulejobs)|| 0])}
                            disabled={status == 'true' ? false : true}
                            >
                                {name}
                              </Button>
                          </TableCell>
                        );
                    }
                  
                    if (typeof (prop) === "string" && prop.includes("teammemberstatus:")){
                      
                      const temp = prop.split("teammemberstatus:")[1].split("-|-")
                      const isActive = temp[0] == "true" ? true : false
                      return (
                          <TableCell className={tableCellClasses} key={key}>
                               <Switch
                                  checked={isActive}
                                  onChange={(event) => updateTeammemberStatus(event.target.checked, temp)}
                                  value={isActive}
                                  classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                  }}
                                />
                                <small style={{color: "gray"}}>{isActive ? "Active" : "Not Active"}</small>

                          </TableCell>
                        );
                    }
                    if (typeof (prop) === "string" && prop.includes("timecardSC:")){
                      const temp = prop.split("timecardSC:")[1].split("-|-")
                      const isActive = temp[0] == "true" ? true : false
                      return (
                          <TableCell className={tableCellClasses} key={key}>
                               <Switch
                                  checked={isActive}
                                  onChange={(event) => updateTimecardTeammemberStatus(event.target.checked, temp)}
                                  value={isActive}
                                  classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                  }}
                                />
                                <small style={{color: "gray"}}>{isActive ? "Active" : "Not Active"}</small>

                          </TableCell>
                        );
                    }
                  
                    if (typeof (prop) === "string" && prop.includes("checkinurl:")){
                      const link = prop.split("checkinurl:")[1]
                      return (
                          <TableCell className={tableCellClasses} key={key}>
                          <a href={link} target="_blank" style={{ textAlign: "center" }}>{link}</a>
                          </TableCell>
                        );
                    }
                    // if (typeof (prop) === "string" && prop.includes("check-in-out-column:")){
                    //   const data = prop.split("check-in-out-column:")[1]
                    //   const dataArray = data.split("-|-")
                    //   // setOverWriteTime(moment(data, "hh:mm A"))
                    //   return (
                    //     <TableCell className={tableCellClasses} key={key}>
                    //      <>
                    //         <Button small={true} color="transparent" style={{ padding: "0px", margin: "0px", fontSize: "15px", color: "#585757" }}
                    //           onClick={() => { handleShowAlert(dataArray) }}>
                    //             {dataArray[0]}
                    //           </Button>
                    //           {showAlert && (
                    //           <SweetAlert
                    //             showCancel                                
                    //             // onConfirm={(e) => {
                    //             //   inputConfirmAlertNext(e);
                    //             // }}
                    //             // onCancel={() => hideAlert()}
                    //             onConfirm={() => { handleConfirmAlert() }}
                    //             onCancel={() => { handleHideAlert() }}
                    //             confirmBtnCssClass={classes2.button + " " + classes2.info}
                    //             cancelBtnCssClass={classes2.button + " " + classes2.danger}
                    //             title="Overwrite Recorded Time"
                    //             style={{display: "block", marginTop: "-100px", overflow: "visible"}}
                    //           >
                    //             Recorded Time: {alertDataArray[0]}
                    //             <br />
                    //             <br />
                    //             <Datetime
                    //               style={{ paddingTop: "20px", width: "80%" }}
                    //               dateFormat={false}
                    //               inputProps={{ placeholder: "Pick New Time" }}
                    //               // value={overWriteTime || data}
                    //               onChange={handleOverWriteTime}
                    //             />
                                
                    //             <span style={{color: "gray", fontSize: "14px"}}>Please make sure you note what you are updating, it cannot be reverted</span>
                    //             <br />
                    //             <span>&nbsp;</span>
                    //             <br />
                    //             <span style={{color: "red", paddingTop: "20px"}}>{overWriteError} </span>
                                
                    //               </SweetAlert>
                    //           )}
                    //         </>
                    //       </TableCell>
                    //     );
                    // }
                  
                    if (typeof (prop) === "string" && prop.includes("shifthours:")){
                      const data = prop.split("shifthours:")[1]
                      const t = data.split("details:")
                      const details = t[1]
                      const dataArray = t[0].split("entry:").slice(1)
                      // setOverWriteTime(moment(data, "hh:mm A"))
                      return (
                        <TableCell className={tableCellClasses} key={key}>
                          <>
                            {dataArray?.map((entry, index) => (<>
                              <Button small={true} color="transparent" style={{ padding: "0px", margin: "0px", fontSize: "15px", color: "#585757" }}
                                onClick={() => { handleShowAlert({ entry: entry.split(" - ")[0].trim(), index: index, details: details, type: 'checkin' }) }}>
                                {entry.split("-")[0]} <span style={{paddingLeft: "10px", paddingRight: "10px"}}>{" - "}</span> 
                              </Button>
                             
                              <Button small={true} color="transparent" style={{ padding: "0px", margin: "0px", fontSize: "15px", color: "#585757" }}
                              onClick={() => { handleShowAlert({ entry: entry.split(" - ")[1].trim(), index: index, details: details, type: 'checkout' }) }}>
                                 {entry.split("-")[1]}
                              </Button>
                              <br />
                            </>)
                              )}
                           
                              {showAlert && (
                              <SweetAlert
                                showCancel                                
                                // onConfirm={(e) => {
                                //   inputConfirmAlertNext(e);
                                // }}
                                // onCancel={() => hideAlert()}
                                onConfirm={() => { handleConfirmAlert() }}
                                onCancel={() => { handleHideAlert() }}
                                confirmBtnCssClass={classes2.button + " " + classes2.info}
                                cancelBtnCssClass={classes2.button + " " + classes2.danger}
                                title="Overwrite Recorded Time"
                                style={{display: "block", marginTop: "-100px", overflow: "visible"}}
                              >
                                Recorded Time: {alertDataArray.entry}
                                <br />
                                <br />
                                <Datetime
                                  style={{ paddingTop: "20px", width: "80%" }}
                                  dateFormat={false}
                                  inputProps={{ placeholder: "Pick New Time" }}
                                  // value={overWriteTime || data}
                                  onChange={handleOverWriteTime}
                                />
                                
                                <span style={{color: "gray", fontSize: "14px"}}>Please make sure you note what you are updating, it cannot be reverted</span>
                                <br />
                                <span>&nbsp;</span>
                                <br />
                                <span style={{color: "red", paddingTop: "20px"}}>{overWriteError} </span>
                                
                              </SweetAlert>
                              )}
                            </>
                          </TableCell>
                        );
                    }
                  
                    if (typeof (prop) === "string" && prop.includes("timecardpassword:")){
                      const data = prop.split("timecardpassword:")[1]
                      const pwdEmailPhone = data.split("-|-")                     

                      return (
                        <TableCell className={tableCellClasses} key={key}>
                          {pwdEmailPhone[0]}
                          <Button small={true} style={{ padding: "3px", left: "20px", background: "#f50057" }}
                            onClick={() => {
                              Firebase.database()
                                .ref(`/tracking/timecard/teammembers/${pwdEmailPhone[2]}/${pwdEmailPhone[1]}/password`)
                                .set(pwdEmailPhone[3])
                            }}
                              >
                                Reset
                              </Button>
                          </TableCell>
                        );
                    }

                    if (typeof (prop) === "string" && prop.includes("stockonoff")){
                      const stock = prop.split("stockonoff:")[1].split(':productid:')[0] == "no" ? false : true
                      return (
                          <TableCell className={tableCellClasses} key={key}>
                               <Switch
                                  checked={stock}
                                  onChange={(event) => updateStock(event.target.checked, prop)}
                                  value={stock}
                                  classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                  }}
                                />
                                <small style={{color: "gray"}}>{stock ? "In Stock" : "Out Of Stock"}</small>

                          </TableCell>
                        );
                    }

                    if (typeof (prop) === "string" && prop.includes("instantlaundryordertime:")){
                      const time = prop.split("instantlaundryordertime:")[1]
                      
                      return (
                        <TableCell className={tableCellClasses} key={key}>
                          <p style={{textTransform: "capitalize"}}>{`${moment(time).fromNow(true)}`}</p>
                        </TableCell>
                      );
                    }


                    // if (typeof (prop) === "string" && prop.includes("instantlaundrydeliverybutton")){
                    //   return (
                    //       <TableCell className={tableCellClasses} key={key}>
                    //           {prop.includes("instantlaundrydeliverybutton:done") ? 
                    //             (<p>{prop.split(":instantlaundrydeliverybutton:done")[0]}</p>) :
                    //             (
                    //             <CustomDropdown
                    //               dropup
                    //               onClick={(val) => {
                    //                 const currentTime = moment().format("YYYY-MM-DD HH:mm:ss")

                    //                 const dname = val;
                    //                let deliveryPersonPhone = ""
                    //                 const orderId = prop.split(":id:")[1]

                    //                 if(val == 'Ajay'){
                    //                   deliveryPersonPhone = "+917760596408"
                    //                 }
                    //                 if(val == 'Dushyant'){
                    //                   deliveryPersonPhone = "+919620478200"
                    //                 }                                   
                    //                 Firebase.database()
                    //                 .ref(`/instantlaundry/orders/${orderId}`)
                    //                 .once('value', snapshot => {
                    //                   let val = snapshot.val()
                    //                   if(val) {
                    //                     snapshot.ref.update({
                    //                       deliverypersonname: dname,
                    //                       deliverypersonphone: deliveryPersonPhone,
                    //                       assignedtime: currentTime
                    //                     })
                    //                   }
                    //                 })
                    //                 const deliveryPerson = {
                    //                   phone: deliveryPersonPhone,
                    //                   name: dname,
                    //                   email: "instantlaundryonroad@gmail.com"
                    //                 }
                    //                 const latlng = prop.split(":addresslatlng:")[1].split(":addresslocation:")[0].split(",")
                    //                 const order = {
                    //                   address: prop.split(":address:")[1].split(":addresslatlng:")[0],
                    //                   addressLatLng: {lat: latlng[0], lng: latlng[1]},
                    //                   phone:  prop.split("phone:")[1].split(":address:")[0],
                    //                   addressLocation: prop.split(":addresslocation:")[1].split(":instantlaundrydeliverybutton:")[0]
                    //                 }

                    //                 instantLaundryScheduleToDeliveryPerson(order, deliveryPerson)

                    //               }}
                    //               buttonText="Pick Delivery Person"
                    //               hoverColor="info"
                    //               dropPlacement="top"
                    //               buttonProps={{
                    //                 round: true,
                    //                 fullWidth: true,
                    //                 style: { marginBottom: "0"},
                    //                 color: "info",
                    //                 width: "50%"
                    //               }}
                    //               style={{width: "80%", paddingLeft: "20px"}}
                    //               dropdownList={["Dushyant", "Ajay"]}
                    //             />
                    //             )
                    //           }
                    //       </TableCell>
                    //     );
                    // }

                    // if (typeof (prop) === "string" && prop.includes("instantlaundrysstorepickbutton")){
                    //   return (
                    //     <TableCell className={tableCellClasses} key={key}>
                    //       {prop.includes("instantlaundrysstorepickbutton:done") ? 
                    //         (<p>{prop.split(":instantlaundrysstorepickbutton:done")[0]}</p>) :
                    //         (
                    //         <CustomDropdown
                    //           dropup
                    //           onClick={(val) => {
                    //                 const sname  = val
                    //                 let assignedStoreID = ""
                    //                 const orderId = prop.split(":id:")[1]
                    //                 if(val == 'Victoria Fabric'){
                    //                   assignedStoreID = "victoriyalaundry@gmail,com"
                    //                 }
                                    
                    //                 const currentTime = moment().format("YYYY-MM-DD HH:mm:ss")
                    //                 Firebase.database()
                    //                 .ref(`/instantlaundry/orders/${orderId}`)
                    //                 .once('value', snapshot => {
                    //                   let val = snapshot.val()
                    //                   if(val) {
                    //                     snapshot.ref.update({
                    //                       storename: sname,
                    //                       storeemail: assignedStoreID,
                    //                       assignedtime: currentTime
                    //                     })
                    //                   }
                    //                 })
                    //                 const latlng = prop.split(":addresslatlng:")[1].split(":addresslocation:")[0].split(",")

                    //                 const order = {
                    //                   name: prop.split("name:")[1].split(":phone:")[0],
                    //                   address: prop.split(":address:")[1].split(":addresslatlng:")[0],
                    //                   addressLatLng: {lat: latlng[0], lng: latlng[1]},
                    //                   phone:  prop.split("phone:")[1].split(":address:")[0],
                    //                   addressLocation: prop.split(":addresslocation:")[1].split(":service:")[0],
                    //                   service: prop.split(":service:")[1].split(":instantlaundrysstorepickbutton:")[0],
                    //                   orderid: prop.split(":id:")[1]
                    //                 }
                    //                 instantLaundryScheduleToStore(order, "2hYObTgSgMZhYisAyPxaSIBF81kFZfKN3HloqwS1ShWpSw=")
                    //           }}
                    //           buttonText="Pick Store"
                    //           hoverColor="info"
                    //           dropPlacement="top"
                    //           buttonProps={{
                    //             round: true,
                    //             fullWidth: true,
                    //             style: { marginBottom: "0"},
                    //             color: "info",
                    //             width: "50%"
                    //           }}
                    //           style={{width: "80%", paddingLeft: "20px"}}
                    //           dropdownList={["Victoria Fabric"]}
                    //         />
                    //         )
                    //       }
                    //     </TableCell>
                    //     );
                    // }
                    
                  return (
                    <TableCell className={tableCellClasses} key={key}>
                      {prop}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
  hover: false,
  colorsColls: [],
  coloredColls: [],
  striped: false,
  customCellClasses: [],
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: [],
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  // Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
  tableData: PropTypes.array,
  hover: PropTypes.bool,
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  // Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
  colorsColls: PropTypes.array,
  customCellClasses: PropTypes.arrayOf(PropTypes.string),
  customClassesForCells: PropTypes.arrayOf(PropTypes.number),
  customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  striped: PropTypes.bool,
  // this will cause some changes in font
  tableShopping: PropTypes.bool,
  openAddress: PropTypes.func,
  openTeamMember: PropTypes.func,
  deleteHandleLastcaseCallback: PropTypes.func
};
