
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import React, {useState, useEffect} from "react";
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components

// @material-ui/icons
// import InfoOutline from "@material-ui/icons/InfoOutline";
import GroupIcon from '@material-ui/icons/Group';
import LocationOnIcon from '@material-ui/icons/LocationOn';
// core components
import Table from "components/Table/Table.js";
import moment from "moment";
import Phone from "@material-ui/icons/Phone";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import LaunchIcon from '@material-ui/icons/Launch';
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
import avatar from "assets/img/faces/marc.jpg";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import Accordion from "components/Accordion/Accordion.js";
import Switch from "@material-ui/core/Switch";
import SweetAlert from "react-bootstrap-sweetalert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import AddAlertIcon from '@material-ui/icons/AddAlert';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import StorefrontIcon from '@material-ui/icons/Storefront';
import TimerIcon from '@material-ui/icons/Timer';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import { cardTitle } from "assets/jss/material-dashboard-pro-js";
// core components
import Heading from "components/Heading/Heading.js";
import Firebase from "firebase";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Person";
import EditIcon from '@material-ui/icons/Edit';
const SMBase64 = require('smbase64')
let base64 = new SMBase64()
import GooglePlacesAutocomplete, {
	geocodeByAddress,
	getLatLng
} from 'react-google-places-autocomplete';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Timeline from "components/Timeline/Timeline.js";
import ChatIcon from '@material-ui/icons/Chat';
import CardTravel from "@material-ui/icons/CardTravel";
import Extension from "@material-ui/icons/Extension";
import Fingerprint from "@material-ui/icons/Fingerprint";
import FlightLand from "@material-ui/icons/FlightLand";
import Build from "@material-ui/icons/Build";
import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
import Info from "@material-ui/icons/Info";
import LocationOn from "@material-ui/icons/LocationOn";
import WorkIcon from '@material-ui/icons/Work';
import HelpOutline from "@material-ui/icons/HelpOutline";
import NavPills from "components/NavPills/NavPills.js";
import TimelineIcon from '@material-ui/icons/Timeline';
import Badge from "components/Badge/Badge.js";
import {mapStyles} from "variables/mapstyles";
import {
  roundedLineChart,
  straightLinesChart,
  simpleBarChart,
  colouredLineChart,
  multipleBarsChart,
  colouredLinesChart,
  pieChart,
} from "variables/charts.js";
import ChartistGraph from "react-chartist";
import StorageIcon from '@material-ui/icons/Storage';
import { CSVLink, CSVDownload } from "react-csv";
import { uniqueNamesGenerator, starWars } from 'unique-names-generator';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import Slider from "nouislider";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Datetime from "react-datetime";

import Popover from '@material-ui/core/Popover';
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import axios from "axios";
import Divider from "@material-ui/core/Divider";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";


const useStyles = makeStyles(styles);

    

const fieldsForReport = [
  "Branch name",
  "Branch location",
  "Team member name",
  "Checkin Time",
  "Checkout Time",
  "Hours spent",]


export default function TimecardPage() {
  const classes = useStyles();
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }
  const [currentUserData, setCurrentUserData] = useState();
  const [branchTableData, setBranchTableData] = useState();
  const [branchListForDropdown, setBranchListForDropdown] = useState();
  const [teamMemberTableData, setTeamMemberTableData] = useState();
  const [timecardData, setTimecardData] = useState();
  const [address, setAddress] = useState();
  const [addressLatLng, setAddressLatLng] = useState();
  const [addressLocation, setAddressLocation] = useState();
  const [addressMainText, setAddressMainText] = useState();
  const [branchName, setBranchName] = useState();
  const [branchPassword, setBranchPassword] = useState();
  const [teamMemberPhone, setTeamMemberPhone] = useState();
  const [teamMemberName, setTeamMemberName] = useState();
  const [timeCardByTheDate, setTimeCardByTheDate] = useState();
  const [selectedDate, setSelectedDate] = useState(moment());
  
  const [createBranchModal, setCreateBranchModal] = useState(false);
  const [createTeamMemberModal, setCreateTeamMemberModal] = useState(false); 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checkedA, setCheckedA] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [reportDates, setReportDates] = React.useState();
  const [reportCSVData, setReportCSVData] = React.useState();
  const [reportGenerationWIP, setReportGenerationWIP] = React.useState(false);
  const [openDate, setOpenDate] = React.useState(false);
  const [multipleSelect, setMultipleSelect] = React.useState([]);
  
  const [reportTeammember, setReportTeammember] = React.useState("");
  const handleSimple = (event) => {
    setReportTeammember(event.target.value);
  };
  const handleMultiple = (event) => {
    setMultipleSelect(event.target.value);
  };
  const openDatePopover = Boolean(anchorEl);
  const id = openDatePopover ? 'simple-popover' : undefined;
  const toggle = () => setOpenDate(!openDate);


  const handleDateOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateClose = () => {
    setAnchorEl(null);
    setReportDates(null)
    setReportCSVData(null);
    setReportGenerationWIP(false);
    setReportTeammember(null)
  };

  var enumerateDaysBetweenDates = (startDate, endDate) => {
    var now = startDate, dates = [];
    
    while (now.isSameOrBefore(endDate)) {
          dates.push(now.format('DD-MM-YYYY'));
          now.add(1, 'days');
      }
    return dates;
  };
  useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setCurrentUserData(data);
        }
      });
    return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])

  useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/tracking/timecard/teammembers/${user.email.replace(/\./g, ",")}/`)
      .limitToLast(100)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];
            if (element && element.verificationstatus === "done") {
              const branchNames = element.branch && element.branch.length > 0 && element.branch.map((b) => `${b.branchname}, `) 
              // result.push([`name-|-${element.name}-|-key-|-${keys[index]}-|-status-|-${element.status}-|-scheduledassignments-|-${scheduledassignments}`, element.phone, `teammemberstatus:${element.status}-|-${element.phone}-|-${user.email.replace(/\./g,",")}-|-${element.name}`, `deleteTeamMember:${element.phone}-|-${user.email.replace(/\./g,",")}`])
              result.push([element.name, element.phone, branchNames,  `timecardSC:${element.status}-|-${element.phone}-|-${user.email.replace(/\./g,",")}-|-${element.name}`, `timecardpassword:${element.password}-|-${element.phone}-|-${user.email.replace(/\./g,",")}-|-${base64.fromNumber(parseInt(Date.now() % 10000000))}`,`timecardDT:${element.phone}-|-${user.email.replace(/\./g,",")}` ])
            }
          }
          setTeamMemberTableData(result);
        }
      });
    return () => Firebase.database().ref(`/tracking/timecard/teammembers/${user.email.replace(/\./g,",")}/`).off('value', onChildAdd);
  }, [])


  useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/tracking/timecard/branches/${user.email.replace(/\./g, ",")}/`)
      .limitToLast(100)
      .on('value', snapshot => {
        const data = snapshot.val()
        if(data){
          let result = []
          let forDropDown = []
          const keys = Object.keys(data);
          for (let index = keys.length-1; index > -1; index--) {
            const element = data[keys[index]];
            forDropDown.push({branchname: element.branchname, branchid: element.branchid, addressmaintext: element.addressmaintext})
            result.push([element.branchname, element.addressmaintext, `checkinurl:${element.checkinurl}`, element.password,  `deleteTimecardBranch:${keys[index]}-|-${user.email.replace(/\./g,",")}`])
          }
          setBranchListForDropdown(forDropDown)
          setBranchTableData(result)
        }

    });
    return () => Firebase.database().ref(`/tracking/timecard/branches/${user.email.replace(/\./g,",")}/`).off('value', onChildAdd);
  }, [])
  const isValidDate = (current) => {
    return current.isBefore(Datetime.moment());
  };

  useEffect(async () => {
    if (!selectedDate) {
      return
    }
    const timeCardReportRef = await Firebase.database().ref(`/tracking/timecard/checkincheckouts/${user.email.replace(/\./g, ",")}/${selectedDate.format("DD-MM-YYYY")}/`).once('value')
    const timeCardReport = timeCardReportRef.val()
    if (!timeCardReport) {
      setTimeCardByTheDate(null)
      return
    }
    const branchesIdArray = Object.keys(timeCardReport)
    let finalResult = []
    for (let index = branchesIdArray.length - 1; index > -1; index--) {
      const branchID = branchesIdArray[index]
      const phoneList = timeCardReport[branchID]
      const phones = Object.keys(phoneList)
      let result = []
      let data;
      for (let index = phones.length - 1; index > -1; index--) {
        const phone = phones[index]
        data = phoneList[phone]
        
        let duration = moment.duration(0)
        // if (data.checkin &&  data.checkout) {
         
        // }


        let res2 = ""
        
        if (data.shifthours.length > 0) {
          for (let index = 0; index < data.shifthours.length; index++) {
            const shift = data.shifthours[index];
            res2 = res2.concat(`entry:${shift.checkin || "To Fill In"}  -  ${shift.checkout || "To Fill In"}`)
            
            if (shift.checkin && shift.checkout) {
              const m1 = moment(shift.checkin, "hh:mm A")
              const m2 = moment(shift.checkout,"hh:mm A")
              duration.add(moment.duration(m2.diff(m1)))
            }
          }
        }
        // if (data && data.shifthours) {
        
        // }
        if (!res2) {
          res2 = `entry:Fill In  - Fill In`
        }
        res2 = res2.concat(`details:${user.email.replace(/\./g, ",")}-|-${selectedDate.format("DD-MM-YYYY")}-|-${branchID}-|-${phone}`)
        result.push([`${data.name}|${phone}`, `shifthours:${res2}`, `${duration.hours()} Hrs ${duration.minutes()} Min`, ""])
        // result.push([`${data.name}|${phone}`, `check-in-out-column:${data.checkin || "To Fill In"}-|-${user.email.replace(/\./g, ",")}-|-${selectedDate.format("DD-MM-YYYY")}-|-${branchID}-|-${phone}-|-checkin`, `check-in-out-column:${data.checkout || "To Fill In"}-|-${user.email.replace(/\./g, ",")}-|-${selectedDate.format("DD-MM-YYYY")}-|-${branchID}-|-${phone}-|-checkout`, duration, ""])

      }
      let temp = {}
      temp[`${data.branch.name} | ${data.branch.address}`] = result
      finalResult.push(temp)
    }
    setTimeCardByTheDate(finalResult)
  }, [selectedDate])

  const showButtonText = () => {
    if (reportGenerationWIP) {
      if (reportCSVData && reportCSVData.length > 0) {
        return (
          <CSVLink filename='Onroad-tracking-report' data={reportCSVData} style={{ fontSize: "10px", color: "white" }}>Download CSV</CSVLink>
        )
      } else {
        return "Preparing"
      }
    } else {
      if (reportCSVData && reportCSVData.length > 0) {
        return (
          <CSVLink filename='Onroad-tracking-report' data={reportCSVData} style={{ fontSize: "10px", color: "white" }}>Download CSV</CSVLink>
        )
      } else {
        return "Generate Report"
      }
    }
  }

  const handleGenerateReport = async () => {
    if (!reportDates) {
      return;
    }
    setReportCSVData(null)
    setReportGenerationWIP(true)

      // const url = `https://us-central1-onroad3-e593b.cloudfunctions.net/generateReportData`
      //     var config = {
      //       method: 'post',
      //       url: url,
      //       headers: {"Authorization": currentUserData.apikey}
      //     };

      // const a = await axios(config)
          // .then(function (response) {
          //   console.log("======nearbysearch====",JSON.stringify(response.data));
          // })
          // .catch(function (error) {
          //   console.log(error);
          // });
    

    var myHeaders = new Headers();
    myHeaders.append("Authorization", currentUserData.apikey);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({dates: reportDates})
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

  const a = await fetch("https://us-central1-onroad3-e593b.cloudfunctions.net/generateHoursTrackReportData", requestOptions)
  const result = await a.json()
  setReportCSVData(result)
    
    // // call api
    // // set csv data
    setReportGenerationWIP(false);
    //  wait(5000);

      // setReportGenerationWIP(false)
    // setReportDates(null)
  
  };

  const handleTeammemberGenerateReport = async () => {
    if (!reportDates || !reportTeammember) {
      return;
    }
    setReportCSVData(null)
    setReportGenerationWIP(true)

    var myHeaders = new Headers();
    myHeaders.append("Authorization", currentUserData.apikey);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({dates: reportDates, member: reportTeammember[1]})
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const a = await fetch("https://us-central1-onroad3-e593b.cloudfunctions.net/generateTeammemberHoursTrackReportData", requestOptions)
    const result = await a.json()
    setReportCSVData(result)
    
    setReportGenerationWIP(false);
   
  };

  const saveBranch = async () => {
    let addedDate = moment().format("DD-MM-YYYY").toString()
    let addedTime = moment().format("hh:mm A").toString()
    const linkID = btoa(`${user.email.replace(/\./g, ",")},${branchName}`)
    const ref = await Firebase.database()
        .ref(`/tracking/timecard/branches/${user.email.replace(/\./g,",")}/`)
        .push({
            "address": address,
            "dateadded":addedDate,
            "timeadded": addedTime,
            "branchname": branchName,
            "addressmaintext": addressMainText,
            "addresslocation": addressLocation,
            "addressadjustedbycustomer": false,
            "addresslatlng": addressLatLng,
            "customer": user.email.replace(/\./g, ","),
            "checkinurl": `https://timecard.onroad.app/${linkID}`,
            "password": branchPassword//`${base64.fromNumber(parseInt(Date.now() % 10000000))}`
        })    
    Firebase.database()
        .ref(`/tracking/timecard/branches/${user.email.replace(/\./g,",")}/${ref.key}/`).update({
           "branchid": ref.key
        })
    
    Firebase.database().ref(`/timecardstorelogin/${user.email.replace(/\./g, ",")}/${branchName}/`).set({ id: ref.key, address: addressMainText, passcode: branchPassword})

    setAddress(null);
    setAddressLatLng(null)
    setAddressLocation(null)
    setBranchName(null)
    setCreateBranchModal(false)
  }
  function validatePhoneNumber(phoneNumber) {
    // Remove any non-digit characters from the phone number
    phoneNumber = phoneNumber.replace(/\D/g, '');
    
    // Check if the resulting string is a valid phone number
    const phoneNumberRegex = /^(?:\+?1[-.\s]?)?\(?([0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})$/;
    return phoneNumberRegex.test(phoneNumber);
  }
  const saveTeammember = async () => {
      if (!multipleSelect || !teamMemberPhone || !teamMemberName) {
        return;
      }
    
    if (!validatePhoneNumber(teamMemberPhone.slice(-10))) {
      return;
    }
    // let selectedBranch;
    //   for(let index = 0; index < branchListForDropdown.length; index++) {
    //     const element = branchListForDropdown[index];
    //     if (element.branchname == teamMemberBranch.split(" | ")[0] && element.addressmaintext == teamMemberBranch.split(" | ")[1]) {
    //       selectedBranch = element
    //     }
    //   }
    // if (!selectedBranch) {
    //   return;
    // }

    Firebase.database()
    .ref(`/timecardactiveteammembers/${user.email.replace(/\./g,",")}/${teamMemberPhone}`)
    .set(teamMemberName)
    
    const passCode = parseInt(Date.now() % 10000)
    
      Firebase.database()
      .ref(`/tracking/timecard/teammembers/${user.email.replace(/\./g,",")}/${teamMemberPhone}`)
      .set({
            id: "storeid",
            name: teamMemberName,
            phone: teamMemberPhone,
            verificationstatus: "done",
            lastupdated: moment().format("YYYY/MM/DD"),
            status: true, 
            branch: multipleSelect,
            password: `${passCode}`
      }) 
    
    Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g,",")}/`)
      .once('value', snapshot => {
        let val = snapshot.val()
        let a = 0
        if(val.totalteammembers) {
          a = val.totalteammembers
        }
        snapshot.ref.update({
          totaltimecardteammembers: parseInt(a) + 1
        })
      })
      
    setTeamMemberName(null)
    setTeamMemberPhone(null)
    setMultipleSelect([])
    setCreateTeamMemberModal(false)
  }

  const noTeamMember = () =>{
    return (
       <GridContainer justify = "space-between"
        style = {{
            color: "grey",
            justifyContent: "center",
            height: "200px",
            display: "inline-block",
            textAlign: "center"
          }}>
         <h3>There are no team members yet</h3>
       </GridContainer>
    )
  }

  const noTimecardData = () =>{
    return (
       <GridContainer justify = "space-between"
        style = {{
            color: "grey",
            justifyContent: "center",
            height: "200px",
            display: "inline-block",
            textAlign: "center"
          }}>
         <h3>There are no timecard details yet</h3>
       </GridContainer>
    )
  }

  const noBranches = () =>{
    return (
       <GridContainer justify = "space-between"
        style = {{
            color: "grey",
            justifyContent: "center",
            height: "200px",
            display: "inline-block",
            textAlign: "center"
          }}>
         <h3>There are no branches created yet</h3>
       </GridContainer>
    )
  }
 
  const getCoordinates = (address) => {
    geocodeByAddress(address.value.description)
      .then(results => getLatLng(results[0]))
      .then((data) => {
      
        setAddress(address.value.description)
        if (address.value && address.value.structured_formatting && address.value.structured_formatting.secondary_text) {
          setAddressLocation(address.value.structured_formatting.secondary_text)
          setAddressMainText(address.value.structured_formatting.main_text)
        }
        setAddressLatLng(data)
      })
  };


  const branchesList = () => {
    return (<>
      <Dialog open={createBranchModal} onClose={()=> {setCreateBranchModal(false)}} aria-labelledby="form-dialog-title" fullWidth>
			<DialogTitle id="form-dialog-title">Add A Branch</DialogTitle>
        <DialogContent>
          <h4>{address}</h4>
          {addressLatLng && (<img src={`https://maps.googleapis.com/maps/api/staticmap?maptype=satellite&center=${addressLatLng.lat},${addressLatLng.lng}&zoom=18&size=550x300&maptype=roadmap&markers=color:red%7Clabel:G%7C${addressLatLng.lat},${addressLatLng.lng}&key=AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk`} title="" />)}
    
          <DialogContentText>
          Enter Address
          </DialogContentText>
          <GooglePlacesAutocomplete
          selectProps={{
          onChange: (address) => getCoordinates(address),
          }}
          placeholder="Search Address "
          apiKey = "AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk"
          />
          &nbsp;
          <h4>Branch Name</h4>
          <CustomInput
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses,
          }}
          inputProps={{
            startAdornment: (
            <InputAdornment
              position="start"
              className={classes.inputAdornment}
            >
              <StorefrontIcon className={classes.inputAdornmentIcon} />
            </InputAdornment>
            ),
            type: "text",
            placeholder: "Name of the branch...",
            onChange: (e) => {
              setBranchName(`${e.target.value}`);
            }
          }}
          />
          &nbsp;
          <h4>Store Passcode</h4>
          <CustomInput
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses,
          }}
          inputProps={{
            startAdornment: (
            <InputAdornment
              position="start"
              className={classes.inputAdornment}
            >
              <VpnKeyIcon className={classes.inputAdornmentIcon} />
            </InputAdornment>
            ),
            type: "text",
            placeholder: "Please enter passcode for the store",
            onChange:  (e) => {
              setBranchPassword(`${e.target.value}`);
            }
          }}
          />
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=> {setCreateBranchModal(false)}} color="primary">
          Cancel
          </Button>
          <Button onClick={()=> {saveBranch()}} color="primary" disabled={!branchName || !branchPassword || !address || !addressLatLng}>
          Add
          </Button>
        </DialogActions>
		  </Dialog>
      <Card>
      <CardHeader color="rose" icon>
        
        <CardIcon color="rose" style={{float: "right", padding: "0px"}}>
        <Button  onClick={()=> {setCreateBranchModal(true)}}
          style = {{
            background: "transparent",
            boxShadow: "none",
            padding:"10px"
          }}>
          Add A Branch
        </Button>
        </CardIcon>      
      </CardHeader>
      <CardBody>
        {branchTableData && branchTableData.length> 0 ? (
        <Table
          tableHeaderColor="primary"
          tableHead={["Branch Name", "Address", "Timecard Link", "Password", " "]}
          tableData={branchTableData}
          coloredColls={[3]}
          colorsColls={["primary"]}
          deleteHandleLastcaseCallback={deleteHandleLastcaseCallback}
        />): noBranches()}
        
      </CardBody>
      </Card>
    </>)

  }
  
  const deleteHandleLastcaseCallback = (t) => {
    if(t == 'member'){
     setTeamMemberTableData([])
    }
    if(t == 'branch'){
      setBranchTableData([])
    }
  }
  
  const overWriteCallback = (date) => {
    if (date) {
      const a = moment(date, "DD-MM-YYYY")
      setSelectedDate(a)
    }
   
  }
  const timeCardList = () => {
    return (
      <>
          <Button onClick={handleDateOpen}
             color="transparent"
             style={{color: "blue", float: "right", fontSize: "10px", marginRight: "-10px", textTransform: "capitalize"}}
             className={classes.buttonLink}
                >
            Generate Report
        </Button>
        <Popover
          id={id}
          open={openDatePopover}
          anchorEl={anchorEl}
          onClose={handleDateClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          >
             
            <DateRangePicker
              open={true}
              toggle={toggle}
              onChange={(range) => {
                setReportDates(enumerateDaysBetweenDates(moment(range.startDate), moment(range.endDate)))
                // generateAndSetCSVData()
              }}
                
            />
            <br />
            <bold style={{fontSize: "20px", padding: "30px"}}>Fields in the report</bold>
            <div style={{maxWidth: "700px", padding: "30px"}}>
            {fieldsForReport.map((field, index) => {
              return (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={true}
                        tabIndex={-1}
                        // onClick={() => handleToggle(index)}
                        checkedIcon={
                          <Check className={classes.checkedIcon} />
                        }
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label={field}
                  />
                </>
              
              )
            })
            }
            </div>
          
           {/* {reportCSVData && reportCSVData.length > 0 ? ( <Button
              size="sm"
              color="rose"
              onClick={handleDateClose}
              style={{ 
                textAlign: "center",
                left: "40%"
              }}>
              {reportCSVData && reportCSVData.length > 0 ? (<CSVLink filename='Onroad-tracking-report' data={reportCSVData} style={{ fontSize: "10px", color: "white" }}>Download CSV</CSVLink>) : (<CustomLinearProgress />)}
            </Button>) : (<CustomLinearProgress />)} */}

            {reportGenerationWIP ? (<CustomLinearProgress />) : ""}
           
            <Button
              size="sm"
              color="rose"
              onClick={() => {reportGenerationWIP ? handleDateClose() :  handleGenerateReport() }}
              style={{ 
                textAlign: "center",
                left: "45%"
              }}>
              {showButtonText()}
            </Button>

            <Button
              size="sm"
              color="rose"
              onClick={handleDateClose}
              style={{ 
                textAlign: "center",
                left: "50%"
                }}>
              Close
            </Button>
            {reportCSVData && reportCSVData.length > 0 && <a style={{ 
                textAlign: "center",
                
              }}>Tracking-report.csv</a> }
            
        </Popover>
        <Card>
       
        <CardHeader >
          <CardIcon style={{ float: "right", paddingRight: "11%" }}>
              <div>
                <Datetime
                  isValidDate={isValidDate}
                  value={selectedDate}
                  timeFormat={false}
                  inputProps={{ placeholder: "Date Picker Here" }}
                  onChange={(e) => {
                    setSelectedDate(e)
                  }}
                />
                 {/* <Button onClick={handleDateOpen}
                    color="transparent"
                    style={{color: "blue", float: "right", paddingTop: "0px", fontSize: "10px", left: "-32px", textTransform: "capitalize"}}
                    className={classes.buttonLink}
                  >
                    Generate Report
                  </Button> */}
              </div>
               
          </CardIcon>
          <br />
        </CardHeader>
        <CardBody>      
            {timeCardByTheDate && timeCardByTheDate.length > 0 ? timeCardByTheDate?.map((timeCard) => {
              return (<>
                <h4><b>{Object.keys(timeCard)[0]}</b>  <br /></h4>
                <Divider style={{ display:"block",  height: "5px"}} />
                     <Table
                  tableHeaderColor="primary"
                  tableHead={["Name", "Shift Hours", "Total Hours", ""]} //["Name", "Check-In", "Check-Out", "Hours Spent", ""]
                  tableData={Object.values(timeCard)[0]}
                  coloredColls={[3]}
                  colorsColls={["primary"]}
                  // openTeamMember={openTeamMemberCallback}
                  deleteHandleLastcaseCallback={deleteHandleLastcaseCallback}
                  overWriteCallback={(e) => { overWriteCallback(e) }}
                      />
                  </>)
                })
               : noTimecardData()}
          
        </CardBody>
        </Card>
      </>
    )
  }

  const teamMemberList = () => {
 
    return (<>
      <Dialog open={createTeamMemberModal} onClose={()=> {setCreateTeamMemberModal(false)}} aria-labelledby="form-dialog-title" fullWidth>
			<DialogTitle id="form-dialog-title">Register A Team Member</DialogTitle>
			<DialogContent>
			<DialogContentText>
				Enter Name
			</DialogContentText>
			<CustomInput
				formControlProps={{
				fullWidth: true,
				className: classes.customFormControlClasses,
				}}
				inputProps={{
				startAdornment: (
					<InputAdornment
					position="start"
					className={classes.inputAdornment}
					>
					<PersonIcon className={classes.inputAdornmentIcon} />
					</InputAdornment>
				),
				placeholder: "Name...",
				onChange: (e)=> {setTeamMemberName(`${e.target.value}`)}
				}}
			/>
			<br/>
			<br/>
			<DialogContentText>
				Enter Phone
			</DialogContentText>
			<CustomInput
				formControlProps={{
				fullWidth: true,
				className: classes.customFormControlClasses,
				}}
				inputProps={{
				startAdornment: (
					<InputAdornment
					position="start"
					className={classes.inputAdornment}
					>
					<Phone className={classes.inputAdornmentIcon} />
					</InputAdornment>
				),
				placeholder: "10 Digit Phone Number",
				onChange: (e)=> {setTeamMemberPhone(`${currentUserData.countrycode}${e.target.value}`)}
				}}
        />
          <h4> Branches : <b>{multipleSelect.map((branch) => <>{branch.branchname} {" "}</>)}</b></h4>
				&nbsp;
      <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="multiple-select"
                    className={classes.selectLabel}
                  >
                    Pick Branches
                  </InputLabel>
                  <Select
                    multiple
                    value={multipleSelect}
                    onChange={handleMultiple}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    inputProps={{
                      name: "multipleSelect",
                      id: "multiple-select",
                    }}
                  >
        
                  {branchListForDropdown?.map((b) => <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value={b}
                    >
                      {b.branchname}
                    </MenuItem>)}
                  </Select>
      </FormControl>
			</DialogContent>
			<DialogActions>
          <Button onClick={() => { setCreateTeamMemberModal(false) }} color="primary">
				Cancel
			</Button>
			<Button onClick={saveTeammember} color="primary" disabled={!teamMemberName || !teamMemberPhone}>
				Save
			</Button>
			</DialogActions>
		  </Dialog>

      <Card>
          {/* <Datetime
                  timeFormat={false}
                  inputProps={{ placeholder: "Date Picker Here" }}
        /> */}
        
        
        <CardHeader  icon>
          
          <CardIcon color="rose" style={{ float: "right", padding: "0px" }}>
          <Button  onClick={() => {setCreateTeamMemberModal(true)}}
            style = {{
              background: "transparent",
              boxShadow: "none",
              padding:"10px"
            }}>
            Add A Team Member
            </Button>
          </CardIcon>
          <br />
          
          
          <Button onClick={handleDateOpen}
             color="transparent"
             style={{color: "blue", float: "right", fontSize: "10px", marginRight: "-162px", textTransform: "capitalize"}}
             className={classes.buttonLink}
                >
                  Generate A Member Report
          </Button>

          <Popover
          id={id}
          open={openDatePopover}
          anchorEl={anchorEl}
          onClose={handleDateClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
            // style={{overflow: "scroll", height: "90vh"}}
          >
              <DateRangePicker
              open={true}
              toggle={toggle}
              onChange={(range) => {
                setReportDates(enumerateDaysBetweenDates(moment(range.startDate), moment(range.endDate)))
                // generateAndSetCSVData()
              }}
                
            />
             <GridItem xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Select A Teammember
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={reportTeammember}
                    onChange={(e) => {
                      handleSimple(e)
                    }}
                    inputProps={{
                      name: "simpleSelect",
                      id: "simple-select",
                    }}
                >
                  <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Select Teammember
                    </MenuItem>
                  {teamMemberTableData?.map((teamMember) => <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={teamMember}
                    >
                      {teamMember[0]}
                    </MenuItem>)}
                </Select>
                </FormControl>
            </GridItem>
            <br />
            <bold style={{fontSize: "20px", padding: "30px"}}>Fields in the report</bold>
            <div style={{maxWidth: "700px", padding: "30px"}}>
            {fieldsForReport.map((field, index) => {
              return (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={true}
                        tabIndex={-1}
                        // onClick={() => handleToggle(index)}
                        checkedIcon={
                          <Check className={classes.checkedIcon} />
                        }
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label={field}
                  />
                </>
              
              )
            })
            }
            </div>
          
           {/* {reportCSVData && reportCSVData.length > 0 ? ( <Button
              size="sm"
              color="rose"
              onClick={handleDateClose}
              style={{ 
                textAlign: "center",
                left: "40%"
              }}>
              {reportCSVData && reportCSVData.length > 0 ? (<CSVLink filename='Onroad-tracking-report' data={reportCSVData} style={{ fontSize: "10px", color: "white" }}>Download CSV</CSVLink>) : (<CustomLinearProgress />)}
            </Button>) : (<CustomLinearProgress />)} */}

            {reportGenerationWIP ? (<CustomLinearProgress />) : ""}
           
            <Button
              size="sm"
              color="rose"
              disabled={!reportTeammember}
              onClick={() => {reportGenerationWIP ? handleDateClose() :  handleTeammemberGenerateReport() }}
              style={{ 
                textAlign: "center",
                left: "45%"
              }}>
              {showButtonText()}
            </Button>

            <Button
              size="sm"
              color="rose"
              onClick={handleDateClose}
              style={{ 
                textAlign: "center",
                left: "50%"
                }}>
              Close
            </Button>
            {reportCSVData && reportCSVData.length > 0 && <a style={{ 
                textAlign: "center",
                
              }}>Tracking-report.csv</a> }
            
        </Popover>
         
        </CardHeader>
        <CardBody>
          {teamMemberTableData && teamMemberTableData.length> 0 ? (<Table
          tableHeaderColor="primary"
          tableHead={["Name", "Phone", "Branch", "Status", "Password"]}
          tableData={teamMemberTableData}
          coloredColls={[3]}
          colorsColls={["primary"]}
          // openTeamMember={openTeamMemberCallback}
          deleteHandleLastcaseCallback={deleteHandleLastcaseCallback}
          />): noTeamMember()}
          
        </CardBody>
        </Card>
    </>)
  }

  const timeCardScreen = () => {
    return (
      <>
        <div style={{alignItems: "center", justifyContent: "center", padding: "0px", marginTop: "-50px"}}>
          <Heading
            textAlign="center"
            title="Timecard"
            className={classes.buttonLink}
            style={{padding: "0px", margin: "0px", margin: "0px!important"}}
            // category={currentUserData?.apikey}
            />
        </div>
   
        <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            headerColor="success"
            // customFunction={customFunctionDP}
              tabs={[
                {
                  tabName: "Branches",
                  tabIcon: StorefrontIcon,
                  tabContent: (
                    <>
                    {branchesList()}
                    </>
                  ),
                },
                {
                  tabName: "Team Members",
                  tabIcon: GroupIcon,
                  tabContent: (
                  <>
                      {teamMemberList()}
                  </>
                  ),
                },
                {
                  tabName: "Timecard ",
                  tabIcon: TimerIcon,
                  tabContent: (
                    <>
                      {timeCardList()}
                    </>
                  ),
                },
            ]}
          >
          </CustomTabs>
        </GridItem>
      </GridContainer> 
        
    
      </>
    )
  }
  return (
    <div>
    	{timeCardScreen()}
    </div>
  );
}
